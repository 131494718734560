import React, { useEffect, useState } from "react";
import SharedModal from "../../happiness/SharedModal";
import SuccessSurvayAddedMobile from "../SuccessSurvayAddedMobile";
import SuccessSurvayAdded from "../SuccessSurvayAdded";
import FeedBackQuestions from "../FeedBackQuestions";

const FeedBackListMobile = ({
  setFeedBackIsOpen,
  feedBackIsOpen,
  survayServices,
  lang,
  enPhrase,
  arPhrase,
  FeedBackListHandler,
  specificSurvey,
  setSpecificSurvey,
  sidebarHeight
}) => {

  const [isOpenFeedBack, setIsOpenFeedBack] = useState(true);
  const [isExpand, setIsExpand] = useState(false);
  const [isRequestSurvay, setIsRequestSurvay] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState();
  const [selectedserviceName, setSelectedserviceName] = useState();
  const [selectedcaseNumber, setSelectedcaseNumber] = useState();
  const [selectedcaseName, setSelectedcaseName] = useState();
  const [selectedItem, setSelectedItem] = useState(null);

  const expandHandler = () => {
    setIsExpand(!isExpand);
  };

  const feedBackHandler = () => {
    setIsOpenFeedBack(false);
    setFeedBackIsOpen(false);
    setSpecificSurvey();
  };

  useEffect(() => {
    if (specificSurvey) {
      requestSurvay(specificSurvey);
      setIsExpand(true);
    }
  }, [specificSurvey]);

  const requestSurvay = (id, specificSurvey) => {
    console.log("id", id);
    setIsRequestSurvay(true);
    if (specificSurvey !== undefined) {
      setSelectedServiceId(specificSurvey.servicesId);
      setSelectedserviceName(specificSurvey.serviceName);
      setSelectedcaseNumber(specificSurvey.caseNumber);
      setSelectedcaseName(specificSurvey.caseTypeName);
    }
    if (id) {
      setSelectedServiceId(id.servicesId);
      setSelectedserviceName(id.serviceName);
      setSelectedcaseNumber(id.caseNumber);
      setSelectedcaseName(id.caseTypeName);
    }
  };

  console.log(
    "selectedServiceId",
    selectedServiceId,
    selectedserviceName,
    selectedcaseNumber,
    selectedcaseName
  );
  

  const [success, setSuccess] = useState("");

  const handleSuccessChange = (newState) => {
    setSuccess(newState);
  };

  // const handleClick = (index) => {
  //   setSelectedItem(index);
  // };

  // const [inactiveStates, setInactiveStates] = useState(survayServices?.services?.map(() => false));

  // const handleNotificationClick = (index) => {
  //   // Set the clicked notification as inactive
  //   const newInactiveStates = [...inactiveStates];
  //   newInactiveStates[index] = true;  // Once clicked, it becomes inactive
  //   setInactiveStates(newInactiveStates);
  // };

    // Retrieve saved state from localStorage or default to all notifications being active
    const savedStates = JSON.parse(localStorage.getItem('inactiveStates')) || survayServices?.services?.map(() => false);

    // State to track if each notification has been clicked (inactive)
    const [inactiveStates, setInactiveStates] = useState(savedStates);
  
    useEffect(() => {
      // Save the current state to localStorage whenever it changes
      localStorage.setItem('inactiveStates', JSON.stringify(inactiveStates));
    }, [inactiveStates]);
  
    const handleNotificationClick = (index) => {
      // Set the clicked notification as inactive
      const newInactiveStates = [...inactiveStates];
      newInactiveStates[index] = true;  // Once clicked, it becomes inactive
      setInactiveStates(newInactiveStates);
    };




  return (
    <div className="w-full">
      {success ? (
        window && window.innerWidth <= 1024 ? (
          <SharedModal isSuccessSurvayMobile setOpenModal={setSuccess}>
            <SuccessSurvayAddedMobile
              setIsOpenFeedBack={setIsOpenFeedBack}
              survayServices={survayServices}
              feedBackHandler={feedBackHandler}
              setIsRequestSurvay={setIsRequestSurvay}
              FeedBackListHandler={FeedBackListHandler}
              lang={lang}
              arPhrase={arPhrase}
              enPhrase={enPhrase}
              expandHandler={expandHandler}
              setFeedBackIsOpen={setFeedBackIsOpen}
              specificSurvey={specificSurvey}
              setSpecificSurvey={setSpecificSurvey}
              feedBackIsOpen={feedBackIsOpen}
            />
          </SharedModal>
        ) : (
          <SuccessSurvayAdded
            setIsOpenFeedBack={setIsOpenFeedBack}
            survayServices={survayServices}
            feedBackHandler={feedBackHandler}
            setIsRequestSurvay={setIsRequestSurvay}
            FeedBackListHandler={FeedBackListHandler}
            lang={lang}
            arPhrase={arPhrase}
            enPhrase={enPhrase}
            expandHandler={expandHandler}
            setFeedBackIsOpen={setFeedBackIsOpen}
            specificSurvey={specificSurvey}
            setSpecificSurvey={setSpecificSurvey}
            feedBackIsOpen={feedBackIsOpen}
          />
        )
      ) : isRequestSurvay ? (
        <div
          className={`w-full md:w-[400px] rounded-[16px] border-[1px] border-[#E6D7A2] bg-[#fff] shadow-[0_35px_60px_-15px_rgba(182,138,53,0.2)] ${
            isOpenFeedBack ? "fixed" : "hidden"
          } ${
            isExpand
              ? `bottom-[0px] h-full overflow-y-scroll rounded-none ${
                  lang === "en" ? "right-0" : "left-0"
                }`
              : `bottom-[35px] ${
                  lang === "en"
                    ? "right-0 md:right-[24px]"
                    : "left-0  md:left-[24px]"
                }`
          } 
   z-[10000]`}
        >
          <div className="h-[56px] bg-[#F2ECCF] flex items-center gap-[16px] px-[16px] fixed w-full">
            <img
              src="/assets/img/case-overview/survayactions.svg"
              alt="back-icon"
              className={`w-[32px] h-[32px] cursor-pointer ${
                lang === "en" ? "rotate-180" : ""
              }`}
              onClick={() => {
                setIsRequestSurvay(false);
                setSpecificSurvey();
                // document.body.style.overflow = "unset";
              }}
            />
            <div>
              <div className="flex flex-col gap-[5px]">
                <div className="font-Almarai font-normal text-[14px] leading-[14.4px] flex gap-[8px]">
                  <span className="font-bold">{selectedServiceId}</span>
                  <span>•</span>
                  <p className="font-bold">{selectedserviceName}</p>
                </div>
                <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[8px] text-[#54504A]">
                  <span>{selectedcaseNumber}</span>
                  <span>•</span>
                  <p>{selectedcaseName}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full p-[16px]  pt-[72px]">
            <div className="h-[72px] w-full m-auto border-[1px]  border-[#F2C99B] p-[16px] rounded-[8px] flex items-center gap-[24px]">
              <img
                src="/assets/img/case-overview/survay-icon.svg"
                alt=""
                className="w-[32px] h-[32px]"
              />
              <p
                className={`flex font-Almarai font-bold text-[14px] leading-[20px] ${
                  lang === "en" ? "text-left" : "text-right"
                }`}
              >
                {lang === "en"
                  ? enPhrase["lblShareSurveytext"]
                  : arPhrase["lblShareSurveytext"]}
              </p>
            </div>

            <FeedBackQuestions
              lang={lang}
              enPhrase={enPhrase}
              arPhrase={arPhrase}
              selectedServiceId={selectedServiceId}
              onSuccessChange={handleSuccessChange}
              expandHandler={expandHandler}
              setIsRequestSurvay={setIsRequestSurvay}
            />
          </div>
        </div>
      ) : (
        <div
          className={`w-full md:w-[400px]   border-[1px]  border-[#E6D7A2]  bg-[#fff] shadow-[0_35px_60px_-15px_rgba(182,138,53,0.2)]  ${
            isOpenFeedBack ? "fixed" : "hidden"
          } bottom-0 top-0 ${
            isExpand
              ? `bottom-[0px] top-0 ease-in-out duration-500 h-full rounded-t-none  ${
                  lang === "en" ? "right-0" : "left-0"
                }`
              : `bottom-[0px] md:bottom-[35px] ${
                  lang === "en"
                    ? "right-0 md:right-[24px]"
                    : "left-0 md:left-[24px]"
                }`
          } 
     z-[10000]`}

          // className={`bottom-0  top-0 right-0 fixed bg-white w-full h-auto pt-[88px] overflow-x-hidden z-[30000]${
          //   isOpenFeedBack ? " translate-y-0" : "flex translate-y-full"
          // } ease-in-out duration-500`}
        >
          <div
            className={`bg-[#F2ECCF] h-[56px] px-[16px]  ${
              isExpand ? "rounded-none" : "md:rounded-t-[16px]"
            } flex items-center justify-between`}
          >
            <div className="flex gap-[12px]">
              <p className="font-Almarai text-[16px] leading-[24px] font-bold">
                {lang === "en"
                  ? enPhrase["lblShareSurvey"]
                  : arPhrase["lblShareSurvey"]}
              </p>
              <div className="rounded-[4px] px-[8px] flex items-center gap-[8px] bg-[#a9803133]">
                <span className="font-Almarai font-medium text-[12px] leading-[16px] ">
                  {survayServices?.services?.length}
                </span>
                <span className="font-Almarai text-[12px] font-normal leading-[16px] ">
                  {lang === "en"
                    ? enPhrase["lblSurvey"]
                    : arPhrase["lblSurvey"]}
                </span>
              </div>
            </div>

            <div className="flex ">
              {isExpand ? (
                <img
                  src="/assets/img/case-overview/minimize-icon.svg"
                  alt="expand-icon"
                  className="cursor-pointer hidden md:flex transform transition-transform duration-500 ease-in-out hover:scale-110 hover:bg-[#a9803133] rounded"
                  // onClick={expandHandler}
                  onClick={() => {
                    expandHandler();
                    document.body.style.overflow = "unset";
                  }}
                />
              ) : (
                <img
                  src="/assets/img/case-overview/expandIcon.svg"
                  alt="expand-icon"
                  className="cursor-pointer hidden md:flex transform transition-transform duration-500 ease-in-out hover:scale-110 hover:bg-[#a9803133] rounded"
                  // onClick={expandHandler}
                  onClick={() => {
                    expandHandler();
                    document.body.style.overflow = "hidden";
                  }}
                />
              )}
              <img
                src="/assets/img/case-overview/closedIcon.svg"
                alt="close-icon"
                className="cursor-pointer transform transition-transform duration-500 ease-in-out hover:scale-110 hover:bg-[#a9803133] rounded"
                onClick={() => {
                  feedBackHandler();
                  document.body.style.overflow = "unset";
                }}
              />
            </div>
          </div>

          <div
            className={`overflow-scroll overflow-x-hidden w-full  h-custom-calc overflow-y-auto `}
          >
            {survayServices?.services?.map((item, index) => (
              <>
                <div
                  className={` flex items-center gap-[8px]  py-[8px] px-[16px] h-[56px]  ${
                    inactiveStates[index] ? "bg-[#fff]" : "bg-[#FBF9F5]"
                  }  `}
                  // onClick={() => handleNotificationClick(index)}
                  key={index}
                >
                  {!inactiveStates[index] ? (
                    <img
                      alt="active"
                      className=""
                      src="/assets/img/case-overview/Ellipse.svg"
                    />
                  ) : (
                    <img
                      alt="active"
                      className="invisible"
                      src="/assets/img/case-overview/Ellipse.svg"
                    />
                  )}
                  <div className="flex items-center justify-between w-full">
                    <div className="flex gap-[16px] items-center">
                      <img
                        src="/assets/img/case-overview/requestIcon.svg"
                        alt=""
                        className="w-[24px] h-[24px]"
                      />
                      <div className="flex flex-col gap-[4px]">
                        <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[8px]">
                          <span className="font-bold">{item?.servicesId}</span>
                          <span>•</span>
                          <p className="font-bold">{item?.serviceName}</p>
                        </div>
                        <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[8px] text-[#54504A]">
                          <span>{item?.caseNumber?.slice(-12)}</span>
                          <span>•</span>
                          <p>{item?.caseTypeName}</p>
                        </div>
                      </div>
                    </div>
                    <img
                      src="/assets/img/case-overview/Subtle Button.svg"
                      alt="Subtle-Button"
                      className={`w-[36px] h-[32px] cursor-pointer transform transition-transform duration-500 ease-in-out hover:scale-110 hover:bg-[#a9803133] rounded  ${
                        lang === "en" ? "rotate-180" : ""
                      }`}
                      onClick={() => {
                        requestSurvay(item);
                        setIsExpand(true);
                        document.body.style.overflow = "hidden";
                        handleNotificationClick(index)
                      }}
                    />
                  </div>
                </div>
                <hr
                  class={`h-px ms-[16px] bg-[#E6E6E6] border-1 ${
                    index === survayServices?.services.length - 1
                      ? "hidden"
                      : ""
                  }`}
                ></hr>
              </>
            ))}
          </div>

          <div className="w-full md:w-[384px] mx-auto h-[32px] my-[4px] rounded-[8px] px-[16px] py-[4px] bg-[#F7F7F7] flex items-center justify-center">
            <p className="flex font-Almarai font-bold text-[12px] leading-[16px] ">
              {lang === "en"
                ? enPhrase["lblShareSurveytext"]
                : arPhrase["lblShareSurveytext"]}
            </p>
          </div>

          {/* 
    <div className={`${isExpand? "":"max-h-[200px]"} overflow-scroll overflow-x-hidden`}>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
      <div className="w-full flex items-center justify-between py-[8px] px-[16px] h-[56px] gap-[16px] bg-[#fff] rounded-b-[16px]">
        <div className="flex gap-[16px] items-center">
          <img
            src="/assets/img/case-overview/requestIcon.svg"
            alt=""
            className="w-[24px] h-[24px]"
          />
          <div className="flex flex-col">
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px]">
              <span>3124</span>
              <span>•</span>
              <p>التدقيق علي الملف</p>
            </div>
            <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[10px] text-[#54504A]">
              <span>2020/0001790</span>
              <span>•</span>
              <p>مدني لجنة فض المنازعات التجارية</p>
            </div>
          </div>
        </div>
        <img
          src="/assets/img/case-overview/Subtle Button.svg"
          alt=""
          className="w-[36px] h-[32px]"
        />
      </div>
    </div> */}
        </div>
      )}
    </div>
  );
};

export default FeedBackListMobile;
