import React, { useEffect, useState } from "react";
import { Container } from "../components/utils";

const FooterLayout = ({ lang, enPhrase, arPhrase, dashboard }) => {
  const [year, setYear] = useState(new Date().getFullYear());
    useEffect(() => {
    const interval = setInterval(() => {
      setYear(new Date().getFullYear());
    }, 1000 * 60 * 60 * 24); // Updates every 24 hours
    return () => clearInterval(interval);
  }, []);

  return (
    <section
      id="footer"
    >
    
      <div className="w-full overflow-hidden  wow fadeInUp py-[1.5rem]  bg-[#fff]">
        <Container
          className={`flex flex-col items-center lg:flex-row justify-evenly `}
        >
          <div className="font-bold font-Almarai text-[14px] text-center lg:text-start lg:font-[16px] mb-[16px] lg:mb-[0px]">
          {lang === "en"
                ? `Copyright © EJE ${year}. All rights reserved`
                : `جميع الحقوق محفوظة © الإمارات لتنفيذ الأحكام ${year}`}
          </div>
          <div className="flex gap-3 items-center justify-center">
            <div className="flex flex-row gap-3 items-center">
              <div className="text-black font-medium text-center">
                <a
                  href="/Privacy"
                  className="font-bold font-Almarai text-[14px] lg:text-base "
                >
                  {lang === "en"
                    ? enPhrase["lbl_privacy_policy"]
                    : arPhrase["lbl_privacy_policy"]}
                </a>
              </div>
            </div>
            <div className=" flex-row gap-3 items-center ">|</div>
            <div className="flex flex-row gap-3 items-center">
              <div className="text-black font-medium text-center">
                <a
                  href="/terms"
                  className="font-bold font-Almarai text-[14px] lg:text-base"
                >
                  {lang === "en"
                    ? enPhrase["lbl_terms_condtions"]
                    : arPhrase["lbl_terms_condtions"]}
                </a>
              </div>
            </div>
            <div className=" flex-row gap-3 items-center ">|</div>
            <div className="flex flex-row gap-3 items-center">
              <div className="text-black font-medium text-center">
                <a
                  href="/Refund"
                  className="font-bold font-Almarai text-[14px] lg:text-base"
                >
                  {lang === "en"
                    ? enPhrase["lbl_Refund_Policy"]
                    : arPhrase["lbl_Refund_Policy"]}
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};
export default FooterLayout;