import React, { useContext, useEffect, useState } from "react";
import { default as DatePicker } from "react-datepicker";
import { Link } from "react-router-dom";
import { PhrasesContext } from "../../../context/phrasesContext";
import {
  getAllDetails,
  getCaseTypes,
  getCasesStatus,
  getCourts,
  getServiceCategories,
  getServiceStatus,
} from "../../../services/Common";
import Pagination from "../../pagination";
import ServiceStatus from "../../serviceStatus";

import "react-datepicker/dist/react-datepicker.css";

const TableMobile = ({
  servicesData,
  isSubTable,
  setFeedBackIsOpen,
  onSurvayRequest,
}) => {
  const [filteredRequests, setFilteredRequests] = useState(servicesData || []);
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [requestPerPage, setRequestPerPage] = useState(20);

  const [years, setYears] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [courts, setCourts] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [caseRequest, setCaseRequest] = useState([]);
  const [servicesStatus, setServicesStatus] = useState([]);
  const [casesStatus, setCasesStatus] = useState([]);
  const maxOffset = 60;

  const [filteredCreatDate, setFilteredCreatDate] = useState("");
  const [filteredCaseType, setFilteredCaseType] = useState("");
  const [filteredRequestType, setFilteredRequestType] = useState("");
  const [filteredCourt, setFilteredCourt] = useState("");
  const [filteredCaseStatus, setFilteredCaseStatus] = useState("");
  const [activeClass, setActiveClass] = useState(false);
  const [activeClassCaseStatus, setActiveClassCaseStatus] = useState(false);
  const [activeClassCourt, setActiveClassCourt] = useState(false);
  const [activeClasscaseType, setActiveClasscaseType] = useState(false);
  const [activeClassrequestType, setActiveClassrequestType] = useState(false);
  const [lang, setLang] = useState("");

  const indexOfLastRequest = currentPage * requestPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestPerPage;
  const records = filteredRequests?.slice(
    indexOfFirstRequest,
    indexOfLastRequest
  );

  const npage = Math.ceil(filteredRequests.length / requestPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const getServiceCatrgoryServices = (currentLang) => {
    return getServiceCategories(currentLang).then((res) => {
      setCaseRequest(res);
    });
  };

  const getCaseTypesSrvices = (currentLang) => {
    return getCaseTypes(currentLang).then((res) => {
      setCaseTypes(res);
    });
  };

  const getCourtsTypes = (currentLang) => {
    return getCourts(currentLang).then((res) => {
      setCourts(res);
    });
  };

  const getServicesStatus = (currentLang) => {
    return getServiceStatus(currentLang).then((res) => {
      setServicesStatus(res);
    });
  };

  const getCaseStatus = (currentLang) => {
    return getCasesStatus(currentLang).then((res) => {
      setCasesStatus(res);
    });
  };

  const getDetails = (currentLang) => {
    return getAllDetails(currentLang).then((res) => {
      console.log("res?.service", res?.service);
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }

    if (!isSubTable) getDetails(currentLang);
    // getInfoService();
    const year = new Date().getFullYear();
    setYears(Array.from(new Array(maxOffset), (val, index) => year - index));
    getServiceCatrgoryServices(currentLang);
    getCaseTypesSrvices(currentLang);
    getCourtsTypes(currentLang);
    getServicesStatus(currentLang);
    getCaseStatus(currentLang);
  }, []);

  const handleFilteredRequests = (value) => {
    const filterd = servicesData?.filter(
      (el) =>
        el?.servicesId?.toString().toLowerCase().includes(value) ||
        el?.serviceName?.toString().toLowerCase().includes(value) ||
        el?.serviceStatus?.toString().toLowerCase().includes(value) ||
        el?.courtName?.toString().toLowerCase().includes(value) ||
        el?.caseTypeName?.toString().toLowerCase().includes(value) ||
        el?.createdAt?.toString().toLowerCase().includes(value)
    );
    setFilteredRequests(filterd);
  };

  const filtered2 = filteredRequests
    .filter((request) =>
      filteredRequestType === ""
        ? request
        : request.serviceName?.toString().includes(filteredRequestType)
    )
    .filter((request) =>
      filteredCaseType === ""
        ? request
        : request.caseTypeName?.toString().includes(filteredCaseType)
    )
    .filter((request) =>
      filteredCourt === ""
        ? request
        : request.courtName?.toString().includes(filteredCourt)
    )
    .filter((request) =>
      filteredCaseStatus === ""
        ? request
        : request.serviceStatus?.toString().includes(filteredCaseStatus)
    )
    .filter((request) =>
      filteredCreatDate === ""
        ? request
        : request.createdAt
            ?.toString()
            .toLowerCase()
            .includes(filteredCreatDate)
    )
    .filter((request) =>
      selectedDate === ""
        ? request
        : request.createdAt?.toString().toLowerCase().includes(selectedDate)
    );

  const handleChangeRequestPerPage = (event) => {
    setRequestPerPage(event.target.value);
    setCurrentPage(1);
  };

  const sideMenuHandler = () => {
    setIsOpen(true);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const createDateHandler = (id) => {
    setFilteredCreatDate(id);
    setActiveClass(id);
  };

  const selectedDateHandler = (e) => {
    setSelectedDate(new Date(e.target.value).toLocaleDateString("en-US"));
  };

  const [startDate, setStartDate] = useState(new Date());

  const caseTypeHanler = (id) => {
    setFilteredCaseType(id?.name);
    setActiveClasscaseType(id);
  };

  const requestTypeHanler = (id) => {
    setFilteredRequestType(id?.name);
    setActiveClassrequestType(id);
  };

  const courtHanler = (id) => {
    setFilteredCourt(id?.name);
    setActiveClassCourt(id);
  };

  const caseStatusHanler = (id) => {
    setFilteredCaseStatus(id?.name);
    setActiveClassCaseStatus(id);
  };

  const resultHandler = () => {
    setFilteredRequests(filtered2);
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  const resetFilterHandler = () => {
    setFilteredCreatDate("");
    setSelectedDate("");
    setSelectedDate("");
    setFilteredCaseType("");
    setFilteredRequestType("");
    setFilteredCourt("");
    setFilteredCaseStatus("");
    setActiveClass(false);
    setActiveClassCaseStatus(false);
    setActiveClassCourt(false);
    setActiveClasscaseType(false);
    setActiveClassrequestType(false);
    setFilteredRequests(servicesData);
    document.body.style.overflow = "unset";
  };

  const performFirstAction = () => {
    setFeedBackIsOpen(true);
  };

  const performSecondAction = (value1) => {
    setTimeout(() => {
      // Assume this is an async action
      console.log("val", value1);
      onSurvayRequest(value1);
    }, 100); // Delay for demonstration
  };

  const handleClick = (value1) => {
    performFirstAction();
    performSecondAction(value1);
  };

  return (
    <div className="w-full">
      {/* {isOpen && (
        <p
          className="text-[#fff] top-[88px] right-4 !z-20 fixed"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          x
        </p>
      )} */}
      <div
        className={`bottom-0  top-0 right-0 fixed bg-white w-full h-auto pt-[88px] overflow-x-hidden z-[30]${
          isOpen ? " translate-y-0" : "flex translate-y-full"
        } ease-in-out duration-500`}
      >
        <div className="flex items-center justify-between h-[56px] px-[24px] bg-[#eee2d0]">
          <p className="text-[14px] font-Almarai font-bold text-[#010202]">
            {lang === "en" ? "Filter" : "التصنيف"}
          </p>
          <img
            src="/assets/img/close.svg"
            alt=""
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
              document.body.style.overflow = "unset";
            }}
          />
        </div>

        <div className="px-[24px] mt-[16px]">
          <div>
            <h2 className="text-[16px] font-Almarai text-[#010202] leading-[1.5] mb-[12px]">
              {lang === "en"
                ? enPhrase["lblRequestDate"]
                : arPhrase["lblRequestDate"]}
            </h2>
            <div className="flex flex-wrap gap-[12px]">
              {/* {years.map((year, index) => (
                <div
                  className={`border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] ${
                    activeClass === year ? "bg-[#a98031]" : "bg-[#fff]"
                  } w-fit cursor-pointer`}
                  onClick={(id) => createDateHandler(year)}
                >
                  <p
                    className="text-[12px] font-Almarai text-[#010202] leading-[2]"
                    value={year}
                  >
                    {year}
                  </p>
                </div>
              ))} */}
              <div>
                {/* <input
                  placeholder={lang === "en" ? "Select Date" : "اختر التاريخ"}
                  type="text"
                  onFocus={(e) => (e.target.type = "date")}
                  onblur="(type='text')"
                  id="date"
                  onChange={selectedDateHandler}
                  className="border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] text-[12px] font-Almarai text-[#010202] leading-[2]"
                /> */}
                {/* <input
                  type="text"
                  onChange={selectedDateHandler}
                  className="border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] text-[12px] font-Almarai text-[#010202] leading-[2]"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  onblur={(e) => {
                    e.currentTarget.type = "text";
                    e.currentTarget.focus();
                  }}
                  placeholder="dd/mm/yyyy"
                  
                /> */}

                {/* <ReactDatePicker
                  onChange={selectedDateHandler}
                  dateFormat="Pp"
                /> */}
                {/* <ReactDatePicker
                  selected={selectedDate}
                  placeholder="Choose a date"
                  className="border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] text-[12px] font-Almarai text-[#010202] leading-[2]"
                  onChange={(date) =>
                    setSelectedDate(new Date(date).toLocaleDateString("en-US"))
                  }
                /> */}
                <DatePicker
                  selected={selectedDate}
                  className="border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] text-[12px] font-Almarai ms-7 text-[#010202] leading-[2] focus:!border-[#e6d7a2]"
                  onChange={(date) =>
                    setSelectedDate(new Date(date).toLocaleDateString("en-US"))
                  }
                  // maxDate={new Date()}
                  showIcon
                  showYearDropdown
                  scrollableMonthYearDropdown
                  name={'"month"'}
                  openTo="month"
                  disabledDays
                  calendarIconClassname="bg-[#e6d7a2] ms-full"
                  placeholderText={
                    lang === "en"
                      ? enPhrase["lblSelectDate"]
                      : arPhrase["lblSelectDate"]
                  }
                  calendarClassName="bg-[#e6d7a2] border-[1px] border-solid border-black"
                />
              </div>
            </div>
          </div>

          <div className="mt-[24px]">
            <h2 className="text-[16px] font-Almarai text-[#010202] leading-[1.5] mb-[12px]">
              {lang === "en" ? enPhrase["lblCourts"] : arPhrase["lblCourts"]}
            </h2>
            <div className="flex flex-wrap gap-[12px]">
              {courts.map((court, index) => (
                <div
                  className={`border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] ${
                    activeClassCourt === court ? "bg-[#a98031]" : "bg-[#fff]"
                  } w-fit cursor-pointer`}
                  onClick={(id) => courtHanler(court)}
                >
                  <p
                    className={`text-[12px] font-Almarai ${
                      activeClassCourt === court
                        ? "text-[#fff]"
                        : "text-[#010202]"
                    }  leading-[2]`}
                    value={court}
                  >
                    {court?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-[24px]">
            <h2 className="text-[16px] font-Almarai text-[#010202] leading-[1.5] mb-[12px]">
              {lang === "en"
                ? enPhrase["lblCaseType"]
                : arPhrase["lblCaseType"]}
            </h2>
            <div className="flex flex-wrap gap-[12px]">
              {caseTypes.map((caseType, index) => (
                <div
                  className={`border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] ${
                    activeClasscaseType === caseType
                      ? "bg-[#a98031]"
                      : "bg-[#fff]"
                  } w-fit cursor-pointer`}
                  onClick={(id) => caseTypeHanler(caseType)}
                >
                  <p
                    className={`text-[12px] font-Almarai ${
                      activeClasscaseType === caseType
                        ? "text-[#fff]"
                        : "text-[#010202]"
                    }  leading-[2]`}
                    value={caseType}
                  >
                    {caseType?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-[24px]">
            <h2 className="text-[16px] font-Almarai text-[#010202] leading-[1.5] mb-[12px]">
              {lang === "en"
                ? enPhrase["lblApplicationType"]
                : arPhrase["lblApplicationType"]}
            </h2>
            <div className="flex flex-wrap gap-[12px]">
              {caseRequest.map((requestType, index) => (
                <div
                  className={`border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] ${
                    activeClassrequestType === requestType
                      ? "bg-[#a98031]"
                      : "bg-[#fff]"
                  } w-fit cursor-pointer`}
                  onClick={(id) => requestTypeHanler(requestType)}
                >
                  <p
                    className={`text-[12px] font-Almarai ${
                      activeClassrequestType === requestType
                        ? "text-[#fff]"
                        : "text-[#010202]"
                    }  leading-[2]`}
                    value={requestType}
                  >
                    {requestType?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-[24px]">
            <h2 className="text-[16px] font-Almarai text-[#010202] leading-[1.5] mb-[12px]">
              {lang === "en" ? enPhrase["lbStatues"] : arPhrase["lbStatues"]}
            </h2>
            <div className="flex flex-wrap gap-[12px]">
              {servicesStatus.map((caseStatus, index) => (
                <div
                  className={`border-[1px] border-solid border-[#e6d7a2] h-[32px] px-[8px] py-[4px] rounded-[4px] ${
                    activeClassCaseStatus === caseStatus
                      ? "bg-[#a98031]"
                      : "bg-[#fff]"
                  } w-fit cursor-pointer`}
                  onClick={(id) => caseStatusHanler(caseStatus)}
                >
                  <p
                    className={`text-[12px] font-Almarai ${
                      activeClassCaseStatus === caseStatus
                        ? "text-[#fff]"
                        : "text-[#010202]"
                    }  leading-[2]`}
                    value={caseStatus}
                  >
                    {caseStatus?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-[24px] shadow-[0_-3px_3px_0_rgba(0,0,0,0.3)] px-[16px] py-[12px] flex gap-[16px] items-center justify-center">
          <button
            className="bg-[#f2eccf] h-[44px] p-[8px] rounded-[4px] w-[126px]"
            onClick={resetFilterHandler}
          >
            <p className="text-[#815327] text-[14px] font-Almarai font-bold h-[16px]">
              {lang === "en"
                ? enPhrase["lblResetFilter"]
                : arPhrase["lblResetFilter"]}
            </p>
          </button>

          <button
            disabled={filtered2?.length === 0 ? true : false}
            className={`h-[44px] p-[8px] rounded-[4px] w-[200px] ${
              filtered2?.length === 0 ? "bg-[#c9c4ba] " : "bg-[#b68a35] "
            }`}
            onClick={resultHandler}
          >
            <p className="text-[#fff] text-[14px] font-Almarai font-bold h-[16px]">
              {lang === "en" ? enPhrase["lblShow"] : arPhrase["lblShow"]}
              {lang === "en"
                ? `(${filtered2?.length})`
                : `(${filtered2?.length})`}
              {lang === "en" ? enPhrase["lblResults"] : arPhrase["lblResults"]}
            </p>
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between  mb-[16px]">
        <div className="flex items-center gap-[8px] ">
          <p className="text-[16px] font-Almarai text-[#333] font-bold">
            {lang === "en"
              ? enPhrase["lblNumberOfApplications"]
              : arPhrase["lblNumberOfApplications"]}
          </p>
          <select
            className={
              lang === "en"
                ? "overview-FooterDropDownEn focus:border-gold-100 focus:ring-gold-100 "
                : "overview-FooterDropDown flex items-center justify-center focus:border-gold-100 focus:ring-gold-100"
            }
            value={requestPerPage}
            onChange={handleChangeRequestPerPage}
          >
            <option value="20" className="text-[14px]">
              20
            </option>

            <option value="30" className="text-[14px]">
              30
            </option>

            <option value="40" className="text-[14px]">
              40
            </option>
          </select>
        </div>
        <div className="flex items-center gap-[8px]">
          <p className="text-[16px] font-Almarai text-[#333] font-bold leading-[1.5] h-[24px]">
            {lang === "en" ? "Filter" : "تصنيف"}
          </p>
          <div className="w-[24px] h-[24px]  py-[4px] rounded-[4px] border-[1px] border-solid border-[#eee2d0] bg-[#fff] flex items-center justify-center">
            <img
              src="/assets/img/case-overview/FilterIcon.svg"
              alt=""
              className="w-[15.3px] h-[9.3px] cursor-pointer"
              onClick={sideMenuHandler}
            />
          </div>
        </div>
      </div>
      <div className="relative  flex items-center justify-center mb-[16px]">
        <input
          type="text"
          name="search"
          key="search"
          placeholder={
            lang === "en"
              ? enPhrase["lblSearchCase"]
              : arPhrase["lblSearchCase"]
          }
          className={`block w-full  h-10 px-[16px] rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300`}
          onChange={(e) => {
            handleFilteredRequests(e.target.value);
            setSearch(e.target.value);
          }}
        />

        <img
          src="/assets/img/button/search.svg"
          alt="search"
          className={
            lang === "en"
              ? "absolute top-3 right-3 w-5 h-5"
              : "absolute top-3 left-3 w-5 h-5"
          }
        />
      </div>

      {search === "" &&
      filteredCreatDate === "" &&
      selectedDate === "" &&
      filteredCaseStatus === "" &&
      filteredCourt === "" &&
      filteredRequestType === "" &&
      filteredCaseType === ""
        ? records?.map((service) => (
            <div className="w-full  border-[1px] border-[#f2eccf] border-solid rounded-[8px] mb-[16px] bg-[#ffffff] bg-LogoEJECommonCard bg-no-repeat bg-left ">
              <div className="w-full h-[44px] bg-[#F3EAD8] border-[1px] rounded-t-[8px] flex items-center px-[16px] py-[12px] justify-between">
                <div className="flex items-center gap-[8px] justify-center">
                  <div className="flex items-center gap-[4px] font-Almarai text-[14px]">
                    <p className="font-[400]">
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </p>
                    <span className="font-[500]">{service?.servicesId}</span>
                  </div>
                  <img src="/assets/img/case-overview/dot.svg" alt="" />
                  <p className="font-Almarai font-[700] text-[14px]">
                    {service?.serviceName}
                  </p>
                </div>

                <Link
                  to={`/order-details/${service?.caseId}/${service?.servicesId}`}
                  onClick={() => window.scrollTo(0, 0)}
                  className="flex items-center justify-center gap-[4px] font-bold text-[#b68a35] text-[14px]"
                >
                  <p>
                    {lang === "en"
                      ? enPhrase["lblDetails"]
                      : arPhrase["lblDetails"]}
                  </p>
                  <img
                    src="/assets/img/case-overview/chevron--sort--down.svg"
                    alt=""
                    className={lang === "en" ? "actionSectionImgEn" : ""}
                  />
                </Link>
              </div>
              <div className="p-[16px] flex flex-col gap-[8px]">
                <div className="flex gap-[4px] items-center">
                  <div className="flex items-center gap-[4px]  h-[20px] text-[14px] font-Almarai leading-[18.2px]">
                    <p className="text-[#737373]">
                      {lang === "en"
                        ? enPhrase["lblCase"]
                        : arPhrase["lblCase"]}
                    </p>
                    {service?.caseId !== 0 ? (
                      <Link
                        to={`/case-details/${service?.caseId}`}
                        className="underline text-[#AF8C45] font-Almarai font-[400] text-[14px]"
                      >
                        {service?.caseNumber?.slice(-12)}
                      </Link>
                    ) : (
                      <span className="text-[#010202] font-Almarai font-[400] text-[14px]">
                        {service?.caseNumber?.slice(-12)}
                      </span>
                    )}
                  </div>
                  <img src="/assets/img/case-overview/dot.svg" alt="dot_image" />
                  <span className="text-[#010202] font-Almarai font-[400] text-[14px] leading-[18.2px]">
                    {service?.caseTypeName}
                  </span>
                </div>

                <div className="flex w-[216px] h-[20px] text-[14px] font-Almarai leading-[1.43] justify-start">
                  <p className="text-[#a5a5a5]">
                    {lang === "en"
                      ? enPhrase["lblCreateDate"]
                      : arPhrase["lblCreateDate"]}
                    :
                  </p>
                  <span className="text-[#010202]">
                    {lang === "en"
                      ? new Date(service?.createdAt).toLocaleDateString("en-GB")
                      : new Date(service?.createdAt).toLocaleDateString("en-GB")}
                  </span>
                </div>

                <div className="flex  h-[20px] text-[14px] font-Almarai leading-[1.43]">
                  <p className="text-[#a5a5a5]">
                    {lang === "en"
                      ? enPhrase["lblCourt"]
                      : arPhrase["lblCourt"]}
                    :
                  </p>
                  <span className="text-[#010202]">{service?.courtName}</span>
                </div>

                <div className="flex items-center justify-between leading-[1.43] ">
                  <div
                    className={`flex items-center justify-start gap-[4px] !w-fit `}
                  >
                    <ServiceStatus serviceStatusId={service?.serviceStatusId} />
                    <p className="text-[12px] font-Almarai leading-[1.33] font-bold h-[16px] text-[#000]">
                      {service?.serviceStatus}
                    </p>
                  </div>
                  <div className="flex  h-[20px] text-[14px] font-Almarai leading-[1.43] justify-between">
                    {service?.serviceStatusId === 2 ||
                    service?.serviceStatusId === 3 ? (
                      <Link
                        to={`/order-details/${service?.caseId}/${service?.servicesId}`}
                        className="flex gap-[4px] underline decoration-[#af8c45] font-bold text-[#b68a35]"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {service?.serviceStatusId === 2 ? (
                          <p>
                            {lang === "en"
                              ? enPhrase["lblCompleteData"]
                              : arPhrase["lblCompleteData"]}
                          </p>
                        ) : service?.serviceStatusId === 3 ? (
                          <p>
                            {lang === "en"
                              ? enPhrase["lblPay"]
                              : arPhrase["lblPay"]}
                          </p>
                        ) : (
                          ""
                        )}
                        <img
                          src="/assets/img/case-overview/chevron--sort--down.svg"
                          alt=""
                          className={lang === "en" ? "actionSectionImgEn" : ""}
                        />
                      </Link>
                    ) : service?.serviceStatusId === 5 &&
                      service?.completeSurvey === false ? (
                      <p
                        className="flex gap-[4px] underline decoration-[#af8c45] font-bold text-[#b68a35]"
                        onClick={() => handleClick(service)}
                      >
                        {service?.serviceStatusId === 5 ? (
                          <p>
                            {lang === "en"
                              ? enPhrase["lbl_Survey"]
                              : arPhrase["lbl_Survey"]}
                          </p>
                        ) : (
                          ""
                        )}
                        <img
                          src="/assets/img/case-overview/chevron--sort--down.svg"
                          alt=""
                          className={lang === "en" ? "actionSectionImgEn" : ""}
                        />
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))
        : filtered2?.map((service) => (
            <div className="w-full  border-[1px] border-[#f2eccf] border-solid rounded-[8px] mb-[16px] bg-[#ffffff] bg-LogoEJECommonCard bg-no-repeat bg-left ">
              <div className="w-full h-[44px] bg-[#F3EAD8] border-[1px] rounded-t-[8px] flex items-center px-[16px] py-[12px] justify-between">
                <div className="flex items-center gap-[8px] justify-center">
                  <div className="flex items-center gap-[4px] font-Almarai text-[14px]">
                    <p className="font-[400]">
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </p>
                    <span className="font-[500]">{service?.servicesId}</span>
                  </div>
                  <img src="/assets/img/case-overview/dot.svg" alt="" />
                  <p className="font-Almarai font-[700] text-[14px]">
                    {service?.serviceName}
                  </p>
                </div>

                <Link
                  to={`/order-details/${service?.caseId}/${service?.servicesId}`}
                  onClick={() => window.scrollTo(0, 0)}
                  className="flex items-center justify-center gap-[4px] font-bold text-[#b68a35] text-[14px]"
                >
                  <p>
                    {lang === "en"
                      ? enPhrase["lblDetails"]
                      : arPhrase["lblDetails"]}
                  </p>
                  <img
                    src="/assets/img/case-overview/chevron--sort--down.svg"
                    alt=""
                    className={lang === "en" ? "actionSectionImgEn" : ""}
                  />
                </Link>
              </div>
              <div className="p-[16px] flex flex-col gap-[8px]">
                <div className="flex gap-[4px] items-center">
                  <div className="flex items-center gap-[4px]  h-[20px] text-[14px] font-Almarai leading-[18.2px]">
                    <p className="text-[#737373]">
                      {lang === "en"
                        ? enPhrase["lblCase"]
                        : arPhrase["lblCase"]}
                    </p>
                    {service?.caseId !== 0 ? (
                      <Link
                        to={`/case-details/${service?.caseId}`}
                        className="underline text-[#AF8C45] font-Almarai font-[400] text-[14px]"
                      >
                        {service?.caseNumber?.slice(-12)}
                      </Link>
                    ) : (
                      <span className="text-[#010202] font-Almarai font-[400] text-[14px]">
                        {service?.caseNumber?.slice(-12)}
                      </span>
                    )}
                  </div>
                  <img src="/assets/img/case-overview/dot.svg" alt="" />
                  <span className="text-[#010202] font-Almarai font-[400] text-[14px] leading-[18.2px]">
                    {service?.caseTypeName}
                  </span>
                </div>

                <div className="flex w-[216px] h-[20px] text-[14px] font-Almarai leading-[1.43] justify-start">
                  <p className="text-[#a5a5a5]">
                    {lang === "en"
                      ? enPhrase["lblCreateDate"]
                      : arPhrase["lblCreateDate"]}
                    :
                  </p>
                  <span className="text-[#010202]">
                    {lang === "en"
                      ? new Date(service?.createdAt).toLocaleDateString("en-GB")
                      : new Date(service?.createdAt).toLocaleDateString("en-GB")}
                  </span>
                </div>

                <div className="flex  h-[20px] text-[14px] font-Almarai leading-[1.43]">
                  <p className="text-[#a5a5a5]">
                    {lang === "en"
                      ? enPhrase["lblCourt"]
                      : arPhrase["lblCourt"]}
                    :
                  </p>
                  <span className="text-[#010202]">{service?.courtName}</span>
                </div>

                <div className="flex items-center justify-between leading-[1.43]">
                  <div
                    className={`flex items-center justify-start gap-[4px] !w-fit`}
                  >
                    <ServiceStatus serviceStatusId={service?.serviceStatusId} />
                    <p className="text-[12px] font-Almarai leading-[1.33] font-bold h-[16px] text-[#000]">
                      {service?.serviceStatus}
                    </p>
                  </div>
                  <div className="flex  h-[20px] text-[14px] font-Almarai leading-[1.43] justify-between">
                    {service?.serviceStatusId === 2 ||
                    service?.serviceStatusId === 3 ? (
                      <Link
                        to={`/order-details/${service?.caseId}/${service?.servicesId}`}
                        className="flex gap-[4px] underline decoration-[#af8c45] font-bold text-[#b68a35]"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {service?.serviceStatusId === 2 ? (
                          <p>
                            {lang === "en"
                              ? enPhrase["lblCompleteData"]
                              : arPhrase["lblCompleteData"]}
                          </p>
                        ) : service?.serviceStatusId === 3 ? (
                          <p>
                            {lang === "en"
                              ? enPhrase["lblPay"]
                              : arPhrase["lblPay"]}
                          </p>
                        ) : (
                          ""
                        )}
                        <img
                          src="/assets/img/case-overview/chevron--sort--down.svg"
                          alt=""
                          className={lang === "en" ? "actionSectionImgEn" : ""}
                        />
                      </Link>
                    ) : service?.serviceStatusId === 5 &&
                      service?.completeSurvey === false ? (
                      <p
                        className="flex gap-[4px] underline decoration-[#af8c45] font-bold text-[#b68a35]"
                        onClick={() => handleClick(service)}
                      >
                        {service?.serviceStatusId === 5 ? (
                          <p>
                            {lang === "en"
                              ? enPhrase["lbl_Survey"]
                              : arPhrase["lbl_Survey"]}
                          </p>
                        ) : (
                          ""
                        )}
                        <img
                          src="/assets/img/case-overview/chevron--sort--down.svg"
                          alt=""
                          className={lang === "en" ? "actionSectionImgEn" : ""}
                        />
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
      {/* <PaginationMobile
        currentPage={currentPage}
        requestPerPage={requestPerPage}
        numbers={numbers}
        indexOfLastRequest={indexOfLastRequest}
        indexOfFirstRequest={indexOfFirstRequest}
        setRequestPerPage={setRequestPerPage}
        setCurrentPage={setCurrentPage}
        npage={npage}
      /> */}
      {filteredRequests.length === 0 ? null : (
        <Pagination
          isMobile
          requestPerPage={requestPerPage}
          totalRequests={filteredRequests?.length}
          paginate={paginate}
          currentPage={currentPage}
          filtered2={filtered2}
          lang={lang}
        />
      )}
    </div>
  );
};

export default TableMobile;
