import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PhrasesContext } from "../../../context/phrasesContext";
import "react-phone-number-input/style.css";
import { Loader } from "../../../components/utils";
import { addComment } from "../../../services/Common";
import SharedModal from "../../happiness/SharedModal";
import { RequesterContext } from "../../../context/requesterContext";
const PopupForm = ({
  handelOpenPopup,
  getCommentsService,
  serviceid,
  setOpenPopub,
}) => {
  const maxOffset = 60;
  const [lang, setLang] = useState("");
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [requesterInfo] = useContext(RequesterContext);
  const [validationError, setValidationError] = useState(false); // Add validation state
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    const year = new Date().getFullYear();
    setYears(Array.from(new Array(maxOffset), (val, index) => year - index));

    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
  }, []);


  const onSubmit = async (data) => {
    // Validate if at least one file is uploaded
    if (uploadedImages.length === 0) {
      setError("image", {
        type: "manual",
        message:
          lang === "en"
            ? "At least one file is required."
            : "يجب رفع ملف واحد على الأقل",
      });
      return; // Prevent form submission
    }

    setLoading(true);

    let commentInfo = {
      ServiceId: serviceid,
      UserId: requesterInfo?.id,
      UserType: 1,
      Comment: data.comment,
      Files: uploadedImages.map((img) => img.file),
      
    };

    addCommentService(commentInfo);
  };

  const addCommentService = (data) => {
    addComment(data).then(
      (res) => {
        setLoading(false);
        if (res?.success) {
          handelOpenPopup(false);
          getCommentsService();
        } else {
          handelOpenPopup(false);
        }
      },
      (err) => {
        handelOpenPopup(false);
      }
    );
  };

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];
    if (!allowedTypes.includes(file.type)) {
      setValidationError(
        lang === "en"
          ? "Only images (png, jpg, jpeg, gif) and PDFs are allowed."
          : "يُسمح فقط بتحميل الصور وملفات PDF."
      );

      return;
    }

    setValidationError("");
    clearErrors("image");

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUploadedImages((prevImages) => [
        ...prevImages,
        { file, preview: reader.result, type: file.type },
      ]);

      e.target.value = "";
    };
    setValue("image", file, { shouldValidate: true });
  };

  const removeImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    if (uploadedImages.length === 1) {
      setValue("image", null);
    }
  };

  const openImageModal = (image, fileType) => {
    setSelectedImage({ preview: image, type: fileType });
    setShowImage(true);
  };


  console.log("uploadedImages",uploadedImages)

  return (
    <div className="w-full ">
      <form
        className="w-full "
        id="formreceipt"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col overflow-hidden border-2 border-solid border-[#EEE2D0] shadow-[0px_10px_40px_#361E1214] rounded-t-[16px]">
          <div className="text-sm text-[#010202]  font-bold bg-[#EEE2D0] h-[40px] leading-[40px] px-2 flex items-center justify-between gap-[20px]">
            <span>
              {lang === "en"
                ? enPhrase["lblCompleteData"]
                : arPhrase["lblCompleteData"]}
            </span>
            <div
              className="text-[#000] w-[24px] h-[24px] text-[20px] cursor-pointer flex items-center justify-center"
              onClick={() => setOpenPopub(false)}
            >
              <img src="/assets/img/close.svg" alt="close" className="" />
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-b-[16px]">
          <div className="bg-white mb-4">
            <label
              htmlFor="comment"
              className="block text-[12px] font-bold text-gray-700"
            >
              {lang === "en" ? enPhrase["lblDetails"] : arPhrase["lblDetails"]}
            </label>
            <textarea
              name="comment"
              key="comment"
              placeholder={
                lang === "en"
                  ? enPhrase["lblWriteDetails"]
                  : arPhrase["lblWriteDetails"]
              }
              aria-invalid={errors.comment ? "true" : "false"}
              {...register("comment", {
                required: true,
              })}
              className={`mt-2 block w-full rounded-md h-[96px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm resize-none ${
                errors.comment ? " border-red-600" : " border-gray-300"
              }`}
            />
            {errors.comment && (
              <span role="alert" className=" text-xs text-red-700">
                {lang === "en"
                  ? enPhrase["lblErrorDetails"]
                  : arPhrase["lblErrorDetails"]}
              </span>
            )}
          </div>

          {showImage && (
            <SharedModal
              showImage
              setOpenModal={setShowImage}
              openModal={showImage}
              imageSrc={selectedImage}
            >
              <div className=" w-full rounded-t-[16px]">
                <div className="h-[56px] w-full flex items-center justify-end px-[24px] bg-[#eee2d0]">
                  <img
                    src="/assets/img/close.svg"
                    alt=""
                    className="cursor-pointer"
                    onClick={() => setShowImage(false)}
                  />
                </div>
                {selectedImage?.type?.includes("pdf") ? (
                  <embed
                    src={selectedImage.preview}
                    type="application/pdf"
                    className="w-full h-[288px] rounded-b-[16px]"
                  />
                ) : (
                  <img
                    className="w-full h-[288px] rounded-b-[16px]"
                    alt="Preview"
                    src={selectedImage.preview}
                  />
                )}
              </div>
            </SharedModal>
          )}

          {uploadedImages.length > 0 && (
            <div className="mt-4 space-y-2">
              {uploadedImages.map((img, index) => (
                <div
                  key={index}
                  className="w-full  h-[66px] p-[16px] rounded-[4px] border-[1px] border-solid border-[#d6d6d6] bg-[#fff] flex items-center justify-between gap-[16px]"
                >
                  <div className="flex items-center justify-center gap-[8px]">
                    <img
                      src="/assets/img/submission.svg"
                      alt="upload"
                      className="w-[25px] h-[30px]"
                    />
                    <div className="text-sm">
                      <p className="text-[14px] font-Almarai font-bold text-[#361e12]  w-[50px] sm:w-[237px] h-[20px] overflow-hidden ">
                        {img.file.name}
                      </p>
                      <p className="text-[12px] font-Almarai  text-[#6e6e6e]">
                        {(img.file.size / 1024).toFixed(2)} KB
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-[8px]">
                    <img
                      src="/assets/img/view.svg"
                      alt="view-icon"
                      className="w-[24px] h-[24px] cursor-pointer"
                      onClick={() => openImageModal(img.preview, img.type)}
                    />

                    <img
                      src="/assets/img/trash-can.svg"
                      alt="delete-icon"
                      className="w-[18px] h-[18px] cursor-pointer"
                      onClick={() => removeImage(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="my-4 cursor-pointer flex flex-col">
            <label
              htmlFor="fileUpload"
              className={`relative ${
                uploadedImages.length === 2
                  ? "cursor-not-allowed !bg-[#ccc]"
                  : "cursor-pointer"
              } flex w-[100%] gap-[12px] items-center justify-center w-100 h-[48px] border-2 border-dashed bg-gold-10 border-[#B4B4B4] r rounded-md  px-6  font-medium text-gold-100 focus-within:outline-none focus-within:ring-2`}
            >
              <img
                src="/assets/img/uploadFile.svg"
                alt="addFile"
                className="w-[20px] h-[20px]"
              />
              {uploadedImages.length === 0 ? (
                <p className="text-[10px] xsm:text-[12px] text-[#6C4527] font-bold">
                  {lang === "en"
                    ? enPhrase["lblAttachFile"]
                    : arPhrase["lblAttachFile"]}
                </p>
              ) : (
                <p
                  className={`text-[10px] xsm:text-[12px]  font-bold ${
                    uploadedImages.length === 2
                      ? "!text-[#838383]"
                      : "text-[#6C4527]"
                  }`}
                >
                  {lang === "en"
                    ? enPhrase["lblAttachAnotherFile"]
                    : arPhrase["lblAttachAnotherFile"]}
                </p>
              )}
              <input
                type="file"
                accept="image/*,application/pdf"
                onChange={handleFileUpload}
                className="hidden"
                id="fileUpload"
                disabled={uploadedImages.length === 2}
              />
            </label>
            {errors.image && (
              <p className="text-red-700 text-xs mt-[5px]">
                {lang === "en"
                  ? enPhrase["lblAttacheFileError"]
                  : arPhrase["lblAttacheFileError"]}
              </p>
            )}
          </div>

          {validationError && (
            <p className="text-red-700 text-xs ">
              {lang === "en"
                ? enPhrase["lblOnlyImgAndPDF"]
                : arPhrase["lblOnlyImgAndPDF"]}
            </p>
          )}

          <div className=" flex justify-end bg-white">
            <button
              type="submit"
              disabled={loading}
              className="inline-flex justify-center gap-2 items-center w-full h-[44px] rounded-md border border-transparent bg-gold-100 py-4 px-8 text-sm font-bold text-white shadow-sm"
            >
              <span>
                {lang === "en"
                  ? enPhrase["lblResponse"]
                  : arPhrase["lblResponse"]}
              </span>
              {loading && <Loader color={"white"} />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PopupForm;
