import React, { useContext } from "react";
import { PhrasesContext } from "../../context/phrasesContext";

const FalidUploadReciept = ({
  lang,
  setFalidUploadReciept,
  setUploadedFile,
}) => {
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const actionHandler = () => {
    setFalidUploadReciept(false);
    setUploadedFile("");
  };
  return (
    <div className="relative pt-[56px] pb-[24px] flex flex-col items-center gap-[24px] w-full px-[16px]">
      <img
        src="/assets/img/close-icon-upload.svg"
        alt=""
        className={`absolute top-[8px] ${
          lang === "en" ? "right-[8px]" : "left-[8px]"
        }`}
        onClick={actionHandler}
      />
      <img src="/assets/img/FalidUpload.svg" alt="FalidUpload" className="" />
      <div className="px-[16px] flex flex-col gap-[16px]">
        <p className="font-bold font-Almarai text-[20px] md:text-[24px]  md:leading-[40px] text-center text-[#010202]">
          {lang === "en"
            ? enPhrase["lblUploadReciptFailure"]
            : arPhrase["lblUploadReciptFailure"]}
        </p>
        <p className="font-Almarai text-[16px] leading-[24px] text-center text-[#666]">
          {lang === "en"
            ? enPhrase["lblUploadRecipttxtFailure"]
            : arPhrase["lblUploadRecipttxtFailure"]}
        </p>
      </div>
      <button
        className="w-full bg-[#B68A35] rounded-[4px] p-[8px] h-[44px] mx-[16px]"
        onClick={actionHandler}
      >
        <span className="w-[83px] h-[16px] text-[14px] leading-[15.62px] font-Almarai text-[#fff]">
          {lang === "en" ? enPhrase["btnTryAgain"] : arPhrase["btnTryAgain"]}
        </span>
      </button>
    </div>
  );
};

export default FalidUploadReciept;
