import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import SharedModal from "../../components/happiness/SharedModal";
import { Loader, toast } from "../../components/utils";
import { PhrasesContext } from "../../context/phrasesContext";
import { CreateTicket, getServiceCategories } from "../../services/Common";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import "./styles.css";
import ar from "react-phone-input-2/lang/ar.json";
import es from "react-phone-input-2/lang/es.json";
import Select from "react-select";
import ReactSelect from "react-select";
import { colors } from "@mui/material";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";
import { BorderColor, WidthFull } from "@mui/icons-material";


const ContactusModel = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      code: {
        name: "United Arab Emirates (UAE)",
        dial_code: "+971",
        code: "AE",
        flag: "🇦🇪",
      }, // Default value set here
    },
  });

  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [lang, setLang] = useState("");
  const [ticketCategories, settTicketCategories] = useState([]);
  const { setOpenModal } = props;

  // const [fullName, setFullName] = useState("");
  // const [email, setEmail] = useState("");
  // const [questoin, setQuestoin] = useState("");
  // const [message, setMessage] = useState("");
  // const [image, setImage] = useState(null);
  // const [fileName, setFileName] = useState("No Selected File");
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [file, setFile] = useState();
  // const [fileUrl, setFileUrl] = useState();
  const [showImage, setShowImage] = useState(false);

  const [mobilevalue, setMobilevalue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [mobileNumber, setMobileNumber] = useState({
    contactno: "",
    country_code: "+971",
  });
  const [mobilePhoneError, setMobilePhoneError] = useState(false);
  const [validationError, setValidationError] = useState(false); // Add validation state

  const getInfoService = (currentLang) => {
    return getServiceCategories(currentLang).then((res) => {
      settTicketCategories(res);
    });
  };

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }

    getInfoService(currentLang);
  }, []);

  const updateData = (data) => {
    setLoading(true);

    CreateTicket(data).then(
      (res) => {
        if (res) {
          setLoading(false);
          setUploadedFile("")
          setFile("")
          console.log("respnsoe", res);
          const title =
            lang === "en" ? enPhrase["lbl_Success"] : arPhrase["lbl_Success"];
          const success =
            lang === "en"
              ? enPhrase["lbl_Success_desc"]
              : arPhrase["lbl_Success_desc"];
          const ok = lang === "en" ? enPhrase["lbl_Ok"] : arPhrase["lbl_Ok"];
          toast(title, success, ok);
          document.getElementById("formcontact").reset();
        } else if (res.status === 400) {
          const title =
            lang === "en" ? enPhrase["lbl_Error"] : arPhrase["lbl_Error"];
          const error =
            lang === "en"
              ? enPhrase["lbl_Faild_desc"]
              : arPhrase["lbl_Faild_desc"];
          const ok = lang === "en" ? enPhrase["lbl_Ok"] : arPhrase["lbl_Ok"];
          toast(title, error, ok, "error");
          setLoading(false);
        }
      },
      (err) => {
        console.log("respnsoe", err);
        const title =
          lang === "en" ? enPhrase["lbl_Error"] : arPhrase["lbl_Error"];
        const error =
          lang === "en"
            ? enPhrase["lbl_Faild_desc"]
            : arPhrase["lbl_Faild_desc"];
        const ok = lang === "en" ? enPhrase["lbl_Ok"] : arPhrase["lbl_Ok"];
        toast(title, error, ok, "error");
        setLoading(false);
      }
    );
  };
  // const handleFileChange = (e) => {
  //   console.log("Upload", e.target.files);
  //   if (e.target.files) {
  //     const reader = new FileReader();
  //     const url = reader.readAsDataURL(e.target.files[0]);
  //     reader.onloadend = () => {
  //       setFileUrl(reader.result);
  //       console.log(url);
  //     };
  //     setFile(e.target.files[0]);
  //     console.log("DATAFILE",e.target.files[0]);
  //   }
  // };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const onSubmit = async (data) => {
    console.log("data,data", data);
    const ticket = {
      // ticketCategoryId: data.ticketCategoryId,
      name: data.fullname,
      email: data.email,
      message: data.message,
      file: uploadedFile,

      Code: data?.code?.dial_code,
      Mobile: data?.mobile,
    };
    updateData(ticket);
  };

  // const handleMobileNumber = (e, value, name) => {
  //   console.log("value", value);
  //   console.log("name", name);

  //   if (name === "contactno") {
  //     let splitMobile = e?.split(value?.dialCode);
  //     setMobileNumber({
  //       ...mobileNumber,
  //       country_code: value?.dialCode,
  //       contactno: splitMobile?.[1] || "",
  //     });
  //   } else {
  //     setMobileNumber(e.target.value);
  //   }
  // };

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#ccc",
        primary: "#E5E7EB",
      },
    };
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fff",
      height: "42px",
      borderRadius: "0.375rem",

      borderColor: errors.code || errors.mobile ? "red" : "#ccc",

      "*": {
        boxShadow: "none !important",
      },
      "&:hover": {
        borderColor: errors.code || errors.mobile ? "red" : "#ccc",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      width: "max-content",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      display: "block",
      position: "absolute !important",

      left: "3px !important",
      height: "20px !important",
    }),
    myDropDown__indicator: (base) => ({
      ...base,
      "&.myDropDown__dropdown-indicator": {
        "&.indicatorContainer": {
          color: "#000000",
        },
      },
    }),
    "&.indicatorContainer": {
      color: "#000000",
    },
  };

  const customStylesEN = {
    control: (base, state) => ({
      ...base,
      background: "#fff",
      height: "42px",
      borderRadius: "0.375rem",
      borderColor: errors.code || errors.mobile ? "red" : "#ccc",
      "*": {
        boxShadow: "none !important",
      },
      "&:hover": {
        borderColor: errors.code ? "red" : "#ccc",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      width: "max-content",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      display: "block",
      position: "absolute !important",

      right: "3px !important",
      height: "20px !important",
    }),
    myDropDown__indicator: (base) => ({
      ...base,
      "&.myDropDown__dropdown-indicator": {
        "&.indicatorContainer": {
          color: "#000000",
        },
      },
    }),
    "&.indicatorContainer": {
      color: "#000000",
    },
  };

  const codeChangeHandler = (selectedOption) => {
    console.log("selectedOption", selectedOption?.target?.value?.dial_code);
    setMobileNumber({
      ...mobileNumber,
      country_code: selectedOption?.target?.value?.dial_code,
    });
    clearErrors("code");
    clearErrors("mobile");
  };

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    if (lang === "en") {
      const data = require("./countriesEn.json");
      setCountries(data);
    } else {
      const dataAr = require("./countriesAr.json");
      setCountries(dataAr);
    }
    console.log("data,data", countries);
  }, [lang]);

  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const formatOptionLabel = ({ name, dial_code, code }, { context }) => {
    if (context === "menu") {
      return (
        <div
          className="flex flex-row gap-[30px] items-center"
          value={dial_code}
        >
          <div className="flex flex-row gap-[15px] items-center">
            {/* <img
              src={`http://www.geonames.org/flags/x/${code.toLowerCase()}.gif`}
              alt="country-image"
              className="w-[15px] h-[15px] md:w-[24px] md:h-[15px] rounded-sm"
            /> */}
            <img
              alt={name}
              src={`http://www.geonames.org/flags/x/${code.toLowerCase()}.gif`}
              className="w-[15px] h-[15px] md:w-[24px] md:h-[15px] rounded-sm"
              onError={(e) => {
                console.error("Failed to load image:", e.target.src);
                e.target.src = "/path-to-fallback-image/fallback.png"; // Specify a fallback image
              }}
            />
            <span className="text-[#333] text-[12px]" dir="ltr">
              {dial_code}
            </span>
          </div>
          <span className="text-[#333] text-[12px]">{name}</span>
        </div>
      );
    } else {
      // This is how the selected option will be displayed in the input field
      return (
        <div
          className="flex flex-row gap-[30px] items-center"
          value={dial_code}
        >
          <div className="flex flex-row gap-[15px] items-center">
            <img
              src={`http://www.geonames.org/flags/x/${code.toLowerCase()}.gif`}
              alt="country-image"
              className="w-[15px] h-[15px] md:w-[24px] md:h-[15px] rounded-sm"
            />
            <span className="text-[#333] text-[12px]" dir="ltr">
              {dial_code}
            </span>
          </div>
        </div>
      );
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // Allowed file types
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf",
    ];

    // Validate file type
    if (!allowedTypes.includes(file.type)) {
      setValidationError(
        lang === "en"
          ? "Only images (PNG, JPG, GIF) and PDFs are allowed."
          : "يُسمح فقط بتحميل الصور (PNG, JPG, GIF) وملفات PDF."
      );
      return;
    }

    // Clear error if valid file type
    setValidationError("");

    setUploadedFile(file);
    getBase64(file, (res) => {
      setFile(res);
    });
  };

  return (
    <div className="relative">
      <div className=" bg-[#eee2d0] w-full rounded-t-[16px]">
        <div className="h-[56px] w-full flex items-center px-[24px] justify-between">
          <h2 className="text-[14px] font-Almarai font-bold h-[16px] text-[#010202]">
            {lang === "en"
              ? enPhrase["lblRequestHelp"]
              : arPhrase["lblRequestHelp"]}
          </h2>
          <img
            src="/assets/img/close.svg"
            alt=""
            className="cursor-pointer"
            onClick={() => setOpenModal(false)}
          />
        </div>
      </div>
      <div className="p-[24px] max-w-[712px] text-[24px] font-Almarai ">
        <form id="formcontact" onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="space-y-6 bg-white  py-[16px] ">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="fullname"
                    className={`block text-sm font-medium ${
                      errors.fullname ? "text-red-600" : "text-gray-700"
                    }`}
                  >
                    {lang === "en"
                      ? enPhrase["lbl_Name"]
                      : arPhrase["lbl_Name"]}
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    key="fullname"
                    placeholder={
                      lang === "en"
                        ? enPhrase["lbl_Name"]
                        : arPhrase["lbl_Name"]
                    }
                    aria-invalid={errors.fullname ? "true" : "false"}
                    {...register("fullname", {
                      required: true,
                    })}
                    className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                      errors.fullname ? " border-red-600" : " border-gray-300"
                    }`}
                  />
                  {errors.fullname && (
                    <span role="alert" className=" text-xs text-red-600">
                      {lang === "en"
                        ? enPhrase["lbl_error_name"]
                        : arPhrase["lbl_error_name"]}
                    </span>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className={`block text-sm font-medium  ${
                      errors.email ? "text-red-600" : "text-gray-700"
                    }`}
                  >
                    {lang === "en"
                      ? enPhrase["lbl_Email"]
                      : arPhrase["lbl_Email"]}
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder={
                      lang === "en"
                        ? enPhrase["lbl_Email"]
                        : arPhrase["lbl_Email"]
                    }
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register("email", {
                      required: `${
                        lang === "en"
                          ? enPhrase["lbl_email_error"]
                          : arPhrase["lbl_email_error"]
                      }`,
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: `${
                          lang === "en"
                            ? enPhrase["lblEmailCorrectError"]
                            : arPhrase["lblEmailCorrectError"]
                        }`,
                      },
                    })}
                    autoComplete=" "
                    className={`mt-1 block w-full rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                      errors.email ? " border-red-600" : " border-gray-300"
                    }`}
                  />
                  {errors.email && (
                    <span role="alert" className=" text-xs text-red-600">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>
              </div>
              {/* // update mobile number with code country// */}
              <div className="col-span-6 sm:col-span-3">
                {/* NEW Solutions */}
                <label
                  htmlFor="code"
                  className={`block text-sm font-medium ${
                    errors.mobile ? "text-red-600" : "text-gray-700"
                  }`}
                >
                  {lang === "en"
                    ? enPhrase["lbl_Mobile_Number"]
                    : arPhrase["lbl_Mobile_Number"]}
                </label>
                <div className="flex flex-row gap-0 relative">
                  <Controller
                    name="code"
                    rules={{
                      required: "Case Type is required",
                      onChange: codeChangeHandler,
                    }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        theme={customTheme}
                        styles={lang === "en" ? customStylesEN : customStyles}
                        placeholder={
                          lang === "en"
                            ? enPhrase["lblMobileCode"]
                            : arPhrase["lblMobileCode"]
                        }
                        label="code"
                        error={false}
                        {...field}
                        className={`mt-1 block w-[40%] md:w-[18%] rounded-md border-gray-300 shadow-sm text-sm  ${
                          errors.code ? "!border-red-600" : "border-gray-300"
                        }`}
                        classNamePrefix="select"
                        defaultValue={{
                          name: "United Arab Emirates (UAE)",
                          flag: "🇦🇪",
                          code: "AE",
                          dial_code: "+971",
                        }}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={false}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="caseType"
                        options={countries}
                        getOptionValue={(options) => options["id"]}
                        getOptionLabel={(option) =>
                          `${option.name}: ${option.dial_code}`
                        }
                        formatOptionLabel={formatOptionLabel}
                      />
                    )}
                  />

                  <input
                    type="number"
                    name="mobile"
                    key="mobile"
                    placeholder={
                      lang === "en"
                        ? enPhrase["lblMobileNumber"]
                        : arPhrase["lblMobileNumber"]
                    }
                    aria-invalid={errors.mobile ? "true" : "false"}
                    {...register("mobile", {
                      required: true,
                      maxLength: `${mobileNumber.country_code === "+971" && 9}`,
                      minLength: `${mobileNumber.country_code === "+971" && 9}`,
                    })}
                    className={`absolute w-[61.5%] md:w-[82.5%] h-[42px]  ${
                      lang === "en"
                        ? "left-[38.5%] md:left-[17.5%] border-l-0 rounded-l-none "
                        : "right-[38.5%] md:right-[17.5%] border-r-0 rounded-r-none "
                    } appearance-text mt-1 block  rounded-md    sm:text-sm remove-arrow shadow-sm ${
                      errors.mobile || errors.code
                        ? " border-red-600 focus:border-red-600 focus:ring-red-600"
                        : " border-[#cccccc] focus:border-[#ccc] focus:ring-[#cccccc]"
                    }`}
                  />
                </div>

                {errors.mobile?.type === "minLength" ||
                errors.mobile?.type === "maxLength" ? (
                  <span role="alert" className=" text-xs text-red-600">
                    {lang === "en"
                      ? enPhrase["lbl_error_mobile_number"]
                      : arPhrase["lbl_error_mobile_number"]}
                  </span>
                ) : errors.code ? (
                  <span role="alert" className=" text-xs text-red-600">
                    {lang === "en"
                      ? enPhrase["lblErrorMobileCode"]
                      : arPhrase["lblErrorMobileCode"]}
                  </span>
                ) : errors.mobile ? (
                  <span role="alert" className=" text-xs text-red-600">
                    {lang === "en"
                      ? enPhrase["lblErrorMobileNumberFormat"]
                      : arPhrase["lblErrorMobileNumberFormat"]}
                  </span>
                ) : null}
              </div>

              {/* // update// */}
              {/* <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="enqFor"
                  className={`block text-sm font-medium ${
                    errors.ticketCategoryId ? "text-red-600" : "text-gray-700"
                  }`}
                >
                  {lang === "en"
                    ? enPhrase["lbl_Enquery"]
                    : arPhrase["lbl_Enquery"]}
                </label>
                <select
                  id="enqFor"
                  name="enqFor"
                  placeholder={
                    lang === "en"
                      ? enPhrase["lblApplicationType"]
                      : arPhrase["lblApplicationType"]
                  }
                  autoComplete="enqFor"
                  aria-invalid={errors.ticketCategoryId ? "true" : "false"}
                  {...register("ticketCategoryId", {
                    required: true,
                  })}
                  className={`mt-1 block w-full rounded-md   border-gray-300 bg-white py-2 px-3 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                    errors.ticketCategoryId
                      ? " border-red-600"
                      : " border-gray-300"
                  } ${lang === "en" ? " bg-right" : " bg-left"}`}
                >
                  <option
                    value=""
                    className="text-slate-100"
                    disabled
                    selected
                    hidden
                  >
                    {lang === "en"
                      ? enPhrase["lblApplicationTypeDot"]
                      : arPhrase["lblApplicationTypeDot"]}
                  </option>

                  {ticketCategories.map((ticketCategorie) => (
                    <option key={ticketCategorie.id} value={ticketCategorie.id}>
                      {ticketCategorie?.name}
                    </option>
                  ))}
                </select>
                {errors.ticketCategoryId && (
                  <span role="alert" className=" text-xs text-red-600">
                    {lang === "en"
                      ? enPhrase["lbl_Error_EnquiryFor"]
                      : arPhrase["lbl_Error_EnquiryFor"]}
                  </span>
                )}
              </div> */}

              <div>
                <label
                  htmlFor="message"
                  className={`block text-sm font-medium ${
                    errors.message ? "text-red-600" : "text-gray-700"
                  }`}
                >
                  {lang === "en"
                    ? enPhrase["lbl_message"]
                    : arPhrase["lbl_message"]}
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    {...register("message", {
                      required: true,
                    })}
                    rows={3}
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm  focus:border-gold-100 focus:ring-gold-100 sm:text-sm resize-none  ${
                      errors.message ? " border-red-600" : " border-gray-300"
                    }`}
                    placeholder={
                      lang === "en"
                        ? enPhrase["lbl_about_your_project"]
                        : arPhrase["lbl_about_your_project"]
                    }
                    defaultValue={""}
                  />
                  {errors.message && (
                    <span role="alert" className=" text-xs text-red-600">
                      {lang === "en"
                        ? enPhrase["lbl_error_message"]
                        : arPhrase["lbl_error_message"]}
                    </span>
                  )}
                </div>
              </div>

              {console.log("errors", errors)}

              <div>
                {file ? (
                  <div className="w-full md:w-[412px] h-[66px] p-[16px] rounded-[4px] border-[1px] border-solid border-[#d6d6d6] bg-[#fff] flex items-center justify-start md:justify-center gap-[16px]">
                    <img
                      src="/assets/img/submission.svg"
                      alt="upload"
                      className="w-[25px] h-[30px]"
                    />
                    {showImage ? (
                      <SharedModal
                        showImage
                        setOpenModal={setShowImage}
                        openModal={showImage}
                      >
                        <div className="w-full rounded-t-[16px]">
                          <div className="h-[56px] w-full flex items-center justify-end px-[24px] bg-[#eee2d0]">
                            <img
                              src="/assets/img/close.svg"
                              alt=""
                              className="cursor-pointer"
                              onClick={() => setShowImage(false)}
                            />
                          </div>
                          {uploadedFile.type.includes("application/pdf") ===
                          true ? (
                            <embed
                              src={file}
                              className="w-full h-[288px] rounded-b-[16px]"
                            ></embed>
                          ) : (
                            <img
                              className="w-full h-[288px] rounded-b-[16px]"
                              alt="icon"
                              src={file}
                            />
                          )}
                        </div>
                      </SharedModal>
                    ) : (
                      <div className="flex flex-col h-[34px]  items-start justify-start">
                        <p className="text-[14px] font-Almarai font-bold text-[#361e12] w-[50px] sm:w-[237px]   h-[16px] truncate 	overflow-hidden ">
                          {uploadedFile.name}
                        </p>
                        <p className="text-[12px] font-Almarai  text-[#6e6e6e]">
                          {uploadedFile.size}KB{" "}
                        </p>
                      </div>
                    )}
                    <img
                      src="/assets/img/view.svg"
                      alt="view-icon"
                      className=" w-[24px] h-[24px] cursor-pointer"
                      onClick={() => setShowImage(!showImage)}
                    />
                    <img
                      src="/assets/img/trash-can.svg"
                      alt="delete-icon"
                      className="w-[18px] h-[18px] cursor-pointer"
                      onClick={() => setFile("")}
                    />
                  </div>
                ) : (
                  <div className="mt-1 flex justify-center rounded-md border-2 border-dashed bg-[#eee2d040] border-[#CACACA] px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center flex flex-col items-center">
                      {!file && (
                        <img
                          src="/assets/img/home/addDocument.svg"
                          alt="add Document"
                        />
                      )}
                      {file && (
                        <img className=" max-h-20" src={file} alt="img" />
                      )}
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md  font-medium text-gold-100 focus-within:outline-none focus-within:ring-2  mt-[16px]"
                        >
                          {!file && (
                            <span className="font-bold font-Almarai text-[14px] text-[#6C4527]">
                              {lang === "en"
                                ? enPhrase["lbl_upload_file"]
                                : arPhrase["lbl_upload_file"]}
                            </span>
                          )}

                          {file && <span>{uploadedFile.name}</span>}

                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            accept="image/png, image/gif, image/jpeg, application/pdf"
                            // onChange={handleFileChange}
                            // onChange={(e) => {
                            //   if (e.target.files.length > 0) {
                            //     setUploadedFile(e.target.files[0]);
                            //     getBase64(e.target.files[0], (res) => {
                            //       setFile(res);
                            //     });
                            //   } else {
                            //     setFile(null);
                            //   }
                            // }}
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                      {!file && (
                        <p className=" text-[#B68A35] text-[14px] font-Almarai ">
                          PNG, JPG, PDF up to 10MB
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {validationError && (
                <p className="text-red-700 text-xs ">
                  {lang === "en"
                    ? enPhrase["lblOnlyImgAndPDF"]
                    : arPhrase["lblOnlyImgAndPDF"]}
                </p>
              )}
            </div>
            <div className="py- justify-end flex ">
              <button
                type="submit"
                className={`w-full md:w-[168px] h-[48px] inline-flex justify-between md:justify-center ${
                  loading ? "gap-[20px]" : "gap-[70px]"
                } items-center rounded-md border border-transparent bg-gold-100 py-2 px-3 text-md font-medium text-white shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                <span className="text-[14px] font-Almarai font-bold text-[#fff]">
                  {lang === "en"
                    ? enPhrase["lbl_callback"]
                    : arPhrase["lbl_callback"]}
                </span>
                {loading && <Loader color={"white"} />}
                {lang === "en" ? (
                  <BsArrowRight size={20} />
                ) : (
                  <BsArrowLeft size={20} />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactusModel;
