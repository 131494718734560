import React, { useContext, useEffect, useState } from "react";
import ThemeLayout from "../layouts/ThemeLayout";
import Breadcrumb from "../components/breadCrumb";
import { PhrasesContext } from "../context/phrasesContext";
import { getServiceDetails } from "../services/Common";
import { useNavigate, useParams } from "react-router-dom";
import LargeTitle from "../components/case-tracking/LargeTitle";
import { USDollar } from "../utils/helpers";
import BankDetails from "../components/PaymentDiffrentMethod/BankDetails";
import SharedModal from "../components/happiness/SharedModal";
import ReceiptForm from "../components/case-tracking/ReceiptForm";

const PaymentViaBank = () => {
  const { caseid, serviceid } = useParams();
  const [lang, setLang] = useState("");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(true);
  const [receiptStatus, setReceiptStatus] = useState(false);
  const [successUploadReciept, setSuccessUploadReciept] = useState(false);
  const [falidUploadReciept, setFalidUploadReciept] = useState(false);
  const navigate = useNavigate();

  const uploadReceiptHandler = () => {
    setReceiptStatus(!receiptStatus);
  };

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getServiceDetailsService(currentLang);
  }, []);

  const getServiceDetailsService = (currentLang) => {
    return getServiceDetails(caseid, serviceid, currentLang).then((res) => {
      setService(res);
      // if (res?.isUploadReceipts) {
      //   // Redirect to order-details page
      //   navigate(`/order-details/${caseid}/${serviceid}`);
      // }
      setLoading(false);
    });
  };

  console.log("setService", service?.caseNumber);

  return (
    <ThemeLayout>
      {loading ? (
        <p className="text-[#b68a35] font-bold font-Almarai mt-[8px]">
          {lang === "en" ? "Loading..." : "جاري عرض البيانات..."}
        </p>
      ) : (
        <div className="flex flex-col w-full py-4 max-w-[1140px] px-[16px] lg:px-[0px]">
          <Breadcrumb
            title={
              lang === "en" ? enPhrase["lblMycases"] : arPhrase["lblMycases"]
            }
            subtitle1={`${
              lang === "en"
                ? `${service?.caseNumber}`
                : `${service?.caseNumber}`
            }`}
            subtitle1Href={`${
              service?.caseId === 0
                ? "/case-overview"
                : `/case-details/${service?.caseId}`
            }`}
            last={`${
              lang === "en"
                ? `${enPhrase["lblRequest"]} ${service?.id}`
                : `${service?.id} ${arPhrase["lblRequest"]}`
            }`}
            lastHref={`${`/order-details/${service?.caseId}/${service?.id}`}`}
            lastSecond={
              lang === "en"
                ? enPhrase["lblPaymentMethodBreadcrumb"]
                : arPhrase["lblPaymentMethodBreadcrumb"]
            }
            lastSecondHref={`/payment-method/${service?.caseId}/${service?.id}`}
            lastThird={
              lang === "en"
                ? enPhrase["lblPaymentViaBank"]
                : arPhrase["lblPaymentViaBank"]
            }
            lastThiedHref={`/payment-Via-Bank/${service?.caseId}/${service?.id}`}
          />

          <div className="flex flex-col xxsm:flex-row justify-between items-center gap-2 mb-8">
            <LargeTitle
              title={
                lang === "en"
                  ? enPhrase["lblPaymentViaBank"]
                  : arPhrase["lblPaymentViaBank"]
              }
              nullmargin
            />
          </div>

          <div className="w-full flex flex-col md:flex-row gap-[24px]">
            <div className="w-full md:w-[60%] flex flex-col items-center">
              <div className="flex items-center justify-between w-full border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] h-[72px] mb-[24px]">
                <p className="text-[20px] text-[#0E0D0C] leading-[24px] font-Almarai font-bold">
                  {lang === "en"
                    ? enPhrase["lblTotalAmount"]
                    : arPhrase["lblTotalAmount"]}
                </p>
                <div className="leading-normal flex items-center gap-[5px] font-medium	">
                  <span className="text-[#A98031] font-Almarai text-[20px] ">
                    {USDollar.format(service?.serviceValue)}
                  </span>

                  <span className="text-[20px] font-Almarai text-[#A98031]">
                    {lang === "en" ? "AED" : "درهم"}
                  </span>
                </div>
              </div>

              <div className="flex items-center  w-full border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] mb-[24px]">
                <div className="flex flex-col gap-[8px] w-full">
                  <p className="text-[20px] text-[#0E0D0C] leading-[24px] font-Almarai font-bold">
                    {lang === "en"
                      ? enPhrase["lblInvoice"]
                      : arPhrase["lblInvoice"]}
                  </p>
                  <p className="text-[16px] text-[#666] leading-[24px] font-Almarai font-[400] mb-[16px]">
                    {lang === "en"
                      ? enPhrase["lblInvoiceDetails"]
                      : arPhrase["lblInvoiceDetails"]}
                  </p>

                  <button
                    className={`w-full flex items-center justify-center bg-[#B68A35]  rounded-[4px] h-[48px] p-[8px] ${
                      service?.isPaid === true ||
                      service?.isUploadReceipts === true
                        ? "cursor-not-allowed opacity-50 "
                        : ""
                    }`}
                    onClick={uploadReceiptHandler}
                    disabled={
                      service?.isPaid === true ||
                      service?.isUploadReceipts === true
                    }
                  >
                    {service?.isUploadReceipts === true ? (
                      <p className="text-[16px] font-Almarai font-[700] text-[#fff] leading-normal">
                        {lang === "en"
                          ? enPhrase["lblReceiptUnderReview"]
                          : arPhrase["lblReceiptUnderReview"]}
                      </p>
                    ) : (
                      <p className="text-[16px] font-Almarai font-[700] text-[#fff] leading-normal">
                        {lang === "en"
                          ? enPhrase["lblInvoiceUpload"]
                          : arPhrase["lblInvoiceUpload"]}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[40%]">
              <BankDetails
                lang={lang}
                service={service}
                arPhrase={arPhrase}
                enPhrase={enPhrase}
              />
            </div>
          </div>

          {receiptStatus && (
            <SharedModal isReceiptForm setOpenModal={uploadReceiptHandler}>
              <ReceiptForm
                uploadReceiptHandler={uploadReceiptHandler}
                setReceiptStatus={setReceiptStatus}
                receiptStatus={receiptStatus}
                service={service}
                successUploadReciept={successUploadReciept}
                setSuccessUploadReciept={setSuccessUploadReciept}
                falidUploadReciept={falidUploadReciept}
                setFalidUploadReciept={setFalidUploadReciept}
              />
            </SharedModal>
          )}
        </div>
      )}
    </ThemeLayout>
  );
};

export default PaymentViaBank;
