import React, { useContext } from "react";
import { PhrasesContext } from "../../context/phrasesContext";
const Breadcrumb = ({
  title,
  subtitle1,
  subtitle1Href,
  subtitle2,
  last,
  lastHref,
  lastSecond,
  lastSecondHref,
  lastThird,
  lastThiedHref,
}) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  return (
    <nav class="w-full rounded-md mb-8 hidden md:flex">
      <ol class="list-reset flex items-center">
        <li>
          <a
            href="/case-overview"
            class={`text-[#AF8C45] ${
              lang === "en" ? "text-[13px]" : "text-[14px]"
            } md:text-[16px] transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600`}
          >
            {title}
          </a>
        </li>
        <li dir="ltr">
          {lang === "en" ? (
            <span className="mx-2 text-neutral-500 dark:text-neutral-400">
              {">"}
            </span>
          ) : (
            <span className="mx-2 text-neutral-500 dark:text-neutral-400">
              {"<"}
            </span>
          )}
        </li>
        {subtitle1 && (
          <>
            <li dir="ltr">
              <a
                href={subtitle1Href}
                class={`text-[#AF8C45] ${
                  lang === "en" ? "text-[13px]" : "text-[14px]"
                } md:text-[16px] transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600`}
              >
                {`${subtitle1 ? subtitle1.slice(-12) : "N/A"} ${
                  lang === "en" ? enPhrase["lblCase"] : arPhrase["lblCase"]
                }`}
              </a>
            </li>
            <li dir="ltr">
              {lang === "en" ? (
                <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                  {">"}
                </span>
              ) : (
                <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                  {"<"}
                </span>
              )}
            </li>
          </>
        )}
        {/* <li
          dir="ltr"
          class={`text-[#828282] dark:text-neutral-400 md:text-[16px] ${
            lang === "en" ? "text-[13px]" : "text-[14px]"
          }`}
        >
          {last}
        </li> */}
        <li>
          <a
            href={lastHref}
            class={`text-[#AF8C45] ${
              lang === "en" ? "text-[13px]" : "text-[14px]"
            } md:text-[16px] transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600`}
          >
            {last}
          </a>
        </li>

        {lastSecond && (
          <>
            <li dir="ltr">
              {lang === "en" ? (
                <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                  {">"}
                </span>
              ) : (
                <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                  {"<"}
                </span>
              )}
            </li>
            <li>
              <a
                href={lastSecondHref}
                class={`text-[#AF8C45] ${
                  lang === "en" ? "text-[13px]" : "text-[14px]"
                } md:text-[16px] transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600`}
              >
                {lastSecond}
              </a>
            </li>
          </>
        )}

        {lastThird && (
          <>
            <li dir="ltr">
              {lang === "en" ? (
                <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                  {">"}
                </span>
              ) : (
                <span className="mx-2 text-neutral-500 dark:text-neutral-400">
                  {"<"}
                </span>
              )}
            </li>
            <li>
              <a
                href={lastThiedHref}
                class={`text-[#AF8C45] ${
                  lang === "en" ? "text-[13px]" : "text-[14px]"
                } md:text-[16px] transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600`}
              >
                {lastThird}
              </a>
            </li>
          </>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
