import React, { useEffect, useRef, useState } from "react";

const BankDetails = ({ lang, service, enPhrase, arPhrase }) => {
  const info = useRef();
  const [copy, setCopy] = useState(false);
  const copyInfo = () => {
    const text = info.current.innerText;
    navigator.clipboard.writeText(text);
    setCopy(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  }, [copy]);
  return (
    <div className="flex flex-col items-center  w-full border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] mb-[24px]">
      <div className="flex flex-col mb-[16px]">
        <p className="text-[20px] text-[#0E0D0C] leading-[24px] font-Almarai font-bold mb-[8px]">
          {lang === "en"
            ? enPhrase["lblBankDetails"]
            : arPhrase["lblBankDetails"]}
        </p>

        <p className="text-[16px] text-[#666] leading-[24px] font-Almarai font-[400]">
          {lang === "en"
            ? enPhrase["lblBankDetailsText"]
            : arPhrase["lblBankDetailsText"]}
        </p>
      </div>

      <div className="flex flex-col items-start justify-start  w-full border-[0.5px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#FEFDFB] p-[12px]">
        <div className="flex flex-col">
          <p className="text-[14px] text-[#A98031] leading-[24px] font-Almarai font-[400]">
            {lang === "en" ? enPhrase["lblBankName"] : arPhrase["lblBankName"]}
          </p>
          <span className="text-[14px] text-[#15171A] leading-[24px] font-Almarai font-[500]">
            {lang === "en"
              ? enPhrase["lblBankNameText"]
              : arPhrase["lblBankNameText"]}
          </span>
        </div>
        <hr className="bg-[#DAB97B] text-[#DAB97B] my-[12px] w-full" />

        <div className="flex flex-col">
          <p className="text-[14px] text-[#A98031] leading-[24px] font-Almarai font-[400]">
            {lang === "en"
              ? enPhrase["lblAccountName"]
              : arPhrase["lblAccountName"]}
          </p>
          <span className="text-[14px] text-[#15171A] leading-[24px] font-Almarai font-[500]">
            {lang === "en"
              ? enPhrase["lblBankNameText"]
              : arPhrase["lblBankNameText"]}
          </span>
        </div>
        <hr className="bg-[#DAB97B] text-[#DAB97B] my-[12px] w-full" />

        <div className="flex flex-col">
          <p className="text-[14px] text-[#A98031] leading-[24px] font-Almarai font-[400]">
            {lang === "en"
              ? enPhrase["lblAccountNumber"]
              : arPhrase["lblAccountNumber"]}
          </p>
          <span className="text-[14px] text-[#15171A] leading-[24px] font-Almarai font-[500]">
            {lang === "en"
              ? enPhrase["lblAccountNumbers"]
              : arPhrase["lblAccountNumbers"]}
          </span>
        </div>
        <hr className="bg-[#DAB97B] text-[#DAB97B] my-[12px] w-full" />

        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col">
            <p className="text-[14px] text-[#A98031] leading-[24px] font-Almarai font-[400]">
              {lang === "en" ? enPhrase["lblIBAN"] : arPhrase["lblIBAN"]}
            </p>
            <span
              className="text-[14px] text-[#15171A] leading-[24px] font-Almarai font-[500]"
              ref={info}
            >
              {lang === "en"
                ? enPhrase["lblIBANNumber"]
                : arPhrase["lblIBANNumber"]}
            </span>
          </div>

       
          <button className="flex gap-[8px]" onClick={copyInfo}>
            {!copy ? (
              <>
                <img src="/assets/img/payment/Copy.svg" alt="" className="" />
                <p className="text-[14px] text-[#A98031] leading-[24px] font-Almarai font-[700]">
                  {lang === "en" ? enPhrase["lblCopy"] : arPhrase["lblCopy"]}
                </p>
              </>
            ) : (
              <p className="text-[14px] text-[#A98031] leading-[24px] font-Almarai font-[700]">
                {lang === "en" ? enPhrase["lblCopied"] : arPhrase["lblCopied"]}
              </p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
