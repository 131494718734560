import axios from "axios";
import classNames from "classnames/bind";
import { React, useContext, useEffect, useState } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";

const cx = classNames;

const Location = (props) => {
  const {
    lang,
    title,
    des,
    setActiveLocation,
    activeLocation,
    LocationId,
    phone,
    email,
    lat,
    lng,
    setMapMarkers,
    initMarkers,
    setMapZoom,
    inAll = false,
  } = props;
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [currentLongitude, setCurrentLongitude] = useState(0);

  const DefaultLocation = { lat: 0, lng: 0 };
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [permissionState, setPermissionState] = useState("unknown");

  const openLocationHandler = () => {
    if (activeLocation === LocationId) {
      setMapZoom(8);
      setActiveLocation(null);
      setMapMarkers(initMarkers);
    } else {
      setMapZoom(16);
      setActiveLocation(LocationId);
      setMapMarkers([
        {
          lat: lat,
          lng: lng,
          icon: "/assets/img/map/mark.svg",
          iconW: 80,
          iconH: 80,
          iconScale: 0.7,
        },
      ]);
    }
  };

  // Fetch fallback location with retry logic and caching
  //  const getFallbackLocation = async (retries = 3, delay = 1000) => {
  //   const cachedLocation = localStorage.getItem("fallbackLocation");
  //   if (cachedLocation) {
  //     console.log("Using cached fallback location");
  //     setDefaultLocation(JSON.parse(cachedLocation));
  //     return;
  //   }

  //   for (let i = 0; i < retries; i++) {
  //     try {
  //       console.log("Fetching fallback location from API...");
  //       const res = await axios.get("https://ipinfo.io/json?token=676619cf838df3");

  //       // Parse latitude and longitude from the "loc" field
  //       const [latitude, longitude] = res.data.loc.split(",").map(Number);
  //       const location = { lat: latitude, lng: longitude };

  //       setDefaultLocation(location);
  //       localStorage.setItem("fallbackLocation", JSON.stringify(location)); // Cache location
  //       return;
  //     } catch (error) {
  //       if (i < retries - 1) {
  //         console.warn(`Retrying fallback location in ${delay}ms...`);
  //         await new Promise((resolve) => setTimeout(resolve, delay));
  //         delay *= 2; // Exponential backoff
  //       } else {
  //         console.error("Failed to fetch fallback location:", error);
  //         setDefaultLocation(DefaultLocation); // Set default on final failure
  //       }
  //     }
  //   }
  // };
  
  const getFallbackLocation = async (retries = 3, delay = 1000) => {
    const cachedLocation = localStorage.getItem("fallbackLocation");
    if (cachedLocation) {
      console.log("Using cached fallback location");
      const location = JSON.parse(cachedLocation);
      console.log("locationLocation",location)
      setDefaultLocation(location);
      setCurrentLatitude(location.lat);
      setCurrentLongitude(location.lng);
      return;
    }
  
    for (let i = 0; i < retries; i++) {
      try {
        console.log("Fetching fallback location from API...");
        const res = await axios.get("https://ipinfo.io/json?token=676619cf838df3");
  
        // Parse latitude and longitude from the "loc" field
        const [latitude, longitude] = res.data.loc.split(",").map(Number);
        const location = { lat: latitude, lng: longitude };
        console.log("ConsoleLocation",location)
        setDefaultLocation(location);
        setCurrentLatitude(latitude); // Update currentLatitude
        setCurrentLongitude(longitude); // Update currentLongitude
   
  
        localStorage.setItem("fallbackLocation", JSON.stringify(location)); // Cache location
        return;
      } catch (error) {
        if (i < retries - 1) {
          console.warn(`Retrying fallback location in ${delay}ms...`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          delay *= 2; // Exponential backoff
        } else {
          console.error("Failed to fetch fallback location:", error);
          setDefaultLocation(DefaultLocation);
          setCurrentLatitude(DefaultLocation.lat); // Ensure fallback is not 0,0
          setCurrentLongitude(DefaultLocation.lng);
        }
      }
    }
  };
  // Check location permission
  const checkLocationPermission = () => {
    if (navigator?.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        console.log("clg",res.state)
        setPermissionState(res.state);
        if (res.state === "granted") {
          getUserLocation();
        } else if (res.state === "denied" || res.state === "prompt") {
          getFallbackLocation();
        }

        res.onchange = () => {
          setPermissionState(res.state);
          if (res.state === "granted") {
            getUserLocation();
          } else if (res.state === "denied") {
            getFallbackLocation();
          }
        };
      });
    } else {
      // Fallback for unsupported browsers
      console.warn("Permissions API not supported.");
      getUserLocation();
    }
  };

  // Get user location
  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLatitude(position.coords.latitude);
        setCurrentLongitude(position.coords.longitude);
      },
      async (error) => {
        console.error("Error getting user location:", error);
        getFallbackLocation();
      }
    );
  };

  // Run on component mount
  useEffect(() => {
    checkLocationPermission();
  }, []);

  return (
    <>
      <div
        className={cx(
          "flex h-[115px] min-h-[115px] border-b-[1px] border-[#ddd]",
          activeLocation === LocationId && "bg-[#f9f7ed]",
          inAll && "last-of-type:border-b-0"
        )}
      >
        <div className="flex items-start pt-[24px] ms-[32px] me-[24px]">
          <img src="/assets/img/geoLocations/location.svg" alt="location" />
        </div>
        <div className={`flex flex-col gap-[12px] pt-[18px] w-[77%]`}>
          <h2
            className={`${
              lang === "en" ? "text-[18px]" : "text-[20px]"
            } text-[#361d13] font-bold`}
          >
            {title}
          </h2>
          <p
            className={`${
              lang === "en" ? "text-[13px]" : "text-[14px]"
            } text-[#888888]`}
          >
            {des}
          </p>
        </div>
        <div
          className="w-[48px] flex justify-center items-center border-[#ddd] cursor-pointer border-s-[1px]"
          onClick={openLocationHandler}
        >
          {activeLocation === LocationId ? (
            <img src="/assets/img/geoLocations/arrowOpen.svg" alt="arrow" />
          ) : (
            <img src="/assets/img/geoLocations/arrow.svg" alt="arrow" />
          )}
        </div>
      </div>
      {activeLocation === LocationId && (
        <div className="flex h-[51px] min-h-[51px] border-b-[1px] border-[#ddd] bg-[#f9f7ed] justify-between items-center pl-[32px] pr-[32px]">
          <div className="flex gap-[32px]">
            <div className="flex items-center gap-[8px]">
              <img src="/assets/img/geoLocations/phone.svg" alt="phone" />
              <a
                className="text-[16px] text-[#5d5d5d] font-medium"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            </div>
            <div className="flex items-center gap-[8px]">
              <img src="/assets/img/geoLocations/email.svg" alt="email" />
              <a
                className="text-[16px] text-[#5d5d5d] font-medium"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </div>
          </div>
          <div className="flex cursor-pointer">
            <a
              className="flex items-center gap-[8px]"
              href={`https://www.google.com/maps/dir/${currentLatitude},${currentLongitude}/${lat},${lng}/@${lat}},${lng},4z/?hl=en`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/img/geoLocations/directions.svg"
                alt="directions"
              />
              <p className="text-[16px] text-[#b68a35] font-bold">
                {lang === "en"
                  ? enPhrase["lblDirections"]
                  : arPhrase["lblDirections"]}
              </p>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
