const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_AR = process.env.REACT_APP_BASE_URL_AR;

const access_token = localStorage.getItem("access_token");

// const username = process.env.API_USERNAME;
// const password = process.env.API_PASSWORD;

// // Combine the username and password and encode it to Base64
// const credentials = `${username}:${password}`;
// const basicAuth = `Basic ${btoa(credentials)}`;

// console.log("basicAuth", basicAuth);

export async function getPhrases() {
  try {
    const response = await fetch(`${BASE_URL_AR}common/phrases`);
    return await response.json();
  } catch (error) {
    return [];
  }
}
export async function getInfo() {
  try {
    const response = await fetch(`${BASE_URL}common/info`);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getDefaultLocationMap() {
  try {
    const response = await fetch(`https://geolocation-db.com/json/`);
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getHomeLanding(lang) {
  try {
    const response = await fetch(`${BASE_URL_AR}Home/HomeLanding?lang=${lang}`);
    return await response.json();
  } catch (error) {
    return [];
  }
}

// export async function getTermsAndConditions(lang) {
//   try {
//     const response = await fetch(`${BASE_URL_AR}Home/TermsAndConditions?lang=${lang}`);
//     return await response.json();
//   } catch (error) {
//     return [];
//   }
// }

export async function getTermsAndConditions(lang) {
  try {
    console.log("Fetching Terms and Conditions with lang:", lang);
    const response = await fetch(
      `${BASE_URL_AR}Home/TermsAndConditions?lang=${lang}`
    );

    // Log the raw response object
    console.log("Raw Response:", response);

    if (!response.ok) {
      console.error(`API Error: ${response.status} - ${response.statusText}`);
      return [];
    }

    const data = await response.text(); // Change to .text() if the response is HTML
    console.log("Parsed Response Data:", data);

    if (!data || Object.keys(data).length === 0) {
      console.error("Empty response body received");
      return [];
    }

    return data;
  } catch (error) {
    console.error("Error in API call:", error);
    return [];
  }
}

export async function getPrivacyPolicy(lang) {
  try {
    console.log("Fetching Terms and Conditions with lang:", lang);
    const response = await fetch(
      `${BASE_URL_AR}Home/PrivacyPolicy?lang=${lang}`
    );

    // Log the raw response object
    console.log("Raw Response PrivacyPolicy:", response);

    if (!response.ok) {
      console.error(`API Error: ${response.status} - ${response.statusText}`);
      return [];
    }

    const data = await response.text(); // Change to .text() if the response is HTML
    console.log("PrivacyPolicy Parsed Response Data:", data);

    if (!data || Object.keys(data).length === 0) {
      console.error("Empty response body received");
      return [];
    }

    return data;
  } catch (error) {
    console.error("Error in API call:", error);
    return [];
  }
}

export async function getRefundPolicy(lang) {
  try {
    console.log("Fetching Terms and Conditions with lang:", lang);
    const response = await fetch(
      `${BASE_URL_AR}Home/RefundPolicy?lang=${lang}`
    );

    // Log the raw response object
    console.log("Raw Response PrivacyPolicy:", response);

    if (!response.ok) {
      console.error(`API Error: ${response.status} - ${response.statusText}`);
      return [];
    }

    const data = await response.text(); // Change to .text() if the response is HTML
    console.log("PrivacyPolicy Parsed Response Data:", data);

    if (!data || Object.keys(data).length === 0) {
      console.error("Empty response body received");
      return [];
    }

    return data;
  } catch (error) {
    console.error("Error in API call:", error);
    return [];
  }
}

export async function UploadReceiptImage(file) {
  var formdata = new FormData();
  formdata.append("recepit", file, file.name);
  const response = await fetch(`${BASE_URL}common/UploadReceiptImage`, {
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body: formdata,
  });
  return await response.json();
}
export async function UploadReceiptBase64(data) {
  console.log(JSON.stringify(`"${data}"`));
  const response = await fetch(`${BASE_URL}common/UploadReceiptBase64`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(`${data}`),
  });
  return await response;
}
// export async function UploadReceipt(data) {
//   const response = await fetch(`${BASE_URL_AR}Cases/UploadReceipt`, {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(data),
//   });
//   return await response.json();
// }
export async function CreateTicket(data) {
  const formData = new FormData();
  // formData.append("ticketCategoryId", data?.ticketCategoryId);
  formData.append("name", data?.name);
  formData.append("email", data?.email);
  formData.append("message", data?.message);
  formData.append("file", data?.file);
  formData.append("code", data?.Code);
  formData.append("mobile", data?.Mobile);

  const response = await fetch(`${BASE_URL_AR}Home/CreateTicket`, {
    method: "POST",
    headers: { Accept: "multipart/form-data" },
    // body: JSON.stringify(data),
    body: formData,
  });
  return await response.json();
}

export async function getServiceDetails(caseid, serviceid, lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Cases/ServiceDetails?caseid=${caseid}&serviceid=${serviceid}&lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}



export async function getCaseDetails(caseid, lang) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    const response = await fetch(
      `${BASE_URL_AR}Cases/CasesDetails?caseid=${caseid}&lang=${lang}`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function UploadReceipt(data) {
  const formData = new FormData();
  formData.append("ServiceId", data?.ServiceId);
  formData.append("CaseNumber", data?.CaseNumber);
  formData.append("Receipt", data?.Receipt);

  const response = await fetch(`${BASE_URL_AR}Cases/UploadReceipt`, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
  return await response.json();
}

export async function addService(data, lang) {
  const formData = new FormData();
  formData.append("RequesterId", data?.RequesterId);
  formData.append("ServiceCategoryId", data?.ServiceCategoryId);
  formData.append("SubServiceCategoryId", data?.SubServiceCategoryId);
  formData.append("ClassficationId", data?.ClassficationId);
  formData.append("KeyService", data?.KeyService);
  formData.append("OtherServiceNote", data?.OtherServiceNote);
  formData.append("CaseNumber", data?.CaseNumber);
  formData.append("CaseYear", data?.CaseYear);
  formData.append("CaseTypeId", data?.CaseTypeId);
  formData.append("CourtId", data?.CourtId);
  formData.append("InquiryId", data?.InquiryId);
  formData.append("RequesterTypeId", data?.RequesterTypeId);
  formData.append("CourtDocument", data?.CourtDocument);
  // formData.append("OtherPartyType", data?.OtherPartyType);

  const response = await fetch(`${BASE_URL_AR}Cases/AddService?lang=${lang}`, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
  return await response.json();
}

// export async function getServiceClassfications(lang,CategoryId,SubCategoryId) {
//   // const formData = new FormData();
//   // formData.append("RequesterId", data?.RequesterId);
//   // formData.append("ServiceCategoryId", data?.ServiceCategoryId);

//   const response = await fetch(
//     `${BASE_URL_AR}Lookup/GetServiceClassfications?Lang=${lang}&CategoryId=${CategoryId}&SubCategoryId=${SubCategoryId}`,
//     {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${access_token}`,
//       },
//     }
//   );
//   return await response.json();
// }

export async function getServiceClassfications(
  lang,
  CategoryId,
  SubCategoryId
) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetServiceClassfications?Lang=${lang}&CategoryId=${CategoryId}&SubCategoryId=${SubCategoryId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getAllDetails(lang) {
  try {
    const response = await fetch(`${BASE_URL_AR}Cases/Services?Lang=${lang}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getArchives(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Cases/ArchiveCases?Lang=${lang}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getCourts(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetCourts?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getPartyTypes(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetPartyTypes?Lang=${lang}`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getGetInquiryOptions(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetInquiryOptions?Lang=${lang}`
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getServiceStatus(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetServiceStatus?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getCasesStatus(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetCasesStatus?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

// export async function getRequester(lang) {
//   try {
//     const response = await fetch(
//       `${BASE_URL_AR}Lookup/GetRequester?Lang=${lang}`,
//       {
//         headers: {
//           Authorization: `Bearer ${access_token}`,
//         },
//       }
//     );
//     return await response.json();
//   } catch (error) {
//     console.log("RequesterError", JSON.stringify(error, null, 2));
//     return [];
//   }
// }

export async function getRequester(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetRequester?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    if (response.status === 401 || response.status === 500) {
      // If status is 401, delete the token and redirect to login
      window.localStorage.removeItem("access_token");
      window.location.href = "/login"; // Redirect to login
      return []; // Return empty array as fallback
    }

    if (!response.ok) {
      // Handle other response errors
      throw new Error("Failed to fetch requester data");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in getRequester:", error);
    return []; // Return empty array in case of error
  }
}

export async function getCaseTypes(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetCaseTypes?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getServiceCategories(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetServiceCategories?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getSubServiceCategories(lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Lookup/GetSubServiceCategories?Lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getSurvey(lang, categoryId) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Survey?Lang=${lang}&categoryId=${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function AddSurvey(surveySchema) {
  const response = await fetch(`${BASE_URL_AR}Survey/Add`, {
    method: "POST",
    // headers: {
    //   headers: { "Content-Type": "application/json" },
    //   // headers: { "Content-Type": "multipart/form-data" },
    //   Authorization: `Bearer ${access_token}`,
    // },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(surveySchema),
  });
  return await response.json();
}

export async function getSurveyServices(lang) {
  try {
    const response = await fetch(`${BASE_URL_AR}Survey/Services?Lang=${lang}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getSurveyNotification(lang) {
  try {
    const response = await fetch(`${BASE_URL_AR}Survey/UserNotification`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return await response.json();
  } catch (error) {
    return [];
  }
}

export const getUaePassUserInfo = async (code, locale) => {
  const response = await fetch(
    `${BASE_URL_AR}Authenticate/UaePass?code=${code}&lang=${locale}&source=web`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    },
    {
      params: {
        code: code,
        Lang: locale,
        source: "web",
      },
    }
  );
  const jsonResponse = await response.json();
  console.log("jsonResponse", jsonResponse);
  return jsonResponse;
};

export const getAccessToken = async (data, locale) => {
  const response = await fetch(`${BASE_URL_AR}Authenticate/GetAccessToken`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(`${data}`),
  });
  return response.data;
};

export async function getComments(missionId, lang) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Cases/Comments?ServiceId=${missionId}&lang=${lang}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function addComment(data) {
  const formData = new FormData();
  formData.append("ServiceId", data?.ServiceId);
  formData.append("UserId", data?.UserId);
  formData.append("UserType", data?.UserType);
  formData.append("Comment", data?.Comment);

  if (Array.isArray(data?.Files)) {
    data.Files.forEach((file) => {
      formData.append("Files", file); // Append each file separately
    });
  }

  const response = await fetch(`${BASE_URL_AR}Cases/AddComment`, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
  return await response.json();
}

export async function makePayment(caseId, serviceId) {
  try {
    const response = await fetch(
      `${BASE_URL_AR}Cases/Payment?serviceId=${serviceId}&caseId=${caseId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    console.log("response", response);

    return await response.json();
  } catch (error) {
    return [];
  }
}

// export async function UpdatePaymentStatus(serviceId, IsPaid, ppId) {
//   try {
//     const response = await fetch(
//       `${BASE_URL_AR}Cases/UpdatePaymentStatus?serviceId=${serviceId}&IsPaid=${IsPaid}&ppId=${ppId}`,
//       {
//         // headers: {
//         //   Authorization: `Bearer ${access_token}`,
//         // },

//         headers: {
//           Authorization: basicAuth,
//         },
//       }
//     );

//     console.log("response", response);

//     return await response.json();
//   } catch (error) {
//     return [];
//   }
// }
