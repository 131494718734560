import React, { useEffect, useState } from "react";
import { Container } from "../components/utils";

const Footer = ({ lang, enPhrase, arPhrase, dashboard }) => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const interval = setInterval(() => {
      setYear(new Date().getFullYear());
    }, 1000 * 60 * 60 * 24); // Updates every 24 hours
    return () => clearInterval(interval);
  }, []);


  return (
    <section
      id="footer"
      className={`${dashboard ? `` : ""}w-full overflow-hidden wow fadeInUp ${
        dashboard ? "" : "pt-[12rem]"
      } lg:pt-[5rem] bg-[#f9f7ed]`}
    >
      <Container>
        <div className="w-full flex justify-center mb-[24px] ">
          {window && window.innerWidth <= 1024 ? (
            <img
              src="assets/img/home/EJE Logo-mobile.svg"
              alt=""
              className="m-auto pt-5 "
            />
          ) : (
            <section className="mt-[48px]">
              <img
                // className="pt-5 max-w-30 "
                className="w-[294px] h-[80px]"
                src="/assets/img/eje-footer/EJELogo.svg"
                alt="footerlogo"
              />
            </section>
          )}
        </div>
        {window && window.innerWidth <= 1024 ? (
          <div className="flex flex-col">
            <div className="flex m-auto items-center justify-center !mb-[16px]">
              <div className="flex flex-row gap-3 items-center ">
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.698 3.774a.941.941 0 0 0-1.107.736c-.1.502.236.992.747 1.09 1.538.295 2.725 1.461 3.026 2.973v.001a.943.943 0 0 0 1.11.736c.51-.1.847-.59.747-1.093-.45-2.259-2.223-4.003-4.523-4.443z"
                    fill="#B68A35"
                  />
                  <path
                    d="M13.629.009a.915.915 0 0 0-.69.19.917.917 0 0 0-.356.632.934.934 0 0 0 .835 1.026c3.576.39 6.355 3.12 6.757 6.635a.939.939 0 0 0 1.046.82.94.94 0 0 0 .634-.343.912.912 0 0 0 .2-.684C21.554 3.899 18.09.495 13.629.009z"
                    fill="#B68A35"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.964 11.857c4.4 4.311 5.399-.676 8.2 2.068 2.702 2.646 4.254 3.176.832 6.528-.429.338-3.153 4.399-12.725-4.977-9.573-9.378-5.43-12.05-5.086-12.47 3.43-3.361 3.962-1.831 6.663.815 2.802 2.744-2.285 3.726 2.116 8.036z"
                    fill="#B68A35"
                  />
                </svg>

                <a
                  className="text-black font-bold font-Almarai"
                  dir="ltr"
                  href={"tel:" + "600 55 33 55"}
                >
                  {" "}
                  600 55 33 55
                </a>
              </div>
              <div className=" flex-row gap-3 mx-[16px] items-center ">|</div>
              <div className="flex flex-row gap-3 items-center">
                <svg
                  width="23"
                  height="21"
                  viewBox="0 0 23 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.036 14.97c0 3.107-2.468 5.625-5.542 5.636H5.564C2.501 20.606 0 18.099 0 14.99v-.01S.007 10.05.015 7.57a.549.549 0 0 1 .89-.437c2.619 2.1 7.3 5.929 7.359 5.98a4.451 4.451 0 0 0 2.787.99A4.392 4.392 0 0 0 13.84 13.1c.058-.04 4.635-3.753 7.292-5.888a.55.55 0 0 1 .894.435c.011 2.46.011 7.322.011 7.322z"
                    fill="#B68A35"
                  />
                  <path
                    d="M21.458 3.53A5.54 5.54 0 0 0 16.56.55H5.563a5.54 5.54 0 0 0-4.9 2.98.97.97 0 0 0 .244 1.2l8.181 6.616c.573.468 1.267.701 1.961.701h.023c.694 0 1.388-.233 1.96-.7l8.182-6.617a.969.969 0 0 0 .243-1.2z"
                    fill="#B68A35"
                  />
                </svg>

                <a
                  className="text-black font-bold font-Almarai"
                  href={`mailto:` + "info@eje.ae"}
                >
                  {" "}
                  info@eje.ae{" "}
                </a>
              </div>
            </div>
            {/* <div className="flex flex-row gap-3 items-center justify-center mt-[23px] mb-[16px]">
              <svg
                width="19"
                height="26"
                viewBox="0 0 19 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.602 1.218a8.996 8.996 0 0 1 9.168.075c2.792 1.732 4.49 4.823 4.474 8.147-.065 3.303-1.885 6.408-4.16 8.807a24.387 24.387 0 0 1-4.377 3.667 1.532 1.532 0 0 1-.53.187 1.07 1.07 0 0 1-.51-.154 24.105 24.105 0 0 1-6.305-5.912A12.045 12.045 0 0 1 0 9.275l.006-.356c.119-3.193 1.85-6.11 4.596-7.701zm5.702 5.327a3.062 3.062 0 0 0-3.38.676 3.186 3.186 0 0 0-.676 3.433A3.098 3.098 0 0 0 9.11 12.6a3.052 3.052 0 0 0 2.194-.912c.582-.59.907-1.39.905-2.226a3.15 3.15 0 0 0-1.906-2.917zM9.121 26c3.599 0 6.516-.582 6.516-1.3 0-.718-2.917-1.3-6.516-1.3-3.598 0-6.516.582-6.516 1.3 0 .718 2.918 1.3 6.516 1.3z"
                  fill="#B68A35"
                />
              </svg>
              <div className="text-black font-bold font-Almarai">
                {lang === "en"
                  ? enPhrase["lbl_Footer_address"]
                  : arPhrase["lbl_Footer_address"]}
              </div>
            </div> */}
          </div>
        ) : (
          <div className="flex flex-col xl:mb-[25px] lg:flex-row items-center justify-center gap-[40px]">
            <div className="flex flex-row gap-[12px] items-center">
              <img
                src="/assets/img/eje-footer/Call.svg"
                alt="phone-icon"
                className="w-[32px] h-[32px]"
              />
              <a
                className="text-black font-bold font-Almarai"
                dir="ltr"
                href={"tel:" + "600 55 33 55"}
              >
                {" "}
                600 55 33 55
              </a>
            </div>
            <div className=" hidden flex-row gap-3 items-center  lg:flex">
              |
            </div>
            <div className="flex flex-row gap-[12px] items-center">
              <img
                src="/assets/img/eje-footer/info.svg"
                alt="email-icon"
                className="w-[32px] h-[32px]"
              />
              <a
                className="text-black font-bold font-Almarai"
                href={`mailto:` + "info@eje.ae"}
              >
                {" "}
                info@eje.ae{" "}
              </a>
            </div>
            {/* <div className=" hidden flex-row gap-3 items-center  lg:flex">
              |
            </div> */}
            {/* <div className="flex flex-row gap-[12px] items-center">
              <img
                src="/assets/img/eje-footer/Location.webp"
                alt="email-icon"
                className="w-[32px] h-[32px]"
              />
              <div className="text-black font-bold font-Almarai">
                {lang === "en"
                  ? enPhrase["lbl_Footer_address"]
                  : arPhrase["lbl_Footer_address"]}
              </div>
            </div> */}
          </div>
        )}
      </Container>
      <div className="w-full overflow-hidden  wow fadeInUp py-[1.5rem] border-t-2 bg-[#fff]">
        {window && window.innerWidth <= 1024 ? (
          <div className="flex flex-col items-center justify-center  max-w-[247px] gap-[16px] m-auto">
            <div className="flex items-center justify-center">
              <a
                href="/Privacy"
                className="font-bold font-Almarai text-[14px] w-[141px] text-center lg:text-base "
              >
                {lang === "en"
                  ? enPhrase["lbl_privacy_policy"]
                  : arPhrase["lbl_privacy_policy"]}
              </a>
              <div className=" flex-row gap-[8px] mx-[16px] items-center ">
                |
              </div>
              <a
                href="/terms"
                className="font-bold font-Almarai text-[14px] w-[141px] text-center lg:text-base"
              >
                {lang === "en"
                  ? enPhrase["lbl_terms_condtions"]
                  : arPhrase["lbl_terms_condtions"]}
              </a>
            </div>

            <div>
              <a
                href="/Refund"
                className="font-bold font-Almarai text-[14px] lg:text-base"
              >
                {lang === "en"
                  ? enPhrase["lbl_Refund_Policy"]
                  : arPhrase["lbl_Refund_Policy"]}
              </a>
            </div>
            <p className="text-[14px] font-Almarai font-bold text-center text-[#020202]">
              {/* {lang === "en"
                ? enPhrase["lbl_copyrights"]
                : arPhrase["lbl_copyrights"]} */}
                {lang === "en"
                ? `Copyright © EJE ${year}. All rights reserved`
                : `جميع الحقوق محفوظة © الإمارات لتنفيذ الأحكام ${year}`}
            </p>
            <div className="border-[1px] border-solid border-gray w-full mt-[0px] mb-[80px]"></div>
          </div>
        ) : (
          <Container
            className={`flex flex-col items-center lg:flex-row justify-evenly h-[28px]`}
          >
            <div className="font-bold font-Almarai text-[14px] text-center lg:text-start lg:font-[16px] mb-[16px] lg:mb-[0px]">
              {/* {lang === "en"
                ? enPhrase["lbl_copyrights"]
                : arPhrase["lbl_copyrights"]} */}
              {lang === "en"
                ? `Copyright © EJE ${year}. All rights reserved`
                : `جميع الحقوق محفوظة © الإمارات لتنفيذ الأحكام ${year}`}
            </div>
            <div className="flex gap-3 items-center justify-center">
              <div className="flex flex-row gap-3 items-center">
                <div className="text-black font-medium text-center">
                  <a
                    href="/Privacy"
                    className="font-bold font-Almarai text-[14px] lg:text-base "
                  >
                    {lang === "en"
                      ? enPhrase["lbl_privacy_policy"]
                      : arPhrase["lbl_privacy_policy"]}
                  </a>
                </div>
              </div>
              <div className=" flex-row gap-3 items-center ">|</div>
              <div className="flex flex-row gap-3 items-center">
                <div className="text-black font-medium text-center">
                  <a
                    href="/terms"
                    className="font-bold font-Almarai text-[14px] lg:text-base"
                  >
                    {lang === "en"
                      ? enPhrase["lbl_terms_condtions"]
                      : arPhrase["lbl_terms_condtions"]}
                  </a>
                </div>
              </div>
              <div className=" flex-row gap-3 items-center ">|</div>
              <div className="flex flex-row gap-3 items-center">
                <div className="text-black font-medium text-center">
                  <a
                    href="/Refund"
                    className="font-bold font-Almarai text-[14px] lg:text-base"
                  >
                    {lang === "en"
                      ? enPhrase["lbl_Refund_Policy"]
                      : arPhrase["lbl_Refund_Policy"]}
                  </a>
                </div>
              </div>
            </div>
          </Container>
        )}
      </div>
    </section>
  );
};
export default Footer;
