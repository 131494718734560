import React from "react";

import ServiceStatus from "../../serviceStatus";
const ServiceDetailsPart1 = ({ service, lang, arPhrase, enPhrase }) => {
  const {
    id,
    serviceCategory,
    status,
    serviceStatusId,
    caseNumber,
    caseTypeName,
  } = service;

  
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center">
        <div className="p-[16px] md:px-4 md:py-6 w-full bg-white flex flex-col md:flex-row flex-wrap gap-[16px] md:gap-x-60 content-between justify-between border-2 border-solid border-[#EEE2D0] rounded shadow-[0px_10px_40px_#361E1214] md:bg-none bg-LogoEJECommonCard bg-no-repeat bg-left">
          <div className="flex justify-between items-center w-full md:w-[30%]">
            <div className="flex flex-row md:flex-col content-between justify-start  gap-1 min-w-[30%]">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblApplicationNumber"]
                  : arPhrase["lblApplicationNumber"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">{id}</p>
            </div>

            <div
              className={`flex md:hidden  border-[1px] ${
                service?.serviceStatusId === 1
                  ? "border-[#aae2ff]"
                  : service?.serviceStatusId === 2
                  ? "border-[#ff6064]"
                  : service?.serviceStatusId === 3
                  ? "border-[#c3bcb2]"
                  : service?.serviceStatusId === 4
                  ? "border-[#ffc670]"
                  : service?.serviceStatusId === 5
                  ? "border-[#67b8a1]"
                  : service?.serviceStatusId === 6
                  ? "border-[#edeae7]"
                  : service?.serviceStatusId === 7
                  ? "border-[#e61a05]"
                  : ""
              } border-solid rounded-[8px] px-[10px] py-[6px]  flex items-center justify-start gap-[8px] !w-fit h-[28px]`}
            >
              {/* <img src="/assets/img/case-status/red.svg" alt="" className="" /> */}
              <ServiceStatus serviceStatusId={service?.serviceStatusId} />
              <p className="text-[12px] font-Almarai leading-[1.33] h-[16px] text-[#5a5a5a]">
                {service?.status}
              </p>
            </div>
          </div>

          <div className="flex flex-row md:flex-col content-between justify-start  gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
            {lang === "en"
                ? "Application type"
                : "نوع الطلب"}
            </p>
            <p className="text-sm font-bold text-[#010202]">
              {serviceCategory}
            </p>
          </div>
          <div className="hidden md:flex flex-row md:flex-col content-between  justify-start gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en" ? enPhrase["lblStatus"] : arPhrase["lblStatus"]}
            </p>
            <p className="flex gap-2 items-center text-sm font-bold text-[#010202]">
              <ServiceStatus serviceStatusId={serviceStatusId} />
              <span>{status}</span>
            </p>
          </div>
          <div className="flex flex-row md:flex-col content-between justify-start  gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblCaseNumber"]
                : arPhrase["lblCaseNumber"]}
            </p>
            <p className="text-sm font-bold text-[#010202] w-[200px] truncate">
              {caseNumber}
            </p>
          </div>
          <div className="flex flex-row md:flex-col content-between justify-start  gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblCaseType"]
                : arPhrase["lblCaseType"]}
            </p>
            <p className="text-sm font-bold text-[#010202]">{caseTypeName}</p>
          </div>
          {/* <div className="flex flex-col md:flex-row content-between justify-between gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblClaimAmount"]
                : arPhrase["lblClaimAmount"]}
              :
            </p>
            <p className="text-[16px] md:text-sm font-bold text-[#a98031] md:text-[#010202]">
              <span>{USDollar.format(claimedValue)} </span>
              <span>{lang === "en" ? "AED" : "درهم"}</span>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsPart1;
