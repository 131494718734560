import React, { useEffect, useRef, useState } from "react";
import FeedBackList from "./FeedBackList";
import {
  getSurveyNotification,
  getSurveyServices,
} from "../../services/Common";
import FeedBackListMobile from "./FeedBackListMobile";
import useISMobile from "../../utils/useISMobile";

const FeedBack = ({
  enPhrase,
  arPhrase,
  setFeedBackIsOpen,
  feedBackIsOpen,
  specificSurvey,
  setSpecificSurvey,
  sidebarHeight,
}) => {
  const [lang, setLang] = useState("");
  const [apiData, setApiData] = useState({
    loading: false,
    data: [],
    error: false,
  });
  const [survayServices, setSurvayServices] = useState([]);
  const [hasNotification, setHasNotification] = useState();
  console.log("feedBackIsOpen", feedBackIsOpen);
  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getSurServices(currentLang);
  }, []);

  const getSurServices = (currentLang) => {
    console.log("LANGLANG",currentLang)
    setApiData({ loading: true, data: [] });
    return getSurveyServices(currentLang).then((res) => {
      setApiData({ loading: false, data: res });
      setSurvayServices(res);
    });

  };

  console.log("apiData", apiData);
  console.log("apiData2", apiData?.data?.services?.length);

  const isMobile = useISMobile();
  const FeedBackListHandler = () => {
    const lang = window.localStorage.getItem("lang");
    if (isMobile) {
      setFeedBackIsOpen(!feedBackIsOpen);
      getSurServices(lang);
      document.body.style.overflow = "hidden";

    } else {
      setFeedBackIsOpen(!feedBackIsOpen);
      getSurServices(lang);
    }
  };


  const getSurveyNotify = () => {
    getSurveyNotification().then((res) => {
      setHasNotification(res?.hasNotifications);
    });
  };

  useEffect(() => {
    // Fetch data immediately when component mounts
    getSurveyNotify();

    // Set up interval to fetch data every 1 minute
    const interval = setInterval(getSurveyNotify, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Check if the flag is set in localStorage
    if (localStorage.getItem("refreshAndRunFunction") === "true") {
      // Remove the flag from localStorage
      localStorage.removeItem("refreshAndRunFunction");
      // Run the function after the page refresh
      FeedBackListHandler();
    }
  });

  return (
    <>
      {!apiData?.loading && apiData?.data?.services?.length > 0 ? (
        <div>
          <div
            className={`${
              survayServices?.services?.length === 0 ? "hidden" : "fixed"
            }  bottom-[43px] z-[49] ${
              lang === "en"
                ? "right-[24px] xl:right-[43px]"
                : "left-[24px] xl:left-[43px]"
            } `}
          >
            <div
              className="cursor-pointer w-[80px] h-[80px] bg-[#fff] rounded-full flex justify-center items-center relative border  !border-[#a9803133] py-[16px] shadow-[0_12px_32px_rgba(182,138,53,0.2)]"
              onClick={FeedBackListHandler}
            >
              <div className="flex flex-col gap-[2px] justify-center items-center h-[90%]">
                <p
                  className={`${
                    lang === "en" ? "w-[44px]" : "w-[36px]"
                  } font-Almarai font-bold text-[12px] text-center leading-[16px]`}
                >
                  {lang === "en"
                    ? enPhrase["lblShareSurvey"]
                    : arPhrase["lblShareSurvey"]}
                </p>
                <img
                  alt=""
                  src="/assets/img/case-overview/FeedBack.svg"
                  className="w-[41px] h-[13.75px] "
                />
              </div>
              {hasNotification && (
                <img
                  alt=""
                  src="/assets/img/case-overview/Notify.svg"
                  className={`w-[20x] h-[18px] absolute top-0 ${
                    lang === "en" ? "right-1" : "left-1"
                  }`}
                />
              )}
            </div>
          </div>
          {feedBackIsOpen && (
            <div
              className={`fixed bottom-[35px] z-[999] ${
                lang === "en" ? "right-0" : "left-[45px]"
              } `}
            >
              {window && window.innerWidth <= 1024 ? (
                <FeedBackListMobile
                  sidebarHeight={sidebarHeight}
                  feedBackIsOpen={feedBackIsOpen}
                  setFeedBackIsOpen={setFeedBackIsOpen}
                  survayServices={survayServices}
                  lang={lang}
                  arPhrase={arPhrase}
                  enPhrase={enPhrase}
                  FeedBackListHandler={FeedBackListHandler}
                  specificSurvey={specificSurvey}
                  setSpecificSurvey={setSpecificSurvey}
                />
              ) : (
                <FeedBackList
                  sidebarHeight={sidebarHeight}
                  feedBackIsOpen={feedBackIsOpen}
                  setFeedBackIsOpen={setFeedBackIsOpen}
                  survayServices={survayServices}
                  lang={lang}
                  arPhrase={arPhrase}
                  enPhrase={enPhrase}
                  FeedBackListHandler={FeedBackListHandler}
                  specificSurvey={specificSurvey}
                  setSpecificSurvey={setSpecificSurvey}
                />
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default FeedBack;
