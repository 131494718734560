import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-phone-number-input/style.css";
import { Link, useNavigate } from "react-router-dom";

import Breadcrumb from "../components/breadCrumb";
import LargeTitle from "../components/case-tracking/LargeTitle";
import SharedModal from "../components/happiness/SharedModal";
import { Loader, toast } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import { RequesterContext } from "../context/requesterContext";
import ThemeLayout from "../layouts/ThemeLayout";
import {
  addService,
  getCaseTypes,
  getCourts,
  getPartyTypes,
  // getRequester,
  getGetInquiryOptions,
  getServiceCategories,
  getServiceClassfications,
  getSubServiceCategories,
} from "../services/Common";
import Select from "react-select";

const Request = () => {
  const [lang, setLang] = useState("");
  const [requestId, setRequestId] = useState(localStorage.getItem("requestId"));

  const maxOffset = 60;
  const inputFile = useRef(null);
  const [courts, setCourts] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [InquiryOptions, setInquiryOptions] = useState([]);
  const [serviceCaregories, setServiceCaregories] = useState([]);
  const [subServiceCaregories, setSubServiceCaregories] = useState([]);
  const [partyTypes, setPartyTypes] = useState([]);
  const [years, setYears] = useState([]);
  const [file, setFile] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestSuccessMessage, setRequestSuccessMessage] = useState(false);
  const [requestIdAdded, setRequestIdAdded] = useState("");
  const [states, setStates] = useState([]);
  const [catigories, setCatigories] = useState([]);
  const [classification, setClassification] = useState("");
  const [physicalService, setPhysicalService] = useState([]);
  const [phisicalStatus, setPhisicalStatus] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [otherServiceDetails, setOtherServiceDetails] = useState("");
  const [requestInfo, setRequestInfo] = useState({});
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [requesterInfo] = useContext(RequesterContext);
  const [courtEmail, setCourtEmail] = useState("");
  const [courtMobileNumber, setCourtMobileNumber] = useState("");
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [admission, setAdmission] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);

  const [partyType, setPartyType] = useState("");

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    resetField,
    control,
    watch,
  } = useForm({
    defaultValues: {
      InquiryOption: 0, // Set a default value here
    },
  });

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    const year = new Date().getFullYear();
    setYears(Array.from(new Array(maxOffset), (val, index) => year - index));
    getCourtsService(currentLang);
    getCaseTypesService(currentLang);
    getServiceCategoriesService(currentLang);
    getSubServiceCategoriesService(currentLang);
    getPartyTypesService(currentLang);
    getGetInquiryOption(currentLang);
  }, []);

  const optionsYear = years.map((year) => ({
    name: year.toString(),
    value: year,
  }));

  const getServiceCategoriesService = (currentLang) => {
    return getServiceCategories(currentLang).then((res) => {
      setServiceCaregories(res);
    });
  };

  const getSubServiceCategoriesService = (currentLang) => {
    return getSubServiceCategories(currentLang).then((res) => {
      setSubServiceCaregories(res);
    });
  };

  const getCaseTypesService = (currentLang) => {
    return getCaseTypes(currentLang).then((res) => {
      setCaseTypes(res);
    });
  };

  const getPartyTypesService = (currentLang) => {
    return getPartyTypes(currentLang).then((res) => {
      setPartyTypes(res);
    });
  };

  const getCourtsService = (currentLang) => {
    return getCourts(currentLang).then((res) => {
      setCourts(res);
    });
  };

  const getGetInquiryOption = (currentLang) => {
    return getGetInquiryOptions(currentLang).then((res) => {
      setInquiryOptions(res);
    });
  };

  // const updateData = (data) => {
  //   addService(data, lang).then(
  //     (res) => {
  //       console.log("RespnseAdd", res.errors);
  //       console.log("RespnseAddError", res?.data?.status);
  //       setLoading(false);
  //       setUploadedFile("");
  //       if (res?.data?.serviceId !== null && !res.data.status) {
  //         setRequestSuccessMessage(true);
  //         setRequestIdAdded(res.data.serviceId);
  //       }
  //       if (res?.data?.serviceId === null) {
  //         setPhisicalStatus(true);
  //         setCourtMobileNumber(res?.data?.courtMobileNumber);
  //         setCourtEmail(res?.data?.courtEmail);
  //         setLoading(false);
  //         setUploadedFile("");
  //       }
  //       if (res?.data?.status === 400) {
  //         setSomethingWentWrong(true);
  //         setUploadedFile("");
  //       }
  //     },
  //     (err) => {
  //       const title =
  //         lang === "en" ? enPhrase["lbl_Error"] : arPhrase["lbl_Error"];
  //       const success =
  //         lang === "en" ? enPhrase["lbl_Error_Msg"] : arPhrase["lbl_Error_Msg"];
  //       const ok = lang === "en" ? enPhrase["lbl_Ok"] : arPhrase["lbl_Ok"];
  //       toast(title, success, ok, "error");
  //     }
  //   );
  // };

  const updateData = (data) => {
    addService(data, lang).then(
      (res) => {
        setLoading(false);
        setUploadedFile("");

        // Check if response body contains status 400
        if (res?.status === 400) {
          setSomethingWentWrong(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
          // setValue("suborderType", null);
          // setValue("classificationType", null);
          // setValue("InquiryOption", null);
          // setValue("orderType", null);
          // setValue("court", null);
          // setValue("caseType", null);
          // setValue("caseYear", null);
          // setValue("caseNumber", null);
          // setValue("customer", null);
          // setValue("fileupload", null);
          // setUploadedFile("");
          setFile("");
          setValue("fileupload", "");
          return;
        }

        // Handle successful response
        if (res?.data?.serviceId !== null && !res.data.status) {
          setRequestSuccessMessage(true);
          setRequestIdAdded(res.data.serviceId);
        }

        if (res?.data?.serviceId === null) {
          setPhisicalStatus(true);
          setCourtMobileNumber(res?.data?.courtMobileNumber);
          setCourtEmail(res?.data?.courtEmail);
          setLoading(false);
          setUploadedFile("");
        }
      },
      (err) => {
        // Catch any request-level errors (e.g., network issues)

        setSomethingWentWrong(true);
        setUploadedFile("");

        const title =
          lang === "en" ? enPhrase["lbl_Error"] : arPhrase["lbl_Error"];
        const success =
          lang === "en" ? enPhrase["lbl_Error_Msg"] : arPhrase["lbl_Error_Msg"];
        const ok = lang === "en" ? enPhrase["lbl_Ok"] : arPhrase["lbl_Ok"];
        toast(title, success, ok, "error");
      }
    );
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const onSubmit = async (data) => {
    setLoading(false);
    console.log("first,", data);
    let recepitInfo = {
      RequesterId: requesterInfo?.id,
      ServiceCategoryId: +data.orderType?.id,
      ClassficationId: +data.suborderType?.id,
      SubServiceCategoryId: +data.classificationType?.id,
      KeyService: isChecked,
      OtherServiceNote: otherServiceDetails,
      // SubServiceCategoryData: +data,
      CaseNumber: data.caseNumber,
      CaseYear: +data.caseYear?.name,
      CaseTypeId: +data.caseType?.id,
      CourtId: +data.court?.id,
      RequesterTypeId: +data.customer?.id,
      CourtDocument: uploadedFile,
      // InquiryId: +data.InquiryOption?.id,
      InquiryId: data.InquiryOption?.id
        ? parseInt(data.InquiryOption?.id, 10)
        : 0,
      // OtherPartyType: otherPartyType,
    };
    console.log("recepitInfo", recepitInfo);
    if (physicalService.length > 0) {
      setRequestInfo(recepitInfo);
      updateData(recepitInfo);
      setLoading(true);
    } else {
      setAdmission(true);
      setRequestInfo(recepitInfo);
    }
  };

  const termsHandler = () => {
    setAcceptTermsAndConditions(true);
    setAdmission(false);
    setLoading(true);

    updateData(requestInfo);
  };

  useEffect(() => {
    setStates(
      subServiceCaregories.filter((ctr) => ctr.serviceId === requestId)
    );
    clearErrors("orderType");
  }, []);

  const [defaultOption, setDefaultOption] = useState("");

  useEffect(() => {
    // Retrieve the saved value from localStorage
    const savedValue = localStorage.getItem("requestId");

    // Find the corresponding option based on the saved value
    const defaultOption = serviceCaregories.find(
      (option) => option.id == savedValue
    );

    // Set the default option state
    if (defaultOption) {
      setDefaultOption(defaultOption);
      setValue("selectedOption", defaultOption);
      setValue("orderType", defaultOption);
      setRequestId(defaultOption);

      setStates(
        subServiceCaregories.filter((ctr) => ctr.serviceId == savedValue)
      );

      if (defaultOption?.id === 11) {
        setIsDisabled(true);
        setClassification("36");
      } else {
        setIsDisabled(false);
      }

      // if (defaultOption && classification) {
      //   updateClassification(lang, defaultOption?.id, classification);
      // }
      if (defaultOption?.id === 11 && classification !== "36") {
        setClassification("36");
      } else if (defaultOption && classification) {
        updateClassification(lang, defaultOption?.id, classification);
      }
    }
  }, [serviceCaregories, subServiceCaregories, classification, defaultOption]);

  const ChangeMainCategoery = (e) => {
    // setRequestId(e.target.value.id);
    localStorage.setItem("requestId", e.target.value.id);
    setDefaultOption(e.target.value);
    clearErrors("orderType");
    // resetField("classificationType");
    // resetField("suborderType");
    setClassification("");
    setKeyValue("");
    setValue("suborderType", null);
    setValue("classificationType", null);
    setOtherServiceDetails("");
    setCatigories([]);
    setStates(
      subServiceCaregories.filter((ctr) => ctr.serviceId == e.target.value.id)
    );

    if (e.target.value.id === 11) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  // const updateClassification = (lang, CategoryId, SubCategoryId) => {
  //   getServiceClassfications(lang, CategoryId, SubCategoryId).then((res) => {
  //     setCatigories(res);
  //   });
  // };

  const updateClassification = (lang, CategoryId, SubCategoryId) => {
    // Ensure `SubCategoryId` is valid before making the API call
    if (!SubCategoryId || SubCategoryId === "") {
      console.warn("⚠️ SubCategoryId is missing, defaulting to 0");
      return;
    }

    getServiceClassfications(lang, CategoryId, SubCategoryId)
      .then((res) => {
        console.log("✅ API Response:", res);
        setCatigories(res);
      })
      .catch((error) => {
        console.error(
          "❌ Error fetching classifications:",
          error.response?.data || error.message
        );
      });
  };

  const ChangeMainClassification = async (e) => {
    setClassification(e.target.value.id);
    clearErrors("classificationType");
    setValue("suborderType", null);
    setOtherServiceDetails("");
    // updateClassification(lang, requestId?.id, e.target.value.id);
  };

  const [keyValue, setKeyValue] = useState("");

  const keyServicesHandler = (e) => {
    clearErrors("suborderType");
    setOtherServiceDetails("");
    setValue("OtherrequestDetail","")
    setKeyValue(catigories.filter((key) => key.keyService === true));
    setPhysicalService(
      catigories.filter((physical) => physical.physicalService === true)
    );
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const DetailsOtherService = (event) => {
    setOtherServiceDetails(event.target.value);
    clearErrors("OtherrequestDetail");
  };

  // const DetailsOtherPartyType = (e) => {
  //   setOtherPartyType(e.target.value);
  //   clearErrors("customer");
  // };

  const somethingWentWrongHandler = () => {
    window.location.reload();
    setSomethingWentWrong(false);
  };

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "#F9F7ED",
        primary: "#B68A35",
      },
    };
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#fff",
      borderRadius: 4,
      "*": {
        boxShadow: "none !important",
      },
      backgroundColor: state.isFocused
        ? "#fffff0 !important"
        : "#fff !important",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      height: "auto", // Custom height
      overflow: "auto", // Scrollbar as needed
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      backgroundColor: "#fffff0",
      maxHeight: "200px", // Maximum height before scrollbar
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    myDropDown__indicator: (base) => ({
      ...base,
      "&.myDropDown__dropdown-indicator": {
        "&.indicatorContainer": {
          color: "#000000",
        },
      },
    }),
    "&.indicatorContainer": {
      color: "#000000",
    },
  };

  const formatOptionLabel = ({ name, label, customAbbreviation }) => (
    <div style={{ display: "flex" }}>
      <div className="text-[0.875rem] font-Almarai ">{name}</div>
    </div>
  );

  const partyTypesHandler = (e) => {
    setPartyType(e.target.value);
    clearErrors("customer");
  };

  const [selectedSuborderType, setSelectedSuborderType] = useState(0);

  const handleSelectedSuborderType = () => {
    setSelectedSuborderType(0);
  };

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  // Effect to handle the select being disabled
  useEffect(() => {
    if (isDisabled) {
      setValue("classificationType", { id: 0, label: "Zero" });
      // setValue("suborderType", { id: 0, label: "Zero" });
    }
  }, [isDisabled, setValue]);

  const selectedValue = watch("suborderType");
  const selectedClassficationIdValue = watch("classificationType");
  const selectedorderType = watch("orderType");

  const openPDF = () => {
    // Replace 'sample.pdf' with the actual path to your PDF file
    if (lang === "en") {
      window.open("/assets/img/Application Fees List.pdf", "_blank");
    } else {
      window.open("/assets/img/قائمة رسوم الطلبات.pdf", "_blank");
    }
  };

  console.log("selectedorderType", otherServiceDetails);

  return (
    <ThemeLayout>
      {somethingWentWrong && (
        <div className="bg-[#E23636]  w-full h-[68px]  flex items-center justify-between px-[24px]">
          <div className="flex items-center gap-[15px]">
            <img
              src="/assets/img/errorRequest.svg"
              alt="Error"
              className="Error"
            />
            <p className="text-[#fff] text-[14px] md:text-[20px] font-Almarai">
              {lang === "en"
                ? enPhrase["lblTechnicalError"]
                : arPhrase["lblTechnicalError"]}
            </p>
          </div>
          <div className="cursor-pointer" onClick={somethingWentWrongHandler}>
            <img
              src="/assets/img/close-icon.svg"
              alt="close-icon"
              className=""
            />
          </div>
        </div>
      )}
      <div className="flex flex-col w-full py-4 max-w-[1140px] px-[24px] lg:px-0">
        <Breadcrumb
          title={
            lang === "en"
              ? enPhrase["lblFollowCases"]
              : arPhrase["lblFollowCases"]
          }
          last={
            lang === "en"
              ? enPhrase["lblNewApplication"]
              : arPhrase["lblNewApplication"]
          }
        />
        <div className="flex justify-between flex-col md:flex-row mb-[15px] md:mb-[0px]">
          <div className="flex gap-[16px] ">
            <Link
              to="/case-overview"
              className="w-[32px] h-[32px] border-[1px] border-solid border-[#815327] rounded-[8px] flex items-center justify-center cursor-pointer bg-[#fff]"
            >
              <img
                src="/assets/img/arrow--down.svg"
                alt="back-icon"
                className={`${lang === "en" ? "rotate-180" : ""}`}
              />
            </Link>
            <LargeTitle
              title={
                lang === "en"
                  ? enPhrase["lblNewApplication"]
                  : arPhrase["lblNewApplication"]
              }
            />
          </div>
          <button
            onClick={openPDF}
            className="w-[200px] flex flex-row items-center gap-[8px]  h-[44px] font-bold font-Almarai text-[14px]  bg-[#F2ECCF] py-3 px-3 rounded-[8px]  text-[#815327]"
          >
            <img
              src="/assets/img/documentrequest.svg"
              alt="archive"
              className="w-5 h-5"
            />
            <span>
              {lang === "en" ? "Application Fee List" : "قائمة رسوم الطلبات"}
            </span>
          </button>
        </div>
        <div className="items-center w-full bg-white border-2 border-solid border-[#EEE2D0] rounded overflow-hidden shadow-[0px_10px_40px_#361E1214]">
          <div className="p-4 shadow-2xl w-full  bg-white">
            <form id="formreceipt" onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-6 bg-white">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="fullname"
                      className="block text-[12px] font-bold text-gray-700"
                    >
                      {lang === "en"
                        ? enPhrase["lbl_Name"]
                        : arPhrase["lbl_Name"]}
                    </label>
                    <input
                      disabled
                      type="text"
                      name="fullname"
                      key="fullname"
                      placeholder={
                        lang === "en"
                          ? enPhrase["lbl_Name"]
                          : arPhrase["lbl_Name"]
                      }
                      value={requesterInfo?.name}
                      className={`mt-1 block w-full rounded-md h-[40px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm  disabled:bg-[#EFEFEF] disabled:text-[#838383] border-0`}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="last-name"
                      className="block text-[12px] font-bold text-gray-700"
                    >
                      {lang === "en"
                        ? enPhrase["lblPhone"]
                        : arPhrase["lblPhone"]}
                    </label>
                    <input
                      dir="ltr"
                      disabled
                      type="mobile"
                      name="mobile"
                      id="mobile"
                      placeholder={
                        lang === "en"
                          ? enPhrase["lblPhone"]
                          : arPhrase["lblPhone"]
                      }
                      value={requesterInfo?.mobile}
                      autoComplete=" "
                      className={`${
                        lang === "en" ? "text-start" : "text-end"
                      } mt-1 px-2 block w-full rounded-md h-[40px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm disabled:bg-[#EFEFEF] disabled:text-[#838383] border-0`}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="last-name"
                      className="block text-[12px] font-bold text-gray-700"
                    >
                      {lang === "en"
                        ? enPhrase["lbl_Email"]
                        : arPhrase["lbl_Email"]}
                    </label>
                    <input
                      disabled
                      type="email"
                      name="email"
                      id="email"
                      placeholder={
                        lang === "en"
                          ? enPhrase["lbl_Email"]
                          : arPhrase["lbl_Email"]
                      }
                      value={requesterInfo?.email}
                      autoComplete=" "
                      className={`mt-1 block w-full rounded-md h-[40px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm disabled:bg-[#EFEFEF] disabled:text-[#838383] border-0`}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-6 sm:grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="court"
                      className={`block text-[12px] font-bold ${
                        errors.court ? " text-[#E61A05]" : "text-gray-700"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblCourt"]
                        : arPhrase["lblCourt"]}
                    </label>
                    <Controller
                      name="court"
                      rules={{
                        required: "court is required",
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={customTheme}
                          styles={customStyles}
                          noOptionsMessage={() =>
                            lang === "en"
                              ? enPhrase["lblNoResultFound"]
                              : arPhrase["lblNoResultFound"]
                          }
                          placeholder={
                            <div className="text-gray-600 text-sm">
                              {lang === "en"
                                ? enPhrase["lblCourtType"]
                                : arPhrase["lblCourtType"]}
                            </div>
                          }
                          label="court"
                          error={false}
                          {...field}
                          classNames={{
                            control: () =>
                              `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                errors.court
                                  ? "!border-red-600"
                                  : "!border-gray-300"
                              }`,
                          }}
                          classNamePrefix="select"
                          defaultValue={courts[0]}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="caseType"
                          options={courts}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          formatOptionLabel={formatOptionLabel}
                        />
                      )}
                    />
                    {errors.court && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["drpdwn_Court_error"]
                          : arPhrase["drpdwn_Court_error"]}
                      </span>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="caseType"
                      className={`block text-[12px] font-bold ${
                        errors.caseType ? " text-[#E61A05]" : "text-gray-700"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblCaseType"]
                        : arPhrase["lblCaseType"]}
                    </label>

                    <Controller
                      name="caseType"
                      rules={{
                        required: "Case Type is required",
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={customTheme}
                          styles={customStyles}
                          noOptionsMessage={() =>
                            lang === "en"
                              ? enPhrase["lblNoResultFound"]
                              : arPhrase["lblNoResultFound"]
                          }
                          placeholder={
                            <div className="text-gray-600 text-sm">
                              {lang === "en"
                                ? enPhrase["lblCasePalceHolder"]
                                : arPhrase["lblCasePalceHolder"]}
                            </div>
                          }
                          label="caseType"
                          error={false}
                          {...field}
                          classNames={{
                            control: () =>
                              `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                errors.caseType
                                  ? "!border-red-600"
                                  : "!border-gray-300"
                              }`,
                          }}
                          classNamePrefix="select"
                          defaultValue={caseTypes[0]}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="caseType"
                          options={caseTypes}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          formatOptionLabel={formatOptionLabel}
                          filterOption={(candidate, input) =>
                            candidate.data.nameForSearch
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          } // Search by "nameForSearch"
                        />
                      )}
                    />

                    {errors.caseType && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["lbl_Error_Case_Type"]
                          : arPhrase["lbl_Error_Case_Type"]}
                      </span>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <div>
                      <label
                        htmlFor="caseNumber"
                        className={`block text-[12px] font-bold ${
                          errors.caseNumber
                            ? " text-[#E61A05]"
                            : "text-gray-700"
                        }`}
                      >
                        {lang === "en"
                          ? enPhrase["lblCaseNumber"]
                          : arPhrase["lblCaseNumber"]}
                      </label>
                      <input
                        type="text"
                        name="caseNumber"
                        key="caseNumber"
                        placeholder={
                          lang === "en"
                            ? enPhrase["lblCaseNumberHolder"]
                            : arPhrase["lblCaseNumberHolder"]
                        }
                        aria-invalid={errors.caseNumber ? "true" : "false"}
                        {...register("caseNumber", {
                          required: true,
                        })}
                        className={`mt-1 block w-full rounded-md h-[40px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm ${
                          errors.caseNumber
                            ? " border-red-600"
                            : " border-gray-300"
                        }`}
                      />
                      {errors.caseNumber && (
                        <span role="alert" className=" text-xs text-red-600">
                          {lang === "en"
                            ? enPhrase["lbl_Case_Number_Error"]
                            : arPhrase["lbl_Case_Number_Error"]}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-6 sm:grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <div>
                      <label
                        htmlFor="caseYear"
                        className={`block text-[12px] font-bold ${
                          errors.caseYear ? " text-[#E61A05]" : "text-gray-700"
                        }`}
                      >
                        {lang === "en"
                          ? enPhrase["lblYear"]
                          : arPhrase["lblYear"]}
                      </label>

                      <Controller
                        name="caseYear"
                        rules={{
                          required: "case Year is required",
                        }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            theme={customTheme}
                            styles={customStyles}
                            noOptionsMessage={() =>
                              lang === "en"
                                ? enPhrase["lblNoResultFound"]
                                : arPhrase["lblNoResultFound"]
                            }
                            placeholder={
                              <div className="text-gray-600 text-sm">
                                {lang === "en"
                                  ? enPhrase["lblYearHolder"]
                                  : arPhrase["lblYearHolder"]}
                              </div>
                            }
                            label="caseYear"
                            error={false}
                            {...field}
                            classNames={{
                              control: () =>
                                `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                  errors.caseYear
                                    ? "!border-red-600"
                                    : "!border-gray-300"
                                }`,
                            }}
                            classNamePrefix="select"
                            defaultValue={optionsYear[0]}
                            isLoading={isLoading}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="caseYear"
                            options={optionsYear}
                            getOptionLabel={(options) => options["name"]}
                            getOptionValue={(options) => options["id"]}
                            formatOptionLabel={formatOptionLabel}
                          />
                        )}
                      />

                      {errors.caseYear && (
                        <span role="alert" className=" text-xs text-red-600">
                          {lang === "en"
                            ? enPhrase["lbl_Error_Case_Year"]
                            : arPhrase["lbl_Error_Case_Year"]}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="customer"
                      className={`block text-[12px] font-bold ${
                        errors.customer ? " text-[#E61A05]" : "text-gray-700"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblUserType"]
                        : arPhrase["lblUserType"]}
                    </label>

                    <Controller
                      name="customer"
                      rules={{
                        required: "party Types is required",
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={customTheme}
                          styles={customStyles}
                          noOptionsMessage={() =>
                            lang === "en"
                              ? enPhrase["lblNoResultFound"]
                              : arPhrase["lblNoResultFound"]
                          }
                          placeholder={
                            <div className="text-gray-600 text-sm">
                              {lang === "en"
                                ? enPhrase["lblUserTypeHolder"]
                                : arPhrase["lblUserTypeHolder"]}
                            </div>
                          }
                          label="customer"
                          error={false}
                          {...field}
                          classNames={{
                            control: () =>
                              `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                errors.customer
                                  ? "!border-red-600"
                                  : "!border-gray-300"
                              }`,
                          }}
                          classNamePrefix="select"
                          defaultValue={partyTypes[0]}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="caseYear"
                          options={partyTypes}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          formatOptionLabel={formatOptionLabel}
                          filterOption={(candidate, input) =>
                            candidate.data.nameForSearch
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          } // Search by "nameForSearch"
                        />
                      )}
                    />

                    {errors.customer && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["lblUserTypeError"]
                          : arPhrase["lblUserTypeError"]}
                      </span>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="orderType"
                      className={`block text-[12px] font-bold ${
                        errors.orderType ? "text-[#E61A05]" : "text-gray-700"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </label>
                    <Controller
                      name="orderType"
                      rules={{
                        required: "Order Types is required",
                        onChange: ChangeMainCategoery,
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={customTheme}
                          styles={customStyles}
                          noOptionsMessage={() =>
                            lang === "en"
                              ? enPhrase["lblNoResultFound"]
                              : arPhrase["lblNoResultFound"]
                          }
                          placeholder={
                            <div className="text-gray-600 text-sm">
                              {lang === "en"
                                ? enPhrase["lblRequestHolder"]
                                : arPhrase["lblRequestHolder"]}
                            </div>
                          }
                          label="orderType"
                          error={false}
                          {...field}
                          classNames={{
                            control: () =>
                              `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                errors.orderType
                                  ? "!border-red-600"
                                  : "!border-gray-300"
                              }`,
                          }}
                          classNamePrefix="select"
                          value={defaultOption || selectedorderType}
                          isLoading={isLoading}
                          // isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="orderType"
                          options={serviceCaregories}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          formatOptionLabel={formatOptionLabel}
                        />
                      )}
                    />

                    {errors.orderType && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["drpdwn_ApplicationType_error"]
                          : arPhrase["drpdwn_ApplicationType_error"]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-6 sm:grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="classificationType"
                      className={`block text-[12px] font-bold ${
                        errors.classificationType
                          ? " text-[#E61A05]"
                          : "text-gray-700"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblCategory"]
                        : arPhrase["lblCategory"]}
                    </label>

                    <Controller
                      name="classificationType"
                      rules={{
                        required: isDisabled
                          ? false
                          : "Classification Type is required",
                        onChange: ChangeMainClassification,
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={isDisabled ? "" : customTheme}
                          styles={isDisabled ? "" : customStyles}
                          noOptionsMessage={() =>
                            lang === "en"
                              ? enPhrase["lblNoResultFound"]
                              : arPhrase["lblNoResultFound"]
                          }
                          placeholder={
                            isDisabled ? (
                              <div className="!text-[#838383] text-sm">
                                {lang === "en"
                                  ? enPhrase["lblCategoryHolder"]
                                  : arPhrase["lblCategoryHolder"]}
                              </div>
                            ) : (
                              <div className="text-gray-600 text-sm">
                                {lang === "en"
                                  ? enPhrase["lblCategoryHolder"]
                                  : arPhrase["lblCategoryHolder"]}
                              </div>
                            )
                          }
                          label="classificationType"
                          error={false}
                          {...field}
                          classNames={{
                            control: () =>
                              `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                errors.classificationType
                                  ? "!border-red-600"
                                  : "!border-gray-300"
                              }`,
                          }}
                          classNamePrefix="select"
                          defaultValue={states[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="classificationType"
                          options={states}
                          value={
                            isDisabled
                              ? lang === "en"
                                ? "Category Type"
                                : "نوع التصنيف"
                              : selectedClassficationIdValue
                          }
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          formatOptionLabel={formatOptionLabel}
                        />
                      )}
                    />

                    {errors.classificationType && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["lblCategoryError"]
                          : arPhrase["lblCategoryError"]}
                      </span>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="suborderType"
                      className={`block text-[12px] font-bold ${
                        errors.suborderType
                          ? " text-[#E61A05]"
                          : "text-gray-700"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblServiceType"]
                        : arPhrase["lblServiceType"]}
                    </label>

                    <Controller
                      name="suborderType"
                      rules={{
                        required: "Sub Classification Type is required",
                        onChange: keyServicesHandler,
                      }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          theme={customTheme}
                          styles={customStyles}
                          noOptionsMessage={() =>
                            lang === "en"
                              ? enPhrase["lblNoResultFound"]
                              : arPhrase["lblNoResultFound"]
                          }
                          placeholder={
                            <div className="text-gray-600 text-sm">
                              {lang === "en"
                                ? enPhrase["lblServiceTypeHolder"]
                                : arPhrase["lblServiceTypeHolder"]}
                            </div>
                          }
                          label="suborderType"
                          error={false}
                          {...field}
                          classNames={{
                            control: () =>
                              `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                errors.suborderType
                                  ? "!border-red-600"
                                  : "!border-gray-300"
                              }`,
                          }}
                          classNamePrefix="select"
                          defaultValue={catigories[0]}
                          // isDisabled={isDisabled}
                          isLoading={isLoading}
                          // isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="suborderType"
                          value={selectedValue}
                          options={catigories}
                          getOptionLabel={(options) => options["name"]}
                          getOptionValue={(options) => options["id"]}
                          formatOptionLabel={formatOptionLabel}
                        />
                      )}
                    />

                    {/* {keyValue.length > 0 && (
                      <label className="flex items-center justify-start gap-[8px] mt-[8px]">
                        <input
                          type="checkbox"
                          className="text-[#AF8C45] border-[#AF8C45] focus:border-gold-100 focus:ring-gold-100 rounded-[4px]"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <p className="text-[14px] font-Almarai text-[#AF8C45] ">
                          {lang === "en"
                            ? enPhrase["lblKeysChanges"]
                            : arPhrase["lblKeysChanges"]}
                        </p>
                      </label>
                    )} */}

                    {errors.suborderType && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["lblServiceError"]
                          : arPhrase["lblServiceError"]}
                      </span>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <div>
                      <label
                        htmlFor="InquiryOption"
                        className={`block text-[12px] font-bold ${
                          errors.InquiryOption
                            ? " text-[#E61A05]"
                            : "text-gray-700"
                        }`}
                      >
                        {lang === "en"
                          ? enPhrase["lblBooking"]
                          : arPhrase["lblBooking"]}
                      </label>
                      <Controller
                        name="InquiryOption"
                        rules={{
                          required: false,
                        }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            theme={customTheme}
                            styles={customStyles}
                            noOptionsMessage={() =>
                              lang === "en"
                                ? enPhrase["lblNoResultFound"]
                                : arPhrase["lblNoResultFound"]
                            }
                            placeholder={
                              <div className="text-gray-600 text-sm">
                                {lang === "en"
                                  ? enPhrase["lblBookingHolder"]
                                  : arPhrase["lblBookingHolder"]}
                              </div>
                            }
                            label="InquiryOption"
                            error={false}
                            {...field}
                            classNames={{
                              control: () =>
                                `mt-1 border border-gray-300 text-black focus:!bg-gold-10 !rounded-lg text-[12px] ${
                                  errors.InquiryOption
                                    ? "!border-red-600"
                                    : "!border-gray-300"
                                }`,
                            }}
                            classNamePrefix="select"
                            defaultValue={InquiryOptions[0]}
                            isLoading={isLoading}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="InquiryOption"
                            options={InquiryOptions}
                            getOptionLabel={(options) => options["name"]}
                            getOptionValue={(options) => options["id"]}
                            formatOptionLabel={formatOptionLabel}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                {selectedValue?.id === 51 ? (
                  <div className="grid grid-cols-1 gap-2">
                    <label
                      htmlFor="OtherrequestDetail"
                      className={`${
                        errors.OtherrequestDetail
                          ? "text-red-600"
                          : "text-gray-700"
                      } block text-[12px] font-bold`}
                    >
                      {lang === "en"
                        ? enPhrase["lblRequestDetail"]
                        : arPhrase["lblRequestDetail"]}
                    </label>
                    <textarea
                      id="OtherrequestDetail"
                      name="OtherrequestDetail"
                      autoComplete="OtherrequestDetail"
                      {...register("OtherrequestDetail", {
                        required: true,
                      })}
                      className={`resize-none focus:border-gold-100 focus:ring-gold-100 h-[115px] rounded-[4px] text-[12px] ${
                        errors.OtherrequestDetail
                          ? "border-red-600"
                          : "border-[#CACACA]"
                      }`}
                      placeholder={
                        lang === "en"
                          ? enPhrase["lblWriteRequestDetails"]
                          : arPhrase["lblWriteRequestDetails"]
                      }
                      onChange={DetailsOtherService}
                    />
                    {errors.OtherrequestDetail && (
                      <span role="alert" className=" text-xs text-red-600">
                        {lang === "en"
                          ? enPhrase["lblEnterRequestDetail"]
                          : arPhrase["lblEnterRequestDetail"]}
                      </span>
                    )}
                  </div>
                ) : null}

                <div>
                  <div className="mt-1 cursor-pointer flex ">
                    <label
                      htmlFor="fileupload"
                      className="relative cursor-pointer flex flex-col w-[100%] items-center justify-center w-100 h-[123px] border-2 border-dashed bg-gold-10 border-[#B4B4B4] r rounded-md  px-6 pt-[46px] pb-[45px] font-medium text-gold-100 focus-within:outline-none focus-within:ring-2  "
                    >
                      {!file && errors.fileupload && (
                        <span
                          role="alert"
                          className="text-red-700 mx-3 text-xs mb-[10px]"
                        >
                          {lang === "en"
                            ? enPhrase["lblUploadError"]
                            : arPhrase["lblUploadError"]}
                        </span>
                      )}
                      <div className="flex items-center gap-[8px]">
                        {!file && (
                          <img
                            src="/assets/img/uploadFile.svg"
                            alt="Upload-File"
                            className="w-[32px] h-[32px]"
                          />
                        )}
                        {!file && (
                          <p className="text-[12px] text-[#6C4527] font-bold">
                            {lang === "en"
                              ? enPhrase["lblAttachJudgeDecision"]
                              : arPhrase["lblAttachJudgeDecision"]}
                          </p>
                        )}
                      </div>

                      <input
                        id="fileupload"
                        name="fileupload"
                        ref={inputFile}
                        type="file"
                        accept="application/pdf"
                        {...register("fileupload", {
                          required: true,
                        })}
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            setUploadedFile(e.target.files[0]);
                            getBase64(e.target.files[0], (res) => {
                              setFile(res);
                            });
                          } else {
                            setFile(null);
                          }
                        }}
                        className="sr-only "
                      />

                      {file && (
                        <span role="alert" className=" text-xs">
                          {uploadedFile?.name}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className=" flex justify-end pt-3 gap-[16px] flex-col sm:flex-row w-full">
                <Link
                  to="/case-overview"
                  className="inline-flex justify-center gap-2 items-center h-[44px] rounded-md border border-transparent bg-[#F2ECCF] py-4 px-8 text-sm font-bold text-white shadow-sm"
                >
                  <p className="text-[14px] font-bold font-Almarai text-[#815327] ">
                    {lang === "en"
                      ? enPhrase["btnCancel"]
                      : arPhrase["btnCancel"]}
                  </p>
                </Link>

                <button
                  type="submit"
                  disabled={physicalService.length > 0 ? loading : loading}
                  className="inline-flex justify-center gap-2 items-center h-[44px] rounded-md border border-transparent bg-gold-100 py-4 px-8 text-sm font-bold text-white shadow-sm"
                >
                  <span>
                    {lang === "en"
                      ? enPhrase["lblRegistration"]
                      : arPhrase["lblRegistration"]}
                  </span>
                  {loading ? <Loader color={"white"} /> : null}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {admission && (
        <SharedModal
          isAdmission
          openModal={admission}
          setOpenModal={setAdmission}
        >
          <div className="flex flex-col items-center justify-center gap-[24px] sm:gap-[32px] py-[24px] sm:py-[40px] px-[24px] sm:px-[32px]">
            <img
              src="/assets/img/Caution.svg"
              alt=""
              className="w-[64px] h-[52px] sm:w-[80px] sm:h-[65.2px]"
            />
            <div className="flex flex-col justify-start items-start">
              <p
                className={`text-[16px] w-full sm:text-[24px] text-[#010202]   font-Almarai  ${
                  lang === "en" ? "leading-[1.3]" : "leading-[1.67]"
                } `}
              >
                {lang === "en"
                  ? enPhrase["lblAdmissionText01"]
                  : arPhrase["lblAdmissionText01"]}
              </p>
              <p
                className={`text-[16px] w-full sm:text-[24px] text-[#010202]  font-Almarai  ${
                  lang === "en" ? "leading-[1.3]" : "leading-[1.67]"
                }`}
              >
                {lang === "en"
                  ? enPhrase["lblAdmissionText02"]
                  : arPhrase["lblAdmissionText02"]}
              </p>
            </div>
            <div className="flex gap-[16px] flex-col-reverse sm:flex-row   w-full justify-center">
              <button
                className="bg-[#F2ECCF] p-[8px] h-[44px] w-full sm:w-[317px] flex items-center justify-center rounded-[4px]"
                onClick={() => setAdmission(false)}
              >
                <p className="text-[16px] font-Almarai text-[#815327] font-bold">
                  {lang === "en"
                    ? enPhrase["lblGoBackText"]
                    : arPhrase["lblGoBackText"]}
                </p>
              </button>
              <button
                className="bg-[#B68A35] p-[8px] h-[44px] w-full sm:w-[317px] flex items-center justify-center rounded-[4px]"
                onClick={termsHandler}
              >
                <p className="text-[16px] font-Almarai text-[#fff] font-bold">
                  {lang === "en"
                    ? enPhrase["lblAgreeText"]
                    : arPhrase["lblAgreeText"]}
                </p>
              </button>
            </div>
          </div>
        </SharedModal>
      )}
      {phisicalStatus && (
        <SharedModal
          isPhisical
          openModal={phisicalStatus}
          setOpenModal={setPhisicalStatus}
        >
          <div className="flex flex-col items-center justify-center  p-[24px] sm:p-[32px]">
            <img
              src="/assets/img/PhysicalStaus.svg"
              alt=""
              className="mb-[16px] sm:mb-[32px] w-[83.5px] h-[83.5px] sm:w-[104px] sm:h-[104px]"
            />
            <h2
              className={`text-[16px] w-full ${
                lang === "en"
                  ? "sm:text-[21px] leading-[1.2]   h-auto sm:h-[100px]"
                  : " h-auto sm:text-[24px] leading-[1.67] sm:h-[80px]"
              } text-[#010202] text-center  font-Almarai font-bold  `}
            >
              {lang === "en"
                ? enPhrase["lblPhisicalStatus"]
                : arPhrase["lblPhisicalStatus"]}
            </h2>
            <div className="bg-[#dedede] h-[1px] sm:w-full my-[7.7px] sm:my-[23.5px] "></div>
            <div className="flex flex-col items-start  sm:w-full gap-[12px] mb-[16px] sm:mb-[32px]">
              <p className="sm:mb-[4px] text-[14px] sm:text-[20px] font-Almarai text-[#666] h-auto sm:h-auto">
                {lang === "en"
                  ? enPhrase["lblContactUSWays"]
                  : arPhrase["lblContactUSWays"]}
              </p>
              <div className="flex sm:flex-col gap-[8px] items-start">
                <div className="flex gap-[6px] sm:gap-[8px] items-center justify-center">
                  <div className="w-[24px] h-[24px] flex items-center justify-center border-[1px] border-solid border-[#f2eccf] rounded-[360px]">
                    <img src="/assets/img/mail.svg" alt="" className="" />
                  </div>
                  <p className="text-[12px] sm:text-[16px] text-[#676767] font-Almarai">
                    {courtEmail}
                  </p>
                </div>
                <div className="flex gap-[6px] sm:gap-[8px] items-center justify-center">
                  <div className="w-[24px] h-[24px] flex items-center justify-center border-[1px] border-solid border-[#f2eccf] rounded-[360px]">
                    <img src="/assets/img/phone.svg" alt="" className="" />
                  </div>
                  <p
                    className="text-[12px] sm:text-[16px] text-[#676767] font-Almarai"
                    dir="ltr"
                  >
                    {courtMobileNumber}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-[16px] flex-col sm:flex-row  w-full">
              <Link
                to={"/"}
                className="bg-[#F2ECCF] p-[8px] h-[44px] w-full sm:w-[176px] flex items-center justify-center rounded-[4px]"
              >
                <p className="text-[16px] font-Almarai text-[#815327] font-bold">
                  {lang === "en" ? enPhrase["btnHome"] : arPhrase["btnHome"]}
                </p>
              </Link>
              <button
                onClick={() => {
                  navigate("/#offices", { state: { targetId: "offices" } });
                }}
                smooth
                className="bg-[#B68A35] p-[8px] h-[44px] w-full sm:w-[176px] flex items-center justify-center rounded-[4px]"
              >
                <p className="text-[16px] font-Almarai text-[#fff] font-bold">
                  {lang === "en"
                    ? enPhrase["btnBranches"]
                    : arPhrase["btnBranches"]}
                </p>
              </button>
            </div>
          </div>
        </SharedModal>
      )}
      {requestSuccessMessage && (
        <SharedModal
          requestSuccessMessage={requestSuccessMessage}
          setOpenModal={setRequestSuccessMessage}
        >
          <div className="p-[32px] flex flex-col items-center justify-center">
            <p className="text-[24px] text-[#333] mb-[16px] font-Almarai font-bold text-center">
              {lang === "en"
                ? enPhrase["lblYourApplicationSuccessfully"]
                : arPhrase["lblYourApplicationSuccessfully"]}
            </p>
            <p className="text-[16px] text-[#666] mb-[12px] font-Almarai text-center">
              {lang === "en"
                ? enPhrase["lblApplicationRegisteredWaitingReview"]
                : arPhrase["lblApplicationRegisteredWaitingReview"]}
            </p>
            <div className="mb-[32px] flex items-center ">
              <p className="text-[16px] text-[#010202] font-Almarai font-bold text-center">
                {lang === "en"
                  ? enPhrase["lblApplicationNumber"]
                  : arPhrase["lblApplicationNumber"]}
              </p>
              <p className="text-[16px] text-[#010202] font-Almarai font-bold ">
                {requestIdAdded}
              </p>
            </div>
            <button
              className="w-[296px] bg-[#b68a35] h-[44px] py-[14px] px-[32px] rounded-[8px]"
              onClick={() => navigate("/case-overview")}
            >
              <p className="text-[14px] font-Almarai font-bold text-[#fff]">
                {lang === "en" ? enPhrase["lblOk"] : arPhrase["lblOk"]}
              </p>
            </button>
          </div>
        </SharedModal>
      )}
    </ThemeLayout>
  );
};

export default Request;
