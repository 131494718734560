import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PhrasesContext } from "../../context/phrasesContext";
import {
  getAllDetails,
  getCaseTypes,
  getCasesStatus,
  getCourts,
  getServiceCategories,
  getServiceStatus,
} from "../../services/Common";
import Pagination from "../pagination";
import ServiceStatus from "../serviceStatus";
import "./index.css";

export default function Table({
  servicesData,
  isSubTable,
  feedBackIsOpen,
  setFeedBackIsOpen,
  onSurvayRequest,
}) {
  const [requests, setRequests] = useState(servicesData || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestPerPage, setRequestPerPage] = useState(20);
  const [order, setOrder] = useState("ASC");
  const indexOfLastRequest = currentPage * requestPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestPerPage;
  const currentRequests = requests?.slice(
    indexOfFirstRequest,
    indexOfLastRequest
  );

  const [filtered, setFiltered] = useState("");
  const [filteredCaseNumber, setFilteredCaseNumber] = useState("");
  const [filteredServiceName, setFilteredServiceName] = useState("");
  const [filteredCaseTypeName, setFilteredCaseTypeName] = useState("");
  const [filteredCourtName, setFilteredCourtName] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [filteredStatusCase, setFilteredStatusCase] = useState("");
  const [filteredCreatDate, setFilteredCreatDate] = useState("");

  const [activeSort, setAciveSort] = useState(false);

  const inputFile = useRef(null);
  // const lang = window.localStorage.getItem("lang");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  const [lang, setLang] = useState("");
  const [courts, setCourts] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const [caseRequest, setCaseRequest] = useState([]);
  const [servicesStatus, setServicesStatus] = useState([]);
  const [casesStatus, setCasesStatus] = useState([]);
  const [years, setYears] = useState([]);
  const maxOffset = 60;

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  // const getInfoService = () => {
  //   return getInfo().then((res) => {
  //     if (res.status === 200) {
  //       // setCourts((courts) => res.data.courts);
  //       // setCaseTypes((caseTypes) => res.data.caseTypes);
  //       // setCaseRequest((caseRequest) => res.data.ticketCategories);
  //     }
  //   });
  // };

  const getServiceCatrgoryServices = (currentLang) => {
    return getServiceCategories(currentLang).then((res) => {
      setCaseRequest(res);
    });
  };

  const getCaseTypesSrvices = (currentLang) => {
    return getCaseTypes(currentLang).then((res) => {
      setCaseTypes(res);
    });
  };

  const getCourtsTypes = (currentLang) => {
    return getCourts(currentLang).then((res) => {
      setCourts(res);
    });
  };

  const getServicesStatus = (currentLang) => {
    return getServiceStatus(currentLang).then((res) => {
      setServicesStatus(res);
    });
  };

  const getCaseStatus = (currentLang) => {
    return getCasesStatus(currentLang).then((res) => {
      setCasesStatus(res);
    });
  };

  const getDetails = (currentLang) => {
    return getAllDetails(currentLang).then((res) => {
      setRequests(res?.service);
    });
  };

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    if (!isSubTable) getDetails(currentLang);
    // getInfoService();
    const year = new Date().getFullYear();
    setYears(Array.from(new Array(maxOffset), (val, index) => year - index));
    getServiceCatrgoryServices(currentLang);
    getCaseTypesSrvices(currentLang);
    getCourtsTypes(currentLang);
    getServicesStatus(currentLang);
    getCaseStatus(currentLang);
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const inputChageHandler = (e) => {
    setCurrentPage(1);
    setFiltered(e.target.value);
  };

  const caseNumberHandler = (e) => {
    setFilteredCaseNumber(e.target.value);
    setCurrentPage(1);
  };

  const serviceNameHandler = (e) => {
    setFilteredServiceName(e.target.value);
  };

  const caseTypeNameHandler = (e) => {
    setFilteredCaseTypeName(e.target.value);
  };

  const courtNameHandler = (e) => {
    setFilteredCourtName(e.target.value);
  };

  const statusHandler = (e) => {
    setFilteredStatus(e.target.value);
  };

  const statusCaseHandler = (e) => {
    setFilteredStatusCase(e.target.value);
  };

  const createDateHandler = (e) => {
    setFilteredCreatDate(e.target.value);
  };

  const filtered2 = requests
    .filter((request) =>
      filtered === ""
        ? request
        : request?.servicesId?.toString().includes(filtered)
    )
    .filter((request) =>
      filteredCaseNumber === ""
        ? request
        : request?.caseNumber?.toString().includes(filteredCaseNumber)
    )
    .filter((request) =>
      filteredServiceName === ""
        ? request
        : request?.serviceName?.toString().includes(filteredServiceName)
    )
    .filter((request) =>
      filteredCaseTypeName === ""
        ? request
        : request?.caseTypeName?.toString().includes(filteredCaseTypeName)
    )
    .filter((request) =>
      filteredCourtName === ""
        ? request
        : request?.courtName?.toString().includes(filteredCourtName)
    )
    .filter((request) =>
      filteredStatus === ""
        ? request
        : request?.serviceStatus?.toString().includes(filteredStatus)
    )
    .filter((request) =>
      filteredStatusCase === ""
        ? request
        : request?.caseStatus?.toString().includes(filteredStatusCase)
    )
    .filter((request) =>
      filteredCreatDate === ""
        ? request
        : request?.createdAt?.toString().includes(filteredCreatDate)
    );

  const mainFilter = currentRequests
    .filter((request) =>
      filtered === ""
        ? request
        : request?.servicesId?.toString().includes(filtered)
    )
    .filter((request) =>
      filteredCaseNumber === ""
        ? request
        : request?.caseNumber?.toString().includes(filteredCaseNumber)
    )
    .filter((request) =>
      filteredServiceName === ""
        ? request
        : request?.serviceName?.toString().includes(filteredServiceName)
    )
    .filter((request) =>
      filteredCaseTypeName === ""
        ? request
        : request?.caseTypeName
            ?.toString()
            .toLowerCase()
            .includes(filteredCaseTypeName)
    )
    .filter((request) =>
      filteredCourtName === ""
        ? request
        : request?.courtName?.toString().includes(filteredCourtName)
    )
    .filter((request) =>
      filteredStatus === ""
        ? request
        : request?.serviceStatus?.toString().includes(filteredStatus)
    )
    .filter((request) =>
      filteredStatusCase === ""
        ? request
        : request?.caseStatus?.toString().includes(filteredStatusCase)
    )
    .filter((request) =>
      filteredCreatDate === ""
        ? request
        : request?.createdAt
            ?.toString()
            .toLowerCase()
            .includes(filteredCreatDate)
    );

  const handleChangeRequestPerPage = (event) => {
    setRequestPerPage(event.target.value);
    setCurrentPage(1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handlerChangeCourt = (e) => {
  //   setFiltered(e.target.value);
  //   console.log("filtering", filtered);
  // };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...requests].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setRequests(sorted);
      setOrder("DSC");
      setAciveSort(!activeSort);
    }
    if (order === "DSC") {
      const sorted = [...requests].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setRequests(sorted);
      setOrder("ASC");
      setAciveSort(!activeSort);
    }
  };

  const performFirstAction = () => {
    setFeedBackIsOpen(true);
  };

  const performSecondAction = (value1) => {
    setTimeout(() => {
      // Assume this is an async action
      console.log("val", value1);
      onSurvayRequest(value1);
    }, 100); // Delay for demonstration
  };

  const handleClick = (value1) => {
    performFirstAction();
    performSecondAction(value1);
  };

  // console.log("filter",filtered2[0]?.caseNumber)

  return (
    <div class="flex flex-col w-full">
      <div class="overflow-hidden xs:overflow-scroll sm:overflow-scroll md:overflow-scroll lg:overflow-hidden xl:overflow-hidden  ">
        <table class="min-w-full text-left text-sm font-light w-full ">
          <thead class=" bg-[#ffffff] h-[92px]  border-[1px] border-solid border-[#E6D7A2] !rounded-[12px]  ">
            {/* <thead class="h-[92px]   border-[1px] border-solid border-[#E6D7A2] !rounded-[8px] bg-[#fff] absolute !top-0 w-full "> */}
            <tr style={{ borderRadius: "20px" }}>
              <th
                scope="col"
                className={`${
                  isSubTable
                    ? lang === "en"
                      ? "numberRequestThDestalisEn"
                      : "numberRequestThDestalis"
                    : "numberRequestTh"
                }`}
              >
                <div className="Mainth numberRequest">
                  <p
                    style={{
                      // width: "65px",
                      fontSize: "14px",
                      color: "#010202",
                      textAlign: "right",
                      fontFamily: "Almarai",
                      fontWeight: "bold",
                      height: "18px",
                    }}
                    className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                      lang === "en" ? "text-left !text-[12px]" : " text-[14px]"
                    }`}
                  >
                    {lang === "en"
                      ? enPhrase["lblAppNumber"]
                      : arPhrase["lblAppNumber"]}
                  </p>
                  {activeSort ? (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src="/assets/img/case-overview/Active-sort.svg"
                      onClick={() => sorting("caseId")}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src="/assets/img/case-overview/sort.svg"
                      onClick={() => sorting("caseId")}
                      alt=""
                    />
                  )}
                </div>
                <div className="sectionInput" style={{ width: "85px" }}>
                  <input
                    className="MainInput "
                    style={{ width: "85px", height: "24px" }}
                    placeholder={lang === "en" ? "App. Number" : "رقم الطلب"}
                    onChange={inputChageHandler}
                    value={filtered}
                  />
                  <img
                    src="/assets/img/case-overview/close.svg"
                    alt="close-icon"
                    onClick={() => setFiltered("")}
                    className={lang === "en" ? "sectionInputImgAr" : ""}
                  />
                </div>
              </th>
              <th
                scope="col"
                className={`${
                  isSubTable
                    ? lang === "en"
                      ? "typeRequestThDetailsEn"
                      : "typeRequestThDetails"
                    : "typeRequestTh"
                }`}
              >
                <div className="typeRequest">
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#010202",
                      fontWeight: "normal",
                      textAlign: "right",
                      // width: "63px",
                      fontFamily: "Almarai",
                      fontWeight: "bold",
                      height: "18px",
                    }}
                    className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                      lang === "en"
                        ? "!text-left !text-[12px]"
                        : "text-right text-[14px]"
                    }`}
                  >
                    {lang === "en" ? "Application Type" : "نوع الطلب"}
                  </p>
                </div>
                <div className="sectionInput">
                  <select
                    style={{
                      height: "24px",
                      width: "135px",
                      fontSize: "12px",
                      padding: "0 10px",
                      fontWeight: "normal",
                      color: "#949494",
                    }}
                    className={
                      lang === "en"
                        ? "selectLineAR focus:border-gold-100 focus:ring-gold-100 !px-[2px]"
                        : "selectLine focus:border-gold-100 focus:ring-gold-100"
                    }
                    onChange={serviceNameHandler}
                    value={filteredServiceName}
                  >
                    <option
                      value=""
                      className="text-slate-200"
                      disabled
                      selected
                      hidden
                    >
                      {" "}
                      {lang === "en" ? "Application Type" : " اختر نوع الطلب"}
                    </option>
                    {caseRequest?.map((caseRequest) => (
                      <option key={caseRequest?.id} value={caseRequest?.name}>
                        {caseRequest?.name}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/case-overview/close.svg"
                    alt="close-icon"
                    onClick={() => setFilteredServiceName("")}
                    className={
                      lang === "en"
                        ? isSubTable
                          ? "!absolute !left-[120px]"
                          : "sectionInputImgAr"
                        : " !absolute right-[120px]"
                    }
                  />
                </div>
              </th>
              {!isSubTable && (
                <th scope="col" className="numberCaseTh">
                  <div className="Mainth numberCase">
                    <p
                      style={{
                        // width: "95px",
                        fontSize: "14px",
                        color: "#010202",
                        fontWeight: "normal",
                        textAlign: "right",
                        fontFamily: "Almarai",
                        fontWeight: "bold",
                        height: "18px",
                      }}
                      className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                        lang === "en"
                          ? "!text-left !text-[12px]"
                          : " text-[14px]"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblCaseNumber"]
                        : arPhrase["lblCaseNumber"]}
                    </p>
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src="/assets/img/case-overview/sort.svg"
                      onClick={() => sorting("caseNumber")}
                      alt=""
                    />
                  </div>
                  <div className="sectionInput" style={{ width: "95px" }}>
                    <input
                      className="MainInput"
                      placeholder={lang === "en" ? "Case Number" : "رقم القضيه"}
                      onChange={caseNumberHandler}
                      value={filteredCaseNumber}
                    />
                    <img
                      src="/assets/img/case-overview/close.svg"
                      alt="close-icon"
                      onClick={() => setFilteredCaseNumber("")}
                      className={lang === "en" ? "sectionInputImgAr" : ""}
                    />
                  </div>
                </th>
              )}

              {!isSubTable && (
                <th scope="col" className="typeCourtTh">
                  <div className="typeCourt">
                    <p
                      style={{
                        // width: "66px",
                        fontSize: "14px",
                        color: "#010202",
                        fontWeight: "normal",
                        textAlign: "right",
                        fontFamily: "Almarai",
                        fontWeight: "bold",
                        height: "18px",
                      }}
                      className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                        lang === "en"
                          ? "!text-left !text-[12px]"
                          : " text-[14px]"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblCaseType"]
                        : arPhrase["lblCaseType"]}
                    </p>
                    {/* <img
                          style={{ width: "16px", height: "16px" }}
                          src="/assets/img/case-overview/sort-solid.svg"
                          onClick={() => sorting("caseTypeName")}
                          alt=""
                        /> */}
                  </div>
                  {/* <input
                      className="MainInput"
                      placeholder="بحث"
                      onChange={inputChageHandler}
                    /> */}
                  <div className="sectionInput">
                    {lang === "en" ? (
                      <select
                        id="caseType"
                        name="caseType"
                        placeholder={
                          lang === "en"
                            ? enPhrase["lbl_Case_Type"]
                            : arPhrase["lbl_Case_Type"]
                        }
                        autoComplete="caseType"
                        style={{
                          height: "24px",
                          // width: "114px",
                          width: "207px",
                          fontSize: "12px",
                          padding: "0 10px",
                          fontWeight: "normal",
                          color: "#949494",
                          fontSize: "12px",
                        }}
                        className={
                          lang === "en"
                            ? "selectLineEn focus:border-gold-100 focus:ring-gold-100 !px-[2px]"
                            : "selectLine focus:border-gold-100 focus:ring-gold-100"
                        }
                        onChange={caseTypeNameHandler}
                        value={filteredCaseTypeName}
                      >
                        <option
                          value=""
                          className="text-slate-200"
                          disabled
                          selected
                          hidden
                        >
                          {lang === "en"
                            ? "Application Type"
                            : "اختر نوع القضية"}
                        </option>
                        {caseTypes?.map((caseType) => (
                          <option key={caseType.id} value={caseType.name}>
                            {caseType.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        id="caseType"
                        name="caseType"
                        placeholder={
                          lang === "en"
                            ? enPhrase["lbl_Case_Type"]
                            : arPhrase["lbl_Case_Type"]
                        }
                        autoComplete="caseType"
                        style={{
                          height: "24px",
                          // width: "114px",
                          width: "170px",
                          fontSize: "12px",
                          padding: "0 10px",
                          fontWeight: "normal",
                          color: "#949494",
                          fontSize: "12px",
                        }}
                        className={
                          lang === "en"
                            ? "selectLineEn focus:border-gold-100 focus:ring-gold-100 !px-[2px]"
                            : "selectLine focus:border-gold-100 focus:ring-gold-100"
                        }
                        onChange={caseTypeNameHandler}
                        value={filteredCaseTypeName}
                      >
                        <option
                          value=""
                          className="text-slate-200"
                          disabled
                          selected
                          hidden
                        >
                          {lang === "en"
                            ? "Application Type"
                            : "اختر نوع القضية"}
                        </option>
                        {caseTypes?.map((caseType) => (
                          <option key={caseType.id} value={caseType.name}>
                            {caseType.name}
                          </option>
                        ))}
                      </select>
                    )}
                    <img
                      src="/assets/img/case-overview/close.svg"
                      alt="close-icon"
                      onClick={() => setFilteredCaseTypeName("")}
                      className={lang === "en" ? "sectionInputImgAr" : ""}
                    />
                  </div>
                </th>
              )}

              {!isSubTable && (
                <th scope="col" className="courtTh">
                  <div className="court">
                    <p
                      style={{
                        // width: "50px",
                        fontSize: "14px",
                        color: "#010202",
                        fontWeight: "normal",
                        textAlign: "right",
                        fontFamily: "Almarai",
                        fontWeight: "bold",
                        height: "18px",
                      }}
                      className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                        lang === "en"
                          ? "!text-left !text-[12px]"
                          : " text-[14px]"
                      }`}
                    >
                      {lang === "en"
                        ? enPhrase["lblCourt"]
                        : arPhrase["lblCourt"]}
                    </p>
                    {/* <img
                        style={{ width: "16px", height: "16px" }}
                        src="./assets/img/case-overview/sort-solid.svg"
                        onClick={() => sorting("courtName")}
                        alt=""
                      /> */}
                  </div>
                  {/* <input
                      className="MainInput"
                      placeholder="بحث"
                      onChange={inputChageHandler}
                    /> */}

                  <div className="sectionInput">
                    <select
                      id="court"
                      name="court"
                      placeholder={
                        lang === "en"
                          ? enPhrase["lbl_Court"]
                          : arPhrase["lbl_Court"]
                      }
                      autoComplete="court"
                      style={{
                        height: "24px",
                        width: "120px",
                        fontSize: "12px",
                        padding: "0 10px",
                        fontWeight: "normal",
                        color: "#949494",
                        fontSize: "12px",
                      }}
                      className={
                        lang === "en"
                          ? "selectLineAR focus:border-gold-100 focus:ring-gold-100 !px-[2px]"
                          : "selectLine focus:border-gold-100 focus:ring-gold-100"
                      }
                      onChange={courtNameHandler}
                      value={filteredCourtName}
                    >
                      <option
                        value=""
                        className="text-slate-200"
                        disabled
                        selected
                        hidden
                      >
                        {lang === "en" ? "Court" : "اختر المحكمة"}
                      </option>

                      {courts?.map((court) => (
                        <option key={court.id} value={court.name}>
                          {court.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src="/assets/img/case-overview/close.svg"
                      alt="close-icon"
                      onClick={() => setFilteredCourtName("")}
                      className={lang === "en" ? "sectionInputImgAr" : ""}
                    />
                  </div>
                </th>
              )}

              {/* Updates 2024 */}
              {/* {!isSubTable && (
                <th scope="col" className="courtStatusTh">
                  <div className="courtStatus">
                    <p
                      style={{
                        // width: "70px",
                        fontSize: '14px',
                        color: '#010202',
                        fontWeight: 'normal',
                        textAlign: 'right',
                        fontFamily: 'Almarai',
                        fontWeight: 'bold',
                        height: '18px',
                      }}>
                      حالة القضية
                    </p>
                  </div>
                  <div className="sectionInput">
                    <select
                      style={{
                        height: '24px',
                        width: '113px',
                        fontSize: '12px',
                        padding: '0 10px',
                        fontWeight: 'normal',
                        color: '#949494',
                        fontSize: '12px',
                      }}
                      className={
                        lang === 'en'
                          ? 'selectLineAR focus:border-gold-100 focus:ring-gold-100'
                          : 'selectLine focus:border-gold-100 focus:ring-gold-100'
                      }
                      onChange={statusCaseHandler}
                      value={filteredStatusCase}>
                      <option value='' className='text-slate-200'>
                        اختر حالة القضية
                      </option>
                      {casesStatus.map((caseStaus) => (
                        <option value={caseStaus.name}>{caseStaus.name}</option>
                      ))}
                    </select>
                    <img
                      src='/assets/img/case-overview/close.svg'
                      alt='close-icon'
                      onClick={() => setFilteredStatusCase('')}
                      className={lang === 'en' ? 'sectionInputImgAr' : ''}
                    />
                  </div>
                </th>
              )} */}

              <th scope="col" className="dateRequestTh">
                <div className="dateRequest">
                  <p
                    style={{
                      // width: "109px",
                      fontSize: "14px",
                      color: "#010202",
                      fontWeight: "normal",
                      textAlign: "right",
                      fontFamily: "Almarai",
                      fontWeight: "bold",
                      height: "18px",
                    }}
                    className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                      lang === "en" ? "!text-left !text-[12px]" : " text-[14px]"
                    }`}
                  >
                    {lang === "en"
                      ? enPhrase["lblCreatedDate"]
                      : arPhrase["lblCreatedDate"]}
                  </p>
                  {/* <img
                        style={{ width: "16px", height: "16px" }}
                        src="./assets/img/case-overview/sort-solid.svg"
                        onClick={() => sorting("status")}
                        alt=""
                      /> */}
                </div>
                {/* <input
                      className="MainInput"
                      placeholder="بحث"
                      onChange={inputChageHandler}
                    /> */}
                <div className="sectionInput" style={{ width: "95px" }}>
                  <select
                    style={{
                      height: "24px",
                      width: "95px",
                      fontSize: "12px",
                      padding: "0 10px",
                      fontWeight: "normal",
                      color: "#949494",
                      fontSize: "12px",
                    }}
                    className={
                      lang === "en"
                        ? "selectLineAR focus:border-gold-100 focus:ring-gold-100 !px-[2px]"
                        : "selectLine focus:border-gold-100 focus:ring-gold-100"
                    }
                    onChange={createDateHandler}
                    value={filteredCreatDate}
                  >
                    <option
                      value=""
                      className="text-slate-200"
                      disabled
                      selected
                      hidden
                    >
                      {lang === "en" ? "Date" : "اختر تاريخ"}
                    </option>
                    {/* <option value="11/2023">11/2023 </option>
                        <option value="13/2023">13/2023</option>
                        <option value="17/2023">17/2023 </option>
                        <option value="18/2023">18/2023 </option>
                        <option value="09/2023">09/2023</option> */}

                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/case-overview/close.svg"
                    alt="close-icon"
                    onClick={() => setFilteredCreatDate("")}
                    className={
                      lang === "en"
                        ? "sectionInputImgAr !right-[0px]"
                        : "!left-[0px]"
                    }
                  />
                </div>
              </th>
              <th
                scope="col"
                className={`${
                  isSubTable
                    ? lang === "en"
                      ? "requestStatusThDetailsEn"
                      : "requestStatusThDetails"
                    : "requestStatusTh"
                }`}
              >
                <div className="requestStatus">
                  <p
                    style={{
                      // width: "67px",
                      fontSize: "14px",
                      color: "#010202",
                      fontWeight: "normal",
                      textAlign: "right",
                      fontFamily: "Almarai",
                      fontWeight: "bold",
                      height: "18px",
                    }}
                    className={` text-[#010202]  font-Almarai font-bold h-[18px] ${
                      lang === "en" ? "!text-left !text-[12px]" : " text-[14px]"
                    }`}
                  >
                    {lang === "en"
                      ? enPhrase["lblApplicationStatus"]
                      : arPhrase["lblApplicationStatus"]}
                  </p>
                  {/* <img
                        style={{ width: "16px", height: "16px" }}
                        src="./assets/img/case-overview/sort-solid.svg"
                        onClick={() => sorting("status")}
                        alt=""
                      /> */}
                </div>
                {/* <input
                      className="MainInput"
                      placeholder="بحث"
                      onChange={inputChageHandler}
                    /> */}
                <div
                  className="sectionInput"
                  style={{
                    width: "145px",
                  }}
                >
                  <select
                    style={{
                      height: "24px",
                      width: "145px",
                      fontSize: "12px",
                      padding: "0 10px",
                      fontWeight: "normal",
                      color: "#949494",
                    }}
                    className={
                      lang === "en"
                        ? "selectLineAR focus:border-gold-100 focus:ring-gold-100 !px-[2px]"
                        : "selectLine focus:border-gold-100 focus:ring-gold-100"
                    }
                    onChange={statusHandler}
                    value={filteredStatus}
                  >
                    <option
                      value=""
                      className="text-slate-200"
                      disabled
                      selected
                      hidden
                    >
                      {lang === "en" ? "Status" : "اختر حالة الطلب"}
                    </option>

                    {servicesStatus?.map((serviceStatus) => (
                      <option value={serviceStatus.name}>
                        {serviceStatus.name}
                      </option>
                    ))}
                  </select>
                  <img
                    src="/assets/img/case-overview/close.svg"
                    alt="close-icon"
                    onClick={() => setFilteredStatus("")}
                    className={lang === "en" ? "sectionInputImgAr" : ""}
                  />
                </div>
              </th>
              {/* <th scope="col" className="px-3 py-3 text-right	">
                    <div className="Mainth">
                      <p
                        style={{
                          width: "76px",
                          fontSize: "14px",
                          color: "#010202",
                          fontWeight: "normal",
                          textAlign: "right",
                        }}
                      >
                        مقدم الطلب
                      </p>
                      <img
                        style={{ width: "16px", height: "16px" }}
                        src="./assets/img/case-overview/sort-solid.svg"
                        onClick={() => sorting("requesterName")}
                        alt=""
                      />
                    </div>
                    <input
                      style={{ width: "180px" }}
                      className="MainInput"
                      placeholder="بحث"
                      onChange={inputChageHandler}
                    />
                  </th> */}

              {/* <th scope="col" className="px-3 py-3 text-right	">
                    <div className="Mainth">
                      <p
                        style={{
                          width: "42px",
                          fontSize: "14px",
                          color: "#010202",
                          fontWeight: "normal",
                          textAlign: "right",
                        }}
                      >
                        الهاتف
                      </p>
                      <img
                        style={{ width: "16px", height: "16px" }}
                        src="./assets/img/case-overview/sort-solid.svg"
                        onClick={() => sorting("requesterMobile")}
                        alt=""
                      />
                    </div>
                    <input
                      className="MainInput"
                      placeholder="بحث"
                      onChange={inputChageHandler}
                    />
                  </th> */}

              <th scope="col" style={{ width: "130px" }}></th>
            </tr>
          </thead>
          {filtered2.length === 0 ? (
            <p className="text-[18px] text-center !mt-[25px] w-[max-content] font-bold text-red-500">
              {lang === "en"
                ? enPhrase["lblThereAreApplications"]
                : arPhrase["lblThereAreApplications"]}
            </p>
          ) : (
            <tbody>
              {filtered === "" &&
              filteredServiceName === "" &&
              filteredCaseTypeName === "" &&
              filteredStatus === "" &&
              filteredStatusCase === "" &&
              filteredCaseNumber === "" &&
              filteredCreatDate === "" &&
              filteredCourtName === ""
                ? mainFilter?.map((request, index) => (
                    <tr
                      className="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700"
                      class={`${
                        request?.serviceStatusId === 5 ? "odd" : "odd"
                      }`}
                    >
                      <td
                        className={
                          lang === "en"
                            ? "numberRequestTdEn"
                            : "numberRequestTd"
                        }
                        style={{ width: "85px" }}
                      >
                        {request?.servicesId}
                      </td>
                      <td
                        className="underline text-[#AF8C45]"
                        class={
                          lang === "en" ? "typeRequestTdEn" : "typeRequestTd"
                        }
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <Link
                          to={`/order-details/${request?.caseId}/${request?.servicesId}`}
                          className={`flex transition-all duration-300 font-medium py-2`}
                          style={{ width: "140px" }}
                        >
                          <p
                            style={{
                              width: "140px",
                              textOverflow: "ellipsis",
                              textWrap: "balance",
                            }}
                          >
                            {request?.serviceName}
                          </p>
                        </Link>
                      </td>
                      {!isSubTable && (
                        <td
                          className={
                            lang === "en" ? "numberCaseTdEn" : "numberCaseTd"
                          }
                        >
                          {request?.caseId !== 0 &&
                          request?.caseNumber !== null ? (
                            <Link
                              to={`/case-details/${request?.caseId}`}
                              className={`flex transition-all duration-300 font-medium py-2    ${
                                lang === "en"
                                  ? "numberCaseTdEntext"
                                  : "numberCasetext"
                              }`}
                            >
                              {/* {request?.caseNumber !== null
                                ? request?.caseNumber.slice(10)
                                : request?.caseNumber} */}
                              {request?.caseNumber?.slice(-12)}
                            </Link>
                          ) : (
                            <p
                              className={`font-medium py-2 text-black ${
                                lang === "en"
                                  ? "numberCaseTdEntextPending"
                                  : "numberCasetextPending"
                              }`}
                              style={{
                                width: "95px",
                                textOverflow: "ellipsis",
                                textWrap: "balance",
                              }}
                            >
                              {/* {request?.caseNumber !== null
                                ? request?.caseNumber.slice(10)
                                : request?.caseNumber} */}
                              {request?.caseNumber?.slice(-12)}
                            </p>
                          )}
                        </td>
                      )}
                      {!isSubTable && (
                        <td
                          className={
                            lang === "en" ? "typeCourtTdEn" : "typeCourtTd"
                          }
                        >
                          {request?.caseTypeName}
                        </td>
                      )}
                      {!isSubTable && (
                        <td className={lang === "en" ? "courtTdEn" : "courtTd"}>
                          {request?.courtName}
                        </td>
                      )}

                      <td
                        className={
                          lang === "en"
                            ? "dateRequestTdEn font-Almarai"
                            : "dateRequestTd"
                        }
                      >
                        {/* {lang === "en"
                          ? new Date(request?.createdAt).toLocaleDateString(
                              "en-US"
                            )
                          : new Date(request?.createdAt)
                              .toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })
                              .split("/")
                              .reverse()
                              .join("/")} */}
                        {lang === "en"
                          ? new Date(request?.createdAt).toLocaleDateString(
                              "en-GB"
                            )
                          : new Date(request?.createdAt).toLocaleDateString(
                              "en-GB"
                            )}
                      </td>

                      <td
                        className={
                          lang === "en"
                            ? "requestStatusTdEn"
                            : "requestStatusTd"
                        }
                      >
                        <p className="flex items-center gap-2">
                          <ServiceStatus
                            serviceStatusId={request?.serviceStatusId}
                          />

                          {/* <FontAwesomeIcon
                            icon={faSquare}
                            className={
                              request?.Status === "قيد المراجعة"
                                ? "statuesSquare2"
                                : request?.Status === "منتهية"
                                ? "statuesSquare4"
                                : "statuesSquare3"
                            }
                          /> */}
                          <span>{request?.serviceStatus}</span>
                        </p>
                      </td>

                      {/* Updates 2024 */}
                      {/* {!isSubTable && (
                        <td
                          className={
                            lang === 'en' ? 'courtStatusTdEn' : 'courtStatusTd'
                          }>
                          {request?.caseStatus}
                        </td>
                      )} */}

                      {/* <td className={lang === "en" ? "actionTdEn" : "actionTd"}>
                        {request?.serviceStatus === "مطلوب بيانات اضافية" ||
                        request?.serviceStatus === "قيد السداد" ||
                        request?.serviceStatus === "Need More Data" ||
                        request?.serviceStatus === "Pending for Payment" ? (
                          <Link
                            to={`/order-details/${request?.caseId}/${request?.servicesId}`}
                            className="flex gap-[4px] underline decoration-[#af8c45]"
                          >
                            <p
                              className={
                                lang === 'en' ? 'actionSectiontextEn' : ''
                              }>
                              {request?.serviceStatus}
                            </p>
                            <img
                              src='./assets/img/case-overview/chevron--sort--down.svg'
                              alt=''
                              className={
                                lang === 'en' ? 'actionSectionImgEn' : ''
                              }
                            />
                          </Link>
                        ) : null}
                      </td> */}
                      <div>
                        <td
                          className={lang === "en" ? "actionTdEn" : "actionTd"}
                          // onClick={() => window.scrollTo(0, 0)}
                        >
                          {request?.serviceStatusId === 2 ||
                          request?.serviceStatusId === 3 ? (
                            <Link
                              to={`/order-details/${request?.caseId}/${request?.servicesId}`}
                              className="flex gap-[4px] underline decoration-[#af8c45]"
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              {request?.serviceStatusId === 2 ? (
                                <p>
                                  {lang === "en"
                                    ? enPhrase["lblCompleteData"]
                                    : arPhrase["lblCompleteData"]}
                                </p>
                              ) : request?.serviceStatusId === 3 ? (
                                <p>
                                  {lang === "en"
                                    ? enPhrase["lblPay"]
                                    : arPhrase["lblPay"]}
                                </p>
                              ) : (
                                ""
                              )}
                              <img
                                src="/assets/img/case-overview/chevron--sort--down.svg"
                                alt=""
                                className={
                                  lang === "en" ? "actionSectionImgEn" : ""
                                }
                              />
                            </Link>
                          ) : request?.serviceStatusId === 5 &&
                            request?.completeSurvey === false ? (
                            <p
                              className=" gap-[4px] underline decoration-[#af8c45] flex"
                              onClick={() => handleClick(request)}
                            >
                              {request?.serviceStatusId === 5 ? (
                                <p>
                                  {lang === "en"
                                    ? enPhrase["lbl_Survey"]
                                    : arPhrase["lbl_Survey"]}
                                </p>
                              ) : (
                                ""
                              )}
                              <img
                                src="/assets/img/case-overview/chevron--sort--down.svg"
                                alt=""
                                className={
                                  lang === "en" ? "actionSectionImgEn" : ""
                                }
                              />
                            </p>
                          ) : null}
                        </td>
                      </div>
                    </tr>
                  ))
                : filtered2
                    ?.slice(indexOfFirstRequest, indexOfLastRequest)
                    .map((request, index) => (
                      <tr
                        class="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700"
                        className={`${
                          request?.serviceStatusId === 5 ? "odd" : "odd"
                        }`}
                      >
                        <td
                          className={
                            lang === "en"
                              ? "numberRequestTdEn"
                              : "numberRequestTd"
                          }
                          style={{ width: "85px" }}
                        >
                          {request?.servicesId}
                        </td>
                        <td
                          class="underline text-[#AF8C45]"
                          className={
                            lang === "en" ? "typeRequestTdEn" : "typeRequestTd"
                          }
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <Link
                            to={`/order-details/${request?.caseId}/${request?.servicesId}`}
                            className={`flex transition-all duration-300 font-medium py-2`}
                            style={{ width: "140px" }}
                          >
                            <p
                              style={{
                                width: "140px",
                                textOverflow: "ellipsis",
                                textWrap: "balance",
                              }}
                            >
                              {request?.serviceName}
                            </p>
                          </Link>
                        </td>
                        {!isSubTable && (
                          <td
                            className={
                              lang === "en" ? "numberCaseTdEn" : "numberCaseTd"
                            }
                          >
                            {request?.caseId !== 0 &&
                            request?.caseNumber !== null ? (
                              <Link
                                to={`/case-details/${request?.caseId}`}
                                className={`flex transition-all duration-300 font-medium py-2 ${
                                  lang === "en"
                                    ? "numberCaseTdEntext"
                                    : "numberCasetext"
                                }`}
                              >
                                {/* {request?.caseNumber !== null
                                ? request?.caseNumber.slice(10)
                                : request?.caseNumber} */}
                                {request?.caseNumber?.slice(-12)}
                              </Link>
                            ) : (
                              <p
                                className={`font-medium py-2 text-black ${
                                  lang === "en"
                                    ? "numberCaseTdEntextPending"
                                    : "numberCasetextPending"
                                }`}
                              >
                                {/* {request?.caseNumber !== null
                                ? request?.caseNumber.slice(10)
                                : request?.caseNumber} */}
                                {request?.caseNumber?.slice(-12)}
                              </p>
                            )}
                          </td>
                        )}
                        {!isSubTable && (
                          <td
                            className={
                              lang === "en" ? "typeCourtTdEn" : "typeCourtTd"
                            }
                          >
                            {request?.caseTypeName}
                          </td>
                        )}
                        {!isSubTable && (
                          <td
                            className={lang === "en" ? "courtTdEn" : "courtTd"}
                          >
                            {request?.courtName}
                          </td>
                        )}

                        <td
                          className={
                            lang === "en" ? "dateRequestTdEn" : "dateRequestTd"
                          }
                        >
                          {/* {lang === "en"
                            ? new Date(request?.createdAt).toLocaleDateString(
                                "en-GB"
                              )
                            : new Date(request?.createdAt)
                                .toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                                .split("/")
                                .reverse()
                                .join("/")} */}
                          {lang === "en"
                            ? new Date(request?.createdAt).toLocaleDateString(
                                "en-GB"
                              )
                            : new Date(request?.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                        </td>

                        <td
                          className={
                            lang === "en"
                              ? "requestStatusTdEn"
                              : "requestStatusTd"
                          }
                        >
                          <p className="flex items-center gap-2">
                            <ServiceStatus
                              serviceStatusId={request?.serviceStatusId}
                            />

                            {/* <FontAwesomeIcon
                          icon={faSquare}
                          className={
                            request?.Status === "قيد المراجعة"
                              ? "statuesSquare2"
                              : request?.Status === "منتهية"
                              ? "statuesSquare4"
                              : "statuesSquare3"
                          }
                        /> */}
                            <span>{request?.serviceStatus}</span>
                          </p>
                        </td>
                        {/* Updates 2024 */}
                        {/* {!isSubTable && (
                        <td
                          className={
                            lang === 'en' ? 'courtStatusTdEn' : 'courtStatusTd'
                          }>
                          {request?.caseStatus}
                        </td>
                      )} */}

                        <td
                          className={lang === "en" ? "actionTdEn" : "actionTd"}
                          // onClick={() => window.scrollTo(0, 0)}
                        >
                          {request?.serviceStatusId === 2 ||
                          request?.serviceStatusId === 3 ? (
                            <Link
                              to={`/order-details/${request?.caseId}/${request?.servicesId}`}
                              className="flex gap-[4px] underline decoration-[#af8c45]"
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              {request?.serviceStatusId === 2 ? (
                                <p>
                                  {lang === "en"
                                    ? enPhrase["lblCompleteData"]
                                    : arPhrase["lblCompleteData"]}
                                </p>
                              ) : request?.serviceStatusId === 3 ? (
                                <p>
                                  {lang === "en"
                                    ? enPhrase["lblPay"]
                                    : arPhrase["lblPay"]}
                                </p>
                              ) : (
                                ""
                              )}
                              <img
                                src="/assets/img/case-overview/chevron--sort--down.svg"
                                alt=""
                                className={
                                  lang === "en" ? "actionSectionImgEn" : ""
                                }
                              />
                            </Link>
                          ) : request?.serviceStatusId === 5 &&
                            request?.completeSurvey === false ? (
                            <p
                              className="flex gap-[4px] underline decoration-[#af8c45]"
                              onClick={() => setFeedBackIsOpen(true)}
                            >
                              {request?.serviceStatusId === 5 ? (
                                <p>
                                  {lang === "en"
                                    ? enPhrase["lbl_Survey"]
                                    : arPhrase["lbl_Survey"]}
                                </p>
                              ) : (
                                ""
                              )}
                              <img
                                src="/assets/img/case-overview/chevron--sort--down.svg"
                                alt=""
                                className={
                                  lang === "en" ? "actionSectionImgEn" : ""
                                }
                              />
                            </p>
                          ) : null}
                        </td>
                      </tr>
                    ))}
            </tbody>
          )}
        </table>
      </div>

      {filtered === "" &&
      filteredServiceName === "" &&
      filteredCaseTypeName === "" &&
      filteredStatus === "" &&
      filteredStatusCase === "" &&
      filteredCaseNumber === "" &&
      filteredCourtName === "" &&
      filteredCreatDate === ""
        ? requests?.length > 20 && (
            <div class="flex items-center flex-col gap-[24px] justify-center lg:flex-row mt-[24px] mb-[85.5px]">
              <div className="overview-FooterPages">
                <p className="overview-FooterText ">
                  {" "}
                  {lang === "en"
                    ? enPhrase["lblNumberOfApplications"]
                    : arPhrase["lblNumberOfApplications"]}
                </p>
                <select
                  className={
                    lang === "en"
                      ? "overview-FooterDropDownEn focus:border-gold-100 focus:ring-gold-100"
                      : "overview-FooterDropDown focus:border-gold-100 focus:ring-gold-100"
                  }
                  value={requestPerPage}
                  onChange={handleChangeRequestPerPage}
                >
                  <option value="20">20</option>

                  <option value="30">30</option>

                  <option value="40">40</option>
                </select>
              </div>
              <div className="overview-FooterTextBold text-center flex items-center justify-center lg:ms-auto">
                {filtered === "" ? (
                  <p>
                    {mainFilter?.length}
                    <span>{lang === "en" ? "From" : "من"}</span>

                    {requests?.length}
                    <span>
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </span>
                  </p>
                ) : (
                  <p>
                    {filtered2?.length}
                    <span>{lang === "en" ? "From" : "من"}</span>

                    {requests?.length}
                    <span>
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </span>
                  </p>
                )}
              </div>
              <div className="overview-Footer">
                {/* <div className="overview-FooterPages">
                <p className="overview-FooterText">عدد الطلبات بالصفحة</p>
                <select
                  className={
                    lang === "en"
                      ? "overview-FooterDropDownEn"
                      : "overview-FooterDropDown"
                  }
                  value={requestPerPage}
                  onChange={handleChangeRequestPerPage}
                >
                  <option value="20">20</option>

                  <option value="30">30</option>

                  <option value="40">40</option>
                </select>
              </div> */}

                {/* <div className="overview-FooterTextBold">
                <p>
                  {filtered2?.length}
                  <span>من</span>

                  {requests?.length}
                  <span>طلب</span>
                </p>
              </div> */}
                <Pagination
                  requestPerPage={requestPerPage}
                  totalRequests={requests?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  filtered2={filtered2}
                  lang={lang}
                />
              </div>
            </div>
          )
        : filtered2?.length > 20 && (
            <div class="flex items-center flex-col gap-[24px] justify-center lg:flex-row mt-[24px] mb-[85.5px]">
              <div className="overview-FooterPages">
                <p className="overview-FooterText ">
                  {lang === "en"
                    ? enPhrase["lblNumberOfApplications"]
                    : arPhrase["lblNumberOfApplications"]}{" "}
                </p>
                <select
                  className={
                    lang === "en"
                      ? "overview-FooterDropDownEn focus:border-gold-100 focus:ring-gold-100"
                      : "overview-FooterDropDown focus:border-gold-100 focus:ring-gold-100"
                  }
                  value={requestPerPage}
                  onChange={handleChangeRequestPerPage}
                >
                  <option value="20">20</option>

                  <option value="30">30</option>

                  <option value="40">40</option>
                </select>
              </div>
              <div className="overview-FooterTextBold text-center flex items-center justify-center lg:ms-auto">
                {filtered === "" ? (
                  <p>
                    {
                      filtered2.slice(indexOfFirstRequest, indexOfLastRequest)
                        .length
                    }
                    <span>{lang === "en" ? "From" : "من"}</span>

                    {filtered2?.length}
                    <span>
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </span>
                  </p>
                ) : (
                  <p>
                    {
                      filtered2?.slice(indexOfFirstRequest, indexOfLastRequest)
                        .length
                    }
                    <span>{lang === "en" ? "From" : "من"}</span>

                    {requests?.length}
                    <span>
                      {lang === "en"
                        ? enPhrase["lblRequest"]
                        : arPhrase["lblRequest"]}
                    </span>
                  </p>
                )}
              </div>
              <div className="overview-Footer">
                {/* <div className="overview-FooterPages">
                <p className="overview-FooterText">عدد الطلبات بالصفحة</p>
                <select
                  className={
                    lang === "en"
                      ? "overview-FooterDropDownEn"
                      : "overview-FooterDropDown"
                  }
                  value={requestPerPage}
                  onChange={handleChangeRequestPerPage}
                >
                  <option value="20">20</option>

                  <option value="30">30</option>

                  <option value="40">40</option>
                </select>
              </div> */}

                {/* <div className="overview-FooterTextBold">
                <p>
                  {filtered2?.length}
                  <span>من</span>

                  {requests?.length}
                  <span>طلب</span>
                </p>
              </div> */}
                <Pagination
                  requestPerPage={requestPerPage}
                  totalRequests={requests?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  filtered2={filtered2}
                  lang={lang}
                />
              </div>
            </div>
          )}
    </div>
  );
}
