import React, { useEffect, useState } from "react";
import { makePayment } from "../../../services/Common";

const PaymentElectronic = ({
  service,
  lang,
  arPhrase,
  enPhrase,
  setOpenModal,
}) => {
  const [paymentURL, setPaymentURL] = useState();
  const [loading, setLoading] = useState(false); // State for button loading

  // useEffect(() => {
  //   getPaymentURL(id, caseId);
  // }, []);
  const caseId = service?.caseId;
  const id = service?.id;

  // const getPaymentURL = () => {
  //   makePayment(caseId, id).then(
  //     (res) => {
  //       console.log("resssss", res);
  //       if (res) {
  //         console.log("res?.paymentUrlssss", res?.paymentUrl);
  //         console.log("RESPONSE", res?.paymentUrl);
  //         setPaymentURL(res?.paymentUrl);
  //         window.location.href = res?.paymentUrl;
  //       }
  //     },
  //     (err) => {
  //       console.log("Fail Payment !!!!!");
  //     }
  //   );
  // };

  // const getPaymentURL = () => {
  //   console.log("caseId", caseId, id);
  //   if (caseId && id) {
  //     makePayment(caseId, id).then(
  //       (res) => {
  //         console.log("resssss", res);
  //         if (res) {
  //           // window.location.href = res?.paymentUrl;
  //           window.open( res?.paymentUrl, "_blank"); // Open the URL in a new tab
  //         }
  //       },
  //       (err) => {
  //         console.log("Fail Payment !!!!!",err);
  //       }
  //     );
  //   }
  // };

  const getPaymentURL = () => {
    makePayment(caseId, id).then(
      (res) => {
        console.log("resssss", res);
        if (res) {
          setPaymentURL(res?.paymentUrl);
          window.location.href = res?.paymentUrl;
        }
      },
      (err) => {
        console.log("Fail Payment !!!!!");
      }
    );
  };

  return (
    <div
      className={`w-full md:w-[50%] border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] h-auto ${
        lang === "en" ? "md:!h-[200px]" : "md:!h-[182px]"
      }`}
    >
      <div className="flex flex-col md:flex-row items-start justify-between mb-[24px]">
        <div className="flex flex-col gap-[5px] ">
          <p className="text-[20px] font-Almarai font-[700] text-[#292724]">
            {lang === "en"
              ? enPhrase["lblPaymentElectronic"]
              : arPhrase["lblPaymentElectronic"]}
          </p>

          <p
            className={`text-[16px] font-Almarai font-[400] text-[#666] ${
              lang === "en" && "max-w-[300px]"
            }`}
          >
            {lang === "en"
              ? enPhrase["lblPaymentElectronicDetails"]
              : arPhrase["lblPaymentElectronicDetails"]}
          </p>
        </div>
        <div className="flex items-center gap-[16px] md:gap-[8px]">
          <img src="/assets/img/payment/visa.svg" alt="visa" className="" />
          <img
            src="/assets/img/payment/master-card.svg"
            alt="master-card"
            className=""
          />
        </div>
      </div>
     

      <button
        className={`w-full flex items-center justify-center bg-[#B68A35] rounded-[4px] h-[48px] p-[8px] disabled:opacity-50 ${
          service?.isPaid === true || service?.isUploadReceipts === true
            ? "cursor-not-allowed"
            : ""
        }`}
        disabled={
          service?.isPaid === true || service?.isUploadReceipts === true
        } // Disable button when paid
        onClick={getPaymentURL}
      >
        {service?.isPaid === true  ? (
          <p className="text-[16px] font-Almarai font-[700] text-[#fff] leading-normal">
            {lang === "en"
              ? enPhrase["lblPaymentIsSuccess"]
              : arPhrase["lblPaymentIsSuccess"]}
          </p>
        ) : (
          <p className="text-[16px] font-Almarai font-[700] text-[#fff] leading-normal">
            {lang === "en" ? enPhrase["btnPayNow"] : arPhrase["btnPayNow"]}
          </p>
        )}
      </button>
    </div>
  );
};

export default PaymentElectronic;
