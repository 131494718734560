import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Breadcrumb from "../components/breadCrumb";
import LargeTitle from "../components/case-tracking/LargeTitle";
import PopupForm from "../components/case-tracking/PopupForm";
import ReceiptForm from "../components/case-tracking/ReceiptForm";
import ServiceDetails from "../components/case-tracking/ServiceDetails";
import ServiceDetailsPart1 from "../components/case-tracking/ServiceDetailsPart1";
import ServiceDetailsPart1DeskTop from "../components/case-tracking/ServiceDetailsPart1DeskTop";
import ServiceDetailsPart2 from "../components/case-tracking/ServiceDetailsPart2";
import ServiceValue from "../components/case-tracking/ServiceValue";
import SmallTitle from "../components/case-tracking/SmallTitle";
import SharedModal from "../components/happiness/SharedModal";
import ConfirmPayment from "../components/paymentPopup/ConfirmPayment";
import FailedPayment from "../components/paymentPopup/FailedPayment";
import SuccessPayment from "../components/paymentPopup/SuccessPayment";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
import { getComments, getServiceDetails } from "../services/Common";
import { USDollar } from "../utils/helpers";
import FeedBackQuestions from "../components/FeedBack/FeedBackQuestions";
import SuccessSurvayAdded from "../components/FeedBack/SuccessSurvayAdded";
import SuccessSurvayAddedMobile from "../components/FeedBack/SuccessSurvayAddedMobile";

const OrderDetails = () => {
  const { caseid, serviceid } = useParams();
  const [lang, setLang] = useState("");
  // const newplugin = defaultLayoutPlugin();
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  const [service, setService] = useState({});
  const [comments, setComments] = useState([]);
  const [openPopub, setOpenPopub] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [receiptStatus, setReceiptStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successUploadReciept, setSuccessUploadReciept] = useState(false);
  const [falidUploadReciept, setFalidUploadReciept] = useState(false);
  const [openModalFail, setOpenModalFail] = useState(false);
  const [openModalSucc, setOpenModalSucc] = useState(false);

  const [isSueveyMenu, setIsSueveyMenu] = useState(false);
  const [isOpenFeedBack, setIsOpenFeedBack] = useState(true);
  const [isExpand, setIsExpand] = useState(false);
  const [success, setSuccess] = useState("");

  const [sidebarHeight, setSidebarHeight] = useState(0);
  const navbarRef = useRef(null);

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    // const lang = window.localStorage.getItem("lang");
    // if (lang === "en") {
    //   document.body.dir = "ltr";
    //   setLang("en");
    // } else {
    //   document.body.dir = "rtl";
    //   setLang("ar");
    // }
    // getPhrasesService();
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getServiceDetailsService(currentLang);
    getCommentsService(currentLang);
  }, []);

  // const changeLanguage2 = (lang) => {
  //   if (lang === "en") {
  //     document.body.dir = "rtl";
  //     setLang("ar");
  //     window.localStorage.setItem("lang", "ar");
  //   } else {
  //     document.body.dir = "ltr";
  //     setLang("en");
  //     window.localStorage.setItem("lang", "en");
  //   }
  //   window.location.reload();
  // };
  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return "";
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };

  const getServiceDetailsService = (currentLang) => {
    return getServiceDetails(caseid, serviceid, currentLang).then((res) => {
      setService(res);
      setLoading(false);
    });
  };

  const getCommentsService = (currentLang) => {
    return getComments(serviceid, currentLang).then((res) => {
      setComments(res);
    });
  };

  const handelOpenPopup = () => {
    setOpenPopub(!openPopub);
  };

  const uploadReceiptHandler = () => {
    setReceiptStatus(!receiptStatus);
  };

  const surveyFeedBackHandler = () => {
    setIsSueveyMenu(!isSueveyMenu);
  };

  const handleSuccessChange = (newState) => {
    setSuccess(newState);
  };

  useEffect(() => {
    if (navbarRef.current) {
      setSidebarHeight(navbarRef.current.offsetHeight);
    }
  }, [navbarRef]);

  return (
    <ThemeLayout navbarRef={navbarRef}>
      {loading ? (
        <p className="text-[#b68a35] font-bold font-Almarai mt-[8px]">
          {lang === "en" ? "Loading..." : "جاري عرض البيانات..."}
        </p>
      ) : (
        <div className="flex flex-col w-full py-4 max-w-[1140px] px-[16px] lg:px-[0px]">
          <Breadcrumb
            title={
              lang === "en" ? enPhrase["lblMycases"] : arPhrase["lblMycases"]
            }
            subtitle1={`${
              lang === "en"
                ? `${service?.caseNumber}`
                : `${service?.caseNumber}`
            }`}
            subtitle1Href={`${
              service?.caseId === 0
                ? "/case-overview"
                : `/case-details/${service?.caseId}`
            }`}
            last={`${
              lang === "en"
                ? `${enPhrase["lblRequest"]} ${service?.id}`
                : `${service?.id} ${arPhrase["lblRequest"]}`
            }`}
            lastHref={`${`/order-details/${service?.caseId}/${service?.id}`}`}
          />
          <div className="flex justify-between items-center mb-8">
            <div className="flex flex-col xxsm:flex-row justify-between items-center gap-2 ">
              <LargeTitle
                title={
                  lang === "en"
                    ? enPhrase["lblApplicationDetails"]
                    : arPhrase["lblApplicationDetails"]
                }
                nullmargin
              />
              {/* {service?.serviceStatusId === 3 && !service.isUploadReceipts ? (
           <button
             className="flex flex-row-reverse font-Almarai justify-center items-center gap-2 transition-all duration-300 px-4 font-semibold h-[44px] text-sm border-2 text-[#815327] border-[#815327] rounded focus:outline-none"
             onClick={uploadReceiptHandler}
           >
             <span>
               {lang === "en"
                 ? enPhrase["lblInvoiceUpload"]
                 : arPhrase["lblInvoiceUpload"]}
             </span>
             <img
               src="/assets/img/button/upload.svg"
               alt="logout"
               className="w-5 h-5"
             />
           </button>
         ) : null} */}
            </div>
            {service?.serviceStatusId === 5 && !service?.completeSurvey ? (
              <button
                className="w-[166px] h-[44px] bg-[#A98031] rounded text-[#fff] font-Almarai font-bold text-[16px] px-3"
                onClick={surveyFeedBackHandler}
              >
                <p>
                  {lang === "en"
                    ? enPhrase["btnSurvey"]
                    : arPhrase["btnSurvey"]}
                </p>
              </button>
            ) : null}
          </div>

          {success ? (
            window && window.innerWidth <= 1024 ? (
              <SharedModal isSuccessSurvayMobile setOpenModal={setSuccess}>
                <SuccessSurvayAddedMobile
                  isOrderDetails
                  sidebarHeight={sidebarHeight}
                  setIsOpenFeedBack={setIsOpenFeedBack}
                  //  survayServices={survayServices}
                  feedBackHandler={surveyFeedBackHandler}
                  //  setIsRequestSurvay={setIsRequestSurvay}
                  //  FeedBackListHandler={FeedBackListHandler}
                  lang={lang}
                  arPhrase={arPhrase}
                  enPhrase={enPhrase}
                  //  expandHandler={expandHandler}
                  //  setFeedBackIsOpen={setFeedBackIsOpen}
                  //  specificSurvey={specificSurvey}
                  //  setSpecificSurvey={setSpecificSurvey}
                  //  feedBackIsOpen={feedBackIsOpen}
                />
              </SharedModal>
            ) : (
              <SuccessSurvayAdded
                isOrderDetails
                sidebarHeight={sidebarHeight}
                setIsOpenFeedBack={setIsOpenFeedBack}
                //  survayServices={survayServices}
                feedBackHandler={surveyFeedBackHandler}
                //  setIsRequestSurvay={setIsRequestSurvay}
                //  FeedBackListHandler={FeedBackListHandler}
                lang={lang}
                arPhrase={arPhrase}
                enPhrase={enPhrase}
                //  expandHandler={expandHandler}
                //  setFeedBackIsOpen={setFeedBackIsOpen}
                //  specificSurvey={specificSurvey}
                //  setSpecificSurvey={setSpecificSurvey}
                //  feedBackIsOpen={feedBackIsOpen}
              />
            )
          ) : isSueveyMenu ? (
            <div
              className={`w-full md:w-[400px] border-[1px] border-[#E6D7A2] bg-[#fff] ${
                isOpenFeedBack ? "fixed" : "hidden"
              } ${
                !isExpand
                  ? `bottom-[0px] overflow-y-scroll  ${
                      lang === "en" ? "right-0" : "left-0"
                    }`
                  : `bottom-[35px] ${
                      lang === "en"
                        ? "right-0 md:right-[24px]"
                        : "left-0  md:left-[24px]"
                    }`
              } 
     z-[10000]`}
              style={{ top: sidebarHeight }}
            >
              <div className="h-[56px]  bg-[#F2ECCF] flex items-center justify-between gap-[16px] px-[16px] fixed w-full md:w-[400px]">
                <div>
                  <div className="flex flex-col gap-[5px]">
                    <div className="font-Almarai font-normal text-[14px] leading-[14.4px] flex gap-[8px]">
                      <span className="font-normal">{service?.id}</span>
                      <span>•</span>
                      <p className="font-bold">{service?.serviceCategory}</p>
                    </div>
                    <div className="font-Almarai text-[12px] leading-[14.4px] flex gap-[8px] text-[#54504A]">
                      <span>{service?.caseNumber?.slice(-12)}</span>
                      <span>•</span>
                      <p>{service?.caseTypeName}</p>
                    </div>
                  </div>
                </div>

                <img
                  src="/assets/img/case-overview/closedIcon.svg"
                  alt="close-icon"
                  className="cursor-pointer transform transition-transform duration-500 ease-in-out hover:scale-110 hover:bg-[#a9803133] rounded"
                  onClick={() => {
                    setIsSueveyMenu(false);
                  }}
                />
              </div>

              <div className="flex flex-col items-center w-full p-[16px] pt-[72px]">
                <div className="h-[72px] w-full m-auto border-[1px]  border-[#F2C99B] p-[16px] rounded-[8px] flex items-center gap-[24px]">
                  <img
                    src="/assets/img/case-overview/survay-icon.svg"
                    alt=""
                    className="w-[32px] h-[32px]"
                  />
                  <p
                    className={`flex font-Almarai font-bold text-[14px] leading-[20px] ${
                      lang === "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {lang === "en"
                      ? enPhrase["lblShareSurveytext"]
                      : arPhrase["lblShareSurveytext"]}
                  </p>
                </div>

                <FeedBackQuestions
                  lang={lang}
                  enPhrase={enPhrase}
                  arPhrase={arPhrase}
                  selectedServiceId={service?.id}
                  onSuccessChange={handleSuccessChange}
                />
              </div>
            </div>
          ) : null}

          <div className="flex flex-col xl:flex-row gap-6 mb-6">
            <div className="w-full xl:w-[57.5%]">
              {window && window.innerWidth <= 1024 ? (
                <ServiceDetailsPart1
                  service={service}
                  lang={lang}
                  arPhrase={arPhrase}
                  enPhrase={enPhrase}
                />
              ) : (
                <ServiceDetailsPart1DeskTop
                  service={service}
                  lang={lang}
                  arPhrase={arPhrase}
                  enPhrase={enPhrase}
                />
              )}
            </div>
            <div className="w-full xl:w-[40.5%]">
              <ServiceDetailsPart2
                service={service}
                lang={lang}
                arPhrase={arPhrase}
                enPhrase={enPhrase}
              />
            </div>
          </div>

          {(service?.serviceStatusId === 6 ||
            service?.serviceStatusId === 7) && (
            <div
              className={`flex items-stretch border-solid border-2 ${
                service?.serviceStatusId === 7
                  ? "border-[#FF9280]"
                  : "border-[#C3C1BB]"
              } rounded shadow-[0px_10px_40px_#361E1214] mb-6`}
            >
              {/* Left Vertical Bar */}
              <div
                className={`w-[20px] border-4  flex justify-center items-center  ${
                  service?.serviceStatusId === 7
                    ? "border-[#FF9280] bg-[#FF9280]"
                    : "border-[#C3C1BB] bg-[#C3C1BB]"
                }`}
              >
                <div
                  className={`w-[8px] min-h-[58px] h-full border-2  bg-[#fff] rounded ${
                    service?.serviceStatusId === 7
                      ? "border-[#FF9280]"
                      : "border-[#C3C1BB]"
                  }`}
                ></div>
              </div>

              {/* Right Content Section */}
              <div className="flex flex-col px-4 py-2 justify-center w-full">
                <h2 className="text-[16px] text-[#010202] md:mb-[8px]">
                  {service?.status} :
                </h2>
                <p className="text-base text-[#010202] font-bold">
                  {service?.suspendReason}
                </p>
              </div>
            </div>
          )}

          {/* {service?.serviceStatusId === 1
         ? 1
         : service?.serviceStatusId === 2
         ? 2
         : service?.serviceStatusId === 3
         ? 3
         : null} */}
          {service?.serviceStatusId === 3 ||
          service?.serviceStatusId === 4 ||
          service?.serviceStatusId === 5 ||
          service?.serviceStatusId === 6 ||
          service?.serviceStatusId === 7 ? (
            <div className="flex flex-col  gap-0 md:gap-0 mb-8 overflow-hidden border-2 border-solid border-[#EEE2D0] rounded shadow-[0px_10px_40px_#361E1214]">
              <div className="w-full ">
                <ServiceValue
                  service={service}
                  claimedValue={service?.serviceValue}
                  serviceStatusId={service?.serviceStatusId}
                  setOpenModal={setOpenModal}
                  successUploadReciept={successUploadReciept}
                />
              </div>
              <div className="w-full  py-[16px] md:py-[0px]">
                <ServiceDetails
                  serviceDescription={service?.serviceDescription}
                  service={service}
                  lang={lang}
                  arPhrase={arPhrase}
                  enPhrase={enPhrase}
                />
              </div>
            </div>
          ) : null}

          {(service?.serviceStatusId === 2 ||
            service?.serviceStatusId === 5 ||
            service?.serviceStatusId === 6 ||
            service?.serviceStatusId === 7) &&
          comments.length > 0 ? (
            <div className="">
              <SmallTitle
                title={
                  lang === "en"
                    ? enPhrase["lblFilesTracking"]
                    : arPhrase["lblFilesTracking"]
                }
              />
              <div className="flex flex-col overflow-hidden border-2 border-solid border-[#EEE2D0] rounded shadow-[0px_10px_40px_#361E1214]">
                {comments
                  ?.slice(0)
                  .reverse()
                  ?.map((comment, indx) => (
                    <div key={indx}>
                      <div className="flex gap-2 text-[12px] text-[#010202] font-medium bg-[#EEE2D0] h-[30px] leading-[30px] px-4">
                        <span className="text-[12px] font-Almarai font-bold">
                          {" "}
                          {comment?.name}
                        </span>
                        <span>•</span>
                        <span className="text-[12px] font-Almarai font-bold">
                          {new Date(comment?.createdAt).toLocaleDateString(
                            "en-GB"
                          )}
                        </span>
                        {/* <span>•</span>
                   <span
                     className="text-[12px] font-Almarai font-bold"
                     dir="ltr"
                   >
                     {new Date(comment?.createdAt).toLocaleTimeString()}
                   </span> */}
                      </div>
                      <div className="px-4">
                        <div className="flex flex-row justify-between gap-20 py-4">
                          <p className="text-sm text-[#010202] font-medium max-w-full overflow-clip overflow-x-clip">
                            {comment?.commentText}
                          </p>
                          {indx === 0 &&
                          comment?.name &&
                          service?.serviceStatusId === 2 ? (
                            <button
                              type="button"
                              className="inline-flex justify-center gap-2 items-center h-[44px] rounded-md border border-transparent bg-gold-100 py-4 px-8 text-sm font-bold text-white shadow-sm"
                              onClick={handelOpenPopup}
                            >
                              <span>{lang === "en" ? "Reply" : "رد"}</span>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex gap-4 mb-4">
                          {comment?.documents?.map((el, index) => (
                            <>
                              {el?.documentUrl.includes("pdf") === true ? (
                                <div className="w-[179px] h-[130px] rounded">
                                  <embed
                                    src={el?.documentUrl}
                                    height="100%"
                                    width="100%"
                                  ></embed>
                                 
                                </div>
                              ) : (
                                <img
                                  className="w-[179px] h-[130px] rounded"
                                  src={el?.documentUrl}
                                  alt=""
                                />
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
          {openModal && (
            <SharedModal isPaymentConfirm setOpenModal={setOpenModal}>
              <ConfirmPayment
                claimedValue={USDollar.format(service?.serviceValue)}
                setOpenModal={setOpenModal}
                service={service}
                caseid={serviceid}
                serviceid={caseid}
                setOpenModalFail={setOpenModalFail}
                setOpenModalSucc={setOpenModalSucc}
              />
              {/* // const html = <SuccessPayment />;
   // const html = <FailedPayment />; */}
            </SharedModal>
          )}
          {openModalFail && (
            <SharedModal setOpenModal={setOpenModalFail}>
              <FailedPayment setOpenModal={setOpenModalFail} />
            </SharedModal>
          )}
          {openModalSucc && (
            <SharedModal setOpenModal={setOpenModalSucc}>
              <SuccessPayment setOpenModal={setOpenModalSucc} />
            </SharedModal>
          )}
          {openPopub && (
            <SharedModal isNeedData setOpenModal={handelOpenPopup}>
              <PopupForm
                handelOpenPopup={handelOpenPopup}
                getCommentsService={getCommentsService}
                serviceid={serviceid}
                setOpenPopub={setOpenPopub}
              />
            </SharedModal>
          )}

          {receiptStatus && (
            <SharedModal isReceiptForm setOpenModal={uploadReceiptHandler}>
              <ReceiptForm
                uploadReceiptHandler={uploadReceiptHandler}
                setReceiptStatus={setReceiptStatus}
                receiptStatus={receiptStatus}
                service={service}
                successUploadReciept={successUploadReciept}
                setSuccessUploadReciept={setSuccessUploadReciept}
                falidUploadReciept={falidUploadReciept}
                setFalidUploadReciept={setFalidUploadReciept}
              />
            </SharedModal>
          )}
        </div>
      )}
    </ThemeLayout>
  );
};

export default OrderDetails;
